import { IVImage, checkWebP, defaultImage } from "./imageHelper";

export const getImageBlockImage = (imageData: any, width: any): IVImage => {
  if (checkWebP) {
    const image = getImageBlockImageWebP(imageData, width);
    if (image) {
      return image;
    }
  }
  return getImageBlockImageFallback(imageData, width);
};

const getImageBlockImageWebP = (imageData: any, width: any): IVImage => {
  if (width < 375) {
    return imageData?.Size375webP
      ? ({
          url: imageData?.Size375webP,
          width: 375,
          height: 211,
          imageAltText: imageData?.altText,
        } as IVImage)
      : null;
  }
  return imageData?.Size768webP
    ? ({
        url: imageData?.Size768webP,
        width: 768,
        height: 432,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
};

const getImageBlockImageFallback = (imageData: any, width: any): IVImage => {
  const desktopImage = imageData?.mediaUrl
    ? ({
        url: imageData?.mediaUrl,
        width: 1240,
        height: 720,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const image1280 = imageData?.Size1280
    ? ({
        url: imageData?.Size1280,
        width: 1240,
        height: 720,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const image768 = imageData?.Size768
    ? ({
        url: imageData?.Size768,
        width: 768,
        height: 432,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const image375 = imageData?.Size375
    ? ({
        url: imageData?.Size375,
        width: 375,
        height: 211,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const mobileImage = imageData?.backup16x9Format
    ? ({
        url: imageData?.backup16x9Format,
        width: 375,
        height: 211,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  if (width > 768) return image768 || image1280 || desktopImage || mobileImage || defaultImage;
  if (width >= 375 && width <= 768)
    return image768 || image1280 || desktopImage || mobileImage || defaultImage;
  if (width < 375)
    return image375 || mobileImage || image768 || image1280 || desktopImage || defaultImage;
  return image768 || desktopImage || image1280 || mobileImage || defaultImage;
};
