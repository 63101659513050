import { isNullOrEmpty, isValidEmail } from "@utils/string";

import { birthyearRegex, phoneRegex, stateRegex, zipCodeRegex } from "./formConstants";
import formErrors from "./formErrors.json";

const validate = (formState: any, errors: any) => {
  let updatedErrors = { ...errors };
  for (const field of Object.keys(formState)) {
    if (formState[field].required) {
      for (const errorType of Object.keys(formErrors[field])) {
        if (!isValidField(field, formState[field].value, errorType)) {
          updatedErrors = {
            ...updatedErrors,
            [field]: {
              ...formErrors[field][errorType],
            },
          };
        }
      }
    } else if (
      !formState[field].required &&
      formState[field].checkValidIfNotEmpty &&
      !isNullOrEmpty(formState[field].value) &&
      !isValidField(field, formState[field].value, "invalid")
    ) {
      updatedErrors = {
        ...updatedErrors,
        [field]: {
          ...formErrors[field]["invalid"],
        },
      };
    }
  }
  return updatedErrors;
};

const isValidField = (fieldName: string, fieldValue: string, key: string) => {
  switch (true) {
    case fieldName === "email" && key === "invalid":
      return isValidEmail(fieldValue);
    case fieldName === "zipCode" && key === "invalid":
      return zipCodeRegex.test(fieldValue);
    case fieldName === "state" && key === "invalid":
      return stateRegex.test(fieldValue);
    case fieldName === "phone" && key === "invalid":
      return phoneRegex.test(fieldValue);
    case fieldName === "approved" && key === "invalid":
      return fieldValue;
    case fieldName === "amount" && key === "invalid":
      return !fieldValue;
    case fieldName === "birth" && key === "invalid":
      return birthyearRegex.test(fieldValue);
    case key === "empty":
      return !isNullOrEmpty(fieldValue);
    default:
      return true;
  }
};

const scrollIntoView = (ref: any, scrollPosition: string = "center") => {
  ref?.current?.scrollIntoView({ behavior: "smooth", block: scrollPosition });
  setTimeout(() => {
    ref?.current?.focus();
  }, 700);
};

export { validate, scrollIntoView };
