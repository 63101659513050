import { RefObject, useEffect } from "react";

export const useEscape = (ref: RefObject<any>, handler: () => void, condition: boolean = true) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref?.current) {
        return;
      }
      if (event.code === "Escape" && condition) {
        handler();
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handler, ref, condition]);
};
