import targetGroupOptions from "@utils/targetGroup/targetGroupOptions.json";

import { PersonalInfo } from "types/PersonalInfo";

export const getPersonalInfoByAudienceId = (cookieValue: string): PersonalInfo => {
  const role = targetGroupOptions?.roles?.find((role) => cookieValue?.includes(role?.roleId));
  const sector = targetGroupOptions?.sectors?.find((sector) =>
    cookieValue?.includes(sector?.sectorId)
  );
  const audienceId = role?.roleId && sector?.sectorId && cookieValue;

  return { audienceId, sector, role };
};
