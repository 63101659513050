import { RefObject, useEffect, useRef, useState } from "react";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Message from "@afa-shared/afa-components/dist/Message";
import { quizEndActionTracker, quizFormActionTracker } from "@components/QuizBlock/QuizTracker";
import { setQuizModalClosed, setQuizModalOpen } from "@utils/globalClassHelper";

import QuizCompetitionModal from "../../Modals/QuizCompetitionModal/QuizCompetitionModal";
import QuizConclusionModal from "../../Modals/QuizConclusionModal/QuizConclusionModal";
import QuizStep from "../../QuizStep/QuizStep";
import { QuizBlockType_quizBlock_questionAreaCollection_items } from "../../queries/contentfulTypes/QuizBlockType";
import { getQuizResult, getQuizResultImage } from "../ResultHelper";
import { ResultModel } from "../ResultModel";
import quizResultStyles from "./QuizResult.module.css";

const defaultResultModel: ResultModel = {
  insufficient: {
    message:
      "Allt börjar med ett första steg! Passa gärna på att lära dig mer om kollektivavtalade försäkringar som kan hjälpa dig om du blir sjuk. Eller skadad på jobbet. Snart har du hur bra koll som helst!",
  },
  average: {
    message:
      "Inte illa! Du är på god väg att få riktigt bra koll! Passa gärna på att lära dig mer om kollektivavtalade försäkringar som kan hjälpa dig om du blir sjuk. Eller skadad på jobbet.",
  },
  great: {
    message:
      "Här har vi en kämpe! Bättre koll än de flesta, vilket du ska vara stolt över. Passa gärna på att lära dig mer om kollektivavtalade försäkringar som kan hjälpa dig om du blir sjuk. Eller skadad på jobbet.",
  },
  perfect: {
    message:
      "Kolla vem som hade koll! Du hade alla rätt och är därmed en mästare på kollektivavtalade försäkringar!",
    competitionText:
      "Vi lottar ut 100 t-shirtar bland alla som fick full pott i quizet. T-shirtarna är i One Size motsvarandes storlek XL. Om du vill delta i utlottningen kan du gå vidare till nästa steg och fylla i dina uppgifter.",
  },
  contactFeedback: {
    heading: "Tack!",
    message: "Du är nu med och tävlar om att vinna en av 100 t-shirtar.",
  },
};

interface IQuizResult {
  questions: Array<QuizBlockType_quizBlock_questionAreaCollection_items>;
  userQuizAnswers: Array<string>;
  quizConclusions: Array<string>;
  userCorrectAnswers: number;
  resultInsufficientMessage: string | null | undefined;
  resultBronzeMessage: string | null | undefined;
  resultSilverMessage: string | null | undefined;
  resultGoldMessage: string | null | undefined;
  bronzeImage: any;
  silverImage: any;
  goldImage: any;
  resultPageContactButtonText: string | null | undefined;
  resultPageConclusionButtonText: string | null | undefined;
  availableQuizForm: boolean;
  competitionExpirationDate: Date;
  quizId: string;
  showForm: boolean;
  containerRef: RefObject<HTMLDivElement>;
  quizType: string;
}

const QuizResult = ({
  questions,
  userQuizAnswers,
  quizConclusions,
  userCorrectAnswers,
  resultInsufficientMessage,
  resultBronzeMessage,
  resultSilverMessage,
  resultGoldMessage,
  bronzeImage,
  silverImage,
  goldImage,
  resultPageContactButtonText,
  resultPageConclusionButtonText,
  availableQuizForm,
  competitionExpirationDate,
  quizId,
  showForm,
  containerRef,
  quizType,
}: IQuizResult) => {
  const [conclusionModalOpen, setConclusionModalOpen] = useState(false);
  const [competitionModalOpen, setCompetitionModalOpen] = useState(false);
  const [formIsSubmitted, setFormIsSubmitted] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const sumbitionFeedbackRef = useRef(null);
  const defaultResultMessageModel = getQuizResult(
    userCorrectAnswers,
    questions?.length,
    defaultResultModel,
    resultInsufficientMessage,
    resultBronzeMessage,
    resultSilverMessage,
    resultGoldMessage
  );
  const resultImage = getQuizResultImage(
    userCorrectAnswers,
    questions?.length,
    bronzeImage,
    silverImage,
    goldImage
  );

  useEffect(() => {
    if (
      showForm &&
      userCorrectAnswers === questions?.length &&
      availableQuizForm &&
      !formIsSubmitted
    ) {
      handleModalToggle(true, "competition");
    } else {
      containerRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalToggle = (setOpen: boolean, modalName: string) => {
    if (setOpen) {
      setScrollPosition(window?.scrollY);
      sessionStorage.setItem(`${quizId}-modal-open`, "true");
    }
    setOpen ? setQuizModalOpen() : setQuizModalClosed();
    modalName === "competition"
      ? setCompetitionModalOpen(setOpen)
      : setConclusionModalOpen(setOpen);
    if (!setOpen) {
      window?.scrollTo(0, scrollPosition);
      containerRef?.current?.focus();
      sessionStorage.setItem(`${quizId}-modal-open`, "false");
    }
  };

  const buttons = (isNotebookDown: boolean) => {
    const showCompetitionBtn =
      userCorrectAnswers === questions?.length && availableQuizForm && !formIsSubmitted;
    return (
      <>
        {!formIsSubmitted && (
          <div
            className={`${quizResultStyles.buttonWrapper} ${
              isNotebookDown ? "" : quizResultStyles.desktopWrapper
            }`}
          >
            {showCompetitionBtn && (
              <Button
                buttonType="primary"
                onClick={() => {
                  handleModalToggle(true, "competition");
                  quizFormActionTracker(quizType, "tävling_quiz", "påbörjat");
                }}
                label={
                  resultPageContactButtonText ? resultPageContactButtonText : "Fyll i din adress"
                }
              />
            )}
            <Button
              buttonType={showCompetitionBtn ? "secondary" : "primary"}
              onClick={() => {
                handleModalToggle(true, "conclusion");
                quizEndActionTracker(quizType, "Se dina svar");
              }}
              label={
                resultPageConclusionButtonText ? resultPageConclusionButtonText : "Se dina svar"
              }
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={quizResultStyles.quizResult}>
      <QuizStep
        ivImage={resultImage}
        children={
          <>
            <Message
              heading={
                formIsSubmitted
                  ? defaultResultModel?.contactFeedback?.heading
                  : `Ditt resultat: ${userCorrectAnswers} av ${questions?.length}`
              }
              headingVariant={"h3"}
              type={"success"}
              children={
                <p>
                  {formIsSubmitted
                    ? defaultResultModel?.contactFeedback?.message
                    : defaultResultMessageModel?.message}
                </p>
              }
              ref={sumbitionFeedbackRef}
            />
            {buttons(false)}
          </>
        }
        notebookDownButtonWrapper={buttons(true)}
      />
      {conclusionModalOpen && (
        <QuizConclusionModal
          questions={questions}
          userQuizAnswers={userQuizAnswers}
          userCorrectAnswers={userCorrectAnswers}
          conclusions={quizConclusions}
          handleModalClose={() => handleModalToggle(false, "conclusion")}
          containerRef={containerRef}
        />
      )}
      {competitionModalOpen && (
        <QuizCompetitionModal
          handleModalClose={() => handleModalToggle(false, "competition")}
          setFormIsSubmitted={() => setFormIsSubmitted(true)}
          competitionExpirationDate={competitionExpirationDate}
          quizId={quizId}
          containerRef={containerRef}
          quizType={quizType}
        />
      )}
    </div>
  );
};

export default QuizResult;
