import { UrlStructure } from "types/UrlStructure";
import { UrlStructureItem } from "types/UrlStructureItem";

export const getUrlStructureItemBySlug = (
  slug: string,
  urlStructure: UrlStructure
): UrlStructureItem | null => {
  const item = urlStructure?.urls?.find((item) => slug === item.slug);
  if (item) return item;
  return null;
};
