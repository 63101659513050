import Grid from "@afa-shared/afa-components/dist/Grid";
import { replaceSector } from "@utils/string";

import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { Context } from "types/context/Context";

import RichTextRenderer from "../RichTextRenderer";
import { RestrictedRichTextBlockType_restrictedRichTextBlock } from "./queries/contentfulTypes/RestrictedRichTextBlockType";

interface IRichTextBlockProps {
  blockData: RestrictedRichTextBlockType_restrictedRichTextBlock;
  context?: Context;
  uniqueKey?: string;
}

export const RestrictedRichTextBlock = ({
  blockData: richTextBlock,
  context,
}: IRichTextBlockProps) => {
  const selectedSector = useSectorToggler((state: ISectorToggler) => state.selectedSector);

  return (
    <Grid
      container
      data-blockname="textyta"
      data-contentful-entry-id={richTextBlock?.sys?.id}
      data-contentful-field-id={"body"}
    >
      <RichTextRenderer
        json={
          context?.insuranceName
            ? replaceSector(richTextBlock?.body?.json, selectedSector?.sector?.chatBoxName)
            : richTextBlock?.body?.json
        }
        links={richTextBlock?.body?.links}
      />
    </Grid>
  );
};
export default RestrictedRichTextBlock;
