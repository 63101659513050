import { IRole, ISector } from "types/PersonalInfo";

import targetGroupOptions from "./targetGroupOptions.json";

const getSectorById = (sectorId: string): ISector => {
  return targetGroupOptions.sectors.find((item) => item.sectorId === sectorId);
};

const getRoleById = (roleId: string): IRole => {
  return targetGroupOptions.roles.find((item) => item.roleId === roleId);
};

const getChatBoxTargetGroupName = (roleId: string, sectorId: string): string => {
  const roleName = getRoleById(roleId)?.chatBoxName;
  const sectorName = getSectorById(sectorId)?.chatBoxName;
  return getConcatinatedTargetGroup(roleId, sectorId, roleName, sectorName);
};

const getSectorGuideButtonTargetGroupName = (roleId: string, sectorId: string): string => {
  if (roleId && sectorId) {
    const roleName = getRoleById(roleId)?.sectorGuideButton;
    const sectorName = getSectorById(sectorId)?.sectorGuideButton;
    return getConcatinatedTargetGroup(roleId, sectorId, roleName, sectorName);
  } else {
    return "Välj sektor";
  }
};

const getConcatinatedTargetGroup = (
  roleId: string,
  sectorId: string,
  roleName: string,
  sectorName: string
): string => {
  return roleId === "arbetsgivareochfack" && sectorId !== "egen-foretagare"
    ? `${roleName} för ${uncapitalizeFirstLetter(sectorName)}`
    : `${sectorName}`;
};

function uncapitalizeFirstLetter(string) {
  return string?.charAt(0)?.toLowerCase() + string?.slice(1);
}

export {
  getSectorById,
  getRoleById,
  getChatBoxTargetGroupName,
  getSectorGuideButtonTargetGroupName,
};
