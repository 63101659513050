import * as ga from "../../lib/ga";

export const quizStartTracker = (quizType: string) => {
  const trackingData = {
    event: "quiz_test_start",
    event_parameters: {
      page_location: window.location.href,
      feature_block_namn: quizType?.toLowerCase() === "quiz" ? "quiz" : "test",
      content_type: quizType?.toLowerCase(),
    },
  };

  ga.gaTracking(trackingData);
};

export const quizEndTracker = (quizType: string) => {
  const trackingData = {
    event: "quiz_test_stop",
    event_parameters: {
      page_location: window.location.href,
      feature_block_namn: quizType?.toLowerCase() === "quiz" ? "quiz" : "test",
      content_type: quizType?.toLowerCase(),
    },
  };

  ga.gaTracking(trackingData);
};

export const quizEndActionTracker = (quizType: string, action: string) => {
  const trackingData = {
    event: "select_content",
    event_parameters: {
      page_location: window.location.href,
      åtgärd: action?.toLowerCase(),
      feature_block_namn: quizType?.toLowerCase() === "quiz" ? "quiz" : "test",
      content_type: quizType?.toLowerCase(),
    },
  };

  ga.gaTracking(trackingData);
};

export const quizFormActionTracker = (quizType: string, quizTheme: string, quizStatus: string) => {
  const trackingData = {
    event: "formulär",
    event_parameters: {
      page_location: window.location.href,
      status: quizStatus?.toLowerCase(),
      feature_block_namn: quizType?.toLowerCase() === "quiz" ? "quiz" : "test",
      content_type: quizType?.toLowerCase(),
      formulär_tema: quizTheme?.toLowerCase(),
      ärende: "annat",
    },
  };
  ga.gaTracking(trackingData);
};
