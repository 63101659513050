import * as ga from "../../lib/ga";

export const shortCutsTracker = (linkText: string) => {
  const trackingData = {
    event: "snabblänkar",
    event_parameters: {
      page_location: window.location.href,
      ärende: linkText?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};
