import { useEffect, useState } from "react";

import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { Context } from "types/context/Context";

import CFContentArea from "../cFContentArea";
import {
  PersonalizationBlockType_personalizationBlock,
  PersonalizationBlockType_personalizationBlock_variantsCollection_items,
} from "./queries/contentfulTypes/PersonalizationBlockType";

interface IProps {
  blockData: PersonalizationBlockType_personalizationBlock;
  context?: Context;
  uniqueKey?: string;
  maxWidth: number;
}

export const PersonalizationBlock = ({
  blockData: personalizationBlock,
  context,
  maxWidth,
}: IProps): JSX.Element => {
  const selectedSector = useSectorToggler((state: ISectorToggler) => state.selectedSector);
  const isLoading = useSectorToggler((state: ISectorToggler) => state.isLoading);
  const [personalizedContentArray, setPersonalizedContentArray] = useState<Array<any>>(
    personalizationBlock?.fallbackVariation ? Array.of(personalizationBlock?.fallbackVariation) : []
  );

  useEffect(() => {
    if (isLoading) return;
    const variants = personalizationBlock?.variantsCollection?.items?.filter((a: any) =>
      a[Object.keys(a)[0]]?.audienceCollection?.items?.some(
        (item: any) => item?.audience?.audianceId === selectedSector?.audienceId
      )
    );

    if (variants && variants?.length > 0) {
      const variantsArray = [];
      variants?.map(
        (variant: PersonalizationBlockType_personalizationBlock_variantsCollection_items) =>
          variantsArray.push(variant)
      );
      setPersonalizedContentArray(variantsArray);
    } else {
      setPersonalizedContentArray(
        personalizationBlock?.fallbackVariation
          ? Array.of(personalizationBlock?.fallbackVariation)
          : []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedSector]);

  return (
    <CFContentArea
      desktopColumns={1}
      contentAreaItems={personalizedContentArray}
      context={context}
      maxWidth={maxWidth}
    />
  );
};

export default PersonalizationBlock;
