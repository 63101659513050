import ComplaintsForm from "@components/AfaForms/ComplaintsForm/ComplaintsForm";
import ContactForm from "@components/AfaForms/ContactForm/ContactForm";
import NewsletterForm from "@components/AfaForms/NewsletterForm/NewsletterForm";
import TestpanelForm from "@components/AfaForms/TestpanelForm/TestpanelForm";

import { Context } from "types/context/Context";

import { FormBlockType_formBlock } from "./queries/contentfulTypes/FormBlockType";

interface FormBlockProps {
  blockData: FormBlockType_formBlock;
  uniqueKey?: string;
  context: Context;
}

export interface FormProps {
  contentfulPreviewEntryId?: string;
}

const testFormIds = {
  "Nyhetsbrevsanmälan-Hälsa och ohälsa i arbetslivet": "7109a16c-6d21-ef11-840a-000d3aa9aa46",
  "Nyhetsbrevsanmälan-Afa Försäkring och omvärlden": "48b1739d-6d21-ef11-840a-000d3aa9aa46",
  "Nyhetsbrevsanmälan-Forskning och utveckling": "684f12e6-6d21-ef11-840a-000d3aa9aa46",
  "Nyhetsbrevsanmälan-Trygg på jobbet": "73043510-6e21-ef11-840a-000d3aa9aa46",
  "Nyhetsbrevsanmälan-Inbjudningar till frukostseminarier": "006adc46-6e21-ef11-840a-000d3aa9aa46",
  "Nyhetsbrevsanmälan-Statistikrapporter": "511251a1-6e21-ef11-840a-000d3aa9aa46",
  "Nyhetsbrevsanmälan-Arbetsmiljö och hälsa": "d7e74516-c83a-ef11-8409-000d3a21a1de",
};

const prodFormIds = {
  "Nyhetsbrevsanmälan-Hälsa och ohälsa i arbetslivet": "664eca11-9808-ef11-9f89-6045bd92e338",
  "Nyhetsbrevsanmälan-Afa Försäkring och omvärlden": "fafccc31-9808-ef11-9f89-6045bd92e338",
  "Nyhetsbrevsanmälan-Forskning och utveckling": "6bb29215-7813-ef11-9f89-7c1e52126257",
  "Nyhetsbrevsanmälan-Trygg på jobbet": "89563ce5-7713-ef11-9f89-7c1e52126257",
  "Nyhetsbrevsanmälan-Inbjudningar till frukostseminarier": "8fa966f5-3b1f-ef11-840b-7c1e52126257",
  "Nyhetsbrevsanmälan-Statistikrapporter": "bf14dc4b-9808-ef11-9f89-6045bd92e338",
  "Nyhetsbrevsanmälan-Arbetsmiljö och hälsa": "48796298-9a75-ef11-a670-000d3a49d798",
};

const getMailingListId = (formId: string): string => {
  const kongUrl = process.env.NEXT_PUBLIC_KONG_URL;
  const testEnvironment = kongUrl?.includes("test");

  if (testEnvironment) return testFormIds[formId];
  return prodFormIds[formId];
};

export const FormBlock = ({ blockData: formBlock }: FormBlockProps) => {
  const GetForm = (
    formId: string,
    formTitle: string,
    formDescription: string,
    simpleNewsletterVersion: boolean
  ): JSX.Element => {
    if (formId == "Kontaktformulär")
      return <ContactForm contentfulPreviewEntryId={formBlock.sys.id} />;
    if (formId == "Klagomålsformulär")
      return <ComplaintsForm contentfulPreviewEntryId={formBlock.sys.id} />;
    if (formId == "Testpanel") return <TestpanelForm contentfulPreviewEntryId={formBlock.sys.id} />;

    const mailingListId = getMailingListId(formId);
    return (
      <NewsletterForm
        mailingListId={mailingListId}
        title={formTitle}
        description={formDescription}
        simpleVersion={simpleNewsletterVersion}
        contentfulPreviewEntryId={formBlock.sys.id}
      />
    );
  };
  return GetForm(
    formBlock?.formId,
    formBlock?.formTitle,
    formBlock?.formDescription,
    formBlock?.simpleNewsletterVersion
  );
};
export default FormBlock;
