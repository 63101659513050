import { replaceImageVaultBasePath } from "@utils/urlResolver";

import { UrlStructure } from "types/UrlStructure";
import { UrlStructureItem } from "types/UrlStructureItem";

export const getUrlByLink = (item: any, array: UrlStructure): string => {
  if (item?.globalLinkItem && item?.globalLinkItem?.value) return item?.globalLinkItem?.value;
  if (array && item?.reference?.imageVaultFile) {
    let url = "";
    url = replaceImageVaultBasePath(item?.reference?.imageVaultFile?.[0]?.mediaUrl, false);

    if (url?.length > 0) return url;
  }
  if (array && item?.reference?.slug) {
    let url = "";
    url = array?.urls?.find((arr: UrlStructureItem) => arr?.slug === item?.reference?.slug)?.url;
    if (url?.length > 0) return url;
  }

  if (item?.url !== null) {
    return item?.url;
  }
  return "";
};
