import { useRouter } from "next/router";
import { RefObject } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Pagination from "@afa-shared/afa-components/dist/Pagination";

import { IFocusState, useFocusState } from "hooks/zustand/useFocusState";

import paginationStyles from "./Pagination.module.css";
import { GetDotedPagination } from "./dotedPaginationHelper";

interface PaginationProps {
  navbarLabel: string;
  itemsPerPage?: number;
  totalItems: number;
  listRef: RefObject<any>;
  searchParameters?: unknown;
  marginTop?: string;
  className?: string;
}

const PaginationBlock = ({
  itemsPerPage = 10,
  totalItems,
  searchParameters,
  marginTop,
  listRef,
  navbarLabel,
  className,
}: PaginationProps) => {
  const setOverridePageTopFocus = useFocusState(
    (state: IFocusState) => state.setOverridePageTopFocus
  );
  const setOnPageLoadRef = useFocusState((state: IFocusState) => state.setOnPageLoadRef);
  const router = useRouter();
  const totalPaginationPages = Math.ceil(totalItems / itemsPerPage);

  const routerSlug = router?.query?.slug;
  const pageString = routerSlug?.[routerSlug?.length - 1];
  const currentPaginationPage = parseInt(pageString?.split("-")?.[1], 10) || 1;

  const queryString =
    searchParameters &&
    Object?.keys(searchParameters)
      .flatMap((key) =>
        !searchParameters[key] || key === "page" ? [] : key + "=" + searchParameters[key]
      )
      .join("&");

  const url =
    router?.asPath?.indexOf("?") < 0
      ? router?.asPath
      : router?.asPath?.substring(0, router?.asPath?.indexOf("?"));

  const handleItemClick = () => {
    setOverridePageTopFocus(true);
    setOnPageLoadRef(listRef);
  };

  return (
    totalPaginationPages > 0 && (
      <Grid
        margin={marginTop && `${marginTop} 0 0`}
        direction="row"
        justifyContent="center"
        className={`${paginationStyles.pagination} ${className}`}
      >
        <Pagination
          paginationItems={GetDotedPagination(
            totalPaginationPages,
            currentPaginationPage,
            url,
            queryString,
            handleItemClick
          )}
          navbarLabel={navbarLabel}
        />
      </Grid>
    )
  );
};
export default PaginationBlock;
