import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Icon from "@afa-shared/afa-components/dist/Icon";
import Message from "@afa-shared/afa-components/dist/Message";
import { OperationalInfoFrontendMessage } from "@pages/api/getOperationalInfoMessages";

import styles from "./OperationalInfo.module.css";

const cookies = new Cookies();
const messagesClosedCookieName = "messages";

const OperationalInfo = () => {
  const [messages, setMessages] = useState<OperationalInfoFrontendMessage[]>([]);
  const [messagesClosed, setMessagesClosed] = useState<string[]>(
    cookies.get(messagesClosedCookieName) || []
  );

  const router = useRouter();

  const pathWithoutQuery = router.asPath.split("?")[0];

  useEffect(() => {
    const abortController = new AbortController();
    const path = encodeURIComponent(pathWithoutQuery);
    const fetchOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
      signal: abortController.signal,
    };

    fetch(`/api/getOperationalInfoMessages?path=${path}`, fetchOptions)
      .then((response) => response.json())
      .then((data) => setMessages(data.messages))
      .catch((e) => console.error(e));

    return () => abortController.abort();
  }, [pathWithoutQuery, setMessages]);

  const handleClose = (message: OperationalInfoFrontendMessage) => {
    if (!message.userCanCloseMessage) {
      return;
    }
    const updatedMessagesClosed = [...messagesClosed, message.id];
    setMessagesClosed(updatedMessagesClosed);
    const date = new Date();
    const expires = date.setDate(date.getDate() + 3);
    cookies.set(messagesClosedCookieName, updatedMessagesClosed, {
      expires: new Date(expires),
    });
  };

  const getTypeClass = (messageType: string) => {
    switch (messageType) {
      case "info":
        return styles.info;
      case "error":
        return styles.error;
      case "warning":
        return styles.warning;
      case "success":
        return styles.success;
      default:
        return "";
    }
  };

  const messagesToDisplay = messages.filter((message) => !messagesClosed.includes(message.id));

  if (!messagesToDisplay.length) {
    return null;
  }

  return (
    <div className="operational-info">
      {messagesToDisplay.map((message) => (
        <div
          className={`${styles.operationalInfo} ${getTypeClass(message.messageType)}`}
          key={message.id}
        >
          <div className={styles.messageWrapper}>
            <Message
              heading={message.header}
              headingVariant="h4"
              type={message.messageType}
              closable={message.userCanCloseMessage}
              closeOnClick={() => handleClose(message)}
            >
              {(message.message || message.link) && (
                <div className={styles.childWrapper}>
                  {message.message && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message.message,
                      }}
                    />
                  )}

                  {message.linkType > 0 && (
                    <>
                      {message.linkType === 1 && (
                        <Button
                          href={message.link}
                          target="_self"
                          label={message.linkText}
                          buttonType={"secondary"}
                          onClick={null}
                        />
                      )}
                      {message.linkType === 2 && (
                        <a
                          href={message.link}
                          target="_self"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0.5rem 0rem 0rem 0rem",
                          }}
                        >
                          <Icon name="arrow_forward" size="sm" />
                          {message.linkText}
                        </a>
                      )}
                    </>
                  )}
                </div>
              )}
            </Message>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OperationalInfo;
