import { ImageSize } from "@afa-shared/afa-components/dist/Card";

import { Context } from "types/context/Context";

const shouldRemoveBorder = (context: Context, backgroundColor?: string): boolean => {
  return (
    context?.relatedContent !== null ||
    (context?.sectionBlock && context?.sectionBlock?.coloredSection) ||
    (backgroundColor && (backgroundColor === "Ljusgrön" || backgroundColor === "Ljusblå"))
  );
};

const getDirection = (size: number, maxWidth: number, tabletOnly: boolean) => {
  return (maxWidth === 1240 && size >= 6) ||
    (maxWidth === 1240 && size < 6 && tabletOnly) ||
    (maxWidth < 1240 && (size === 12 || (size < 12 && tabletOnly)))
    ? "row"
    : "column";
};

const getReverse = (size: number, maxWidth: number) => {
  return (maxWidth === 1240 && size >= 6) || (maxWidth < 1240 && size === 12);
};

const getTeaserImageSize = (
  size: number,
  maxWidth: number,
  shouldUseSmallImages: boolean,
  context: Context
): ImageSize => {
  if (context?.accordion) {
    return "m";
  }
  if (maxWidth === 1240) {
    if (size === 12) {
      return "xl";
    }
    return shouldUseSmallImages ? "xs" : "l";
  }
  if (size === 12) {
    return "m";
  }
  if (size < 12) {
    return "s";
  }
};

const shouldUseSmallImages = (size: number, maxWidth: number, context: Context) => {
  return (
    maxWidth === 1240 &&
    size === 6 &&
    ((context?.sectionBlock && context?.sectionBlock?.smallImages) ||
      (context?.relatedContent && context?.relatedContent?.smallImages))
  );
};

const shouldUseNotebookSmallImages = (size: number, maxWidth: number) => {
  return maxWidth === 1240 && size === 6;
};

const shouldRemovePadding = (blockColor: string, context: Context) => {
  return (
    (context?.relatedContent && blockColor === "Ljusgrön") ||
    (context?.sectionBlock &&
      context?.sectionBlock?.backgroundColor === "Ljusgrön" &&
      blockColor === "Ljusgrön") ||
    (context?.sectionBlock &&
      context?.sectionBlock?.backgroundColor === "Ljusblå" &&
      blockColor === "Ljusblå")
  );
};

export {
  shouldRemoveBorder,
  getDirection,
  getReverse,
  getTeaserImageSize,
  shouldUseSmallImages,
  shouldUseNotebookSmallImages,
  shouldRemovePadding,
};
