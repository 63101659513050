import useMediaQuery from "hooks/useMediaQuery";

import DynamicMainNavigation from "./Desktop";
import DynamicMobileNavigation from "./Mobile";
import navigationStyles from "./navigation.module.css";

const RenderMenu = ({
  isForsakringPages,
  toggleMenu,
  setToggleMenu,
  isBrandPortal,
  brandMenu,
  isCampaignPage,
  campaignMenu,
  mobileMenu,
  mobileMenuShortcuts,
  mobileMenuId,
  desktopMenu,
  path,
}) => {
  const isDesktopUp = useMediaQuery("desktopup");

  return isDesktopUp ? (
    <DynamicMainNavigation menuItems={desktopMenu} currentUrl={path} />
  ) : (
    <div
      className={`${navigationStyles.mobileMenuWrapper} ${
        isForsakringPages ? "" : navigationStyles.useTopPadding
      } ${toggleMenu ? "" : navigationStyles.mobileMenuClosed}`}
    >
      <DynamicMobileNavigation
        showMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        toggleMenu={toggleMenu}
        menuItems={isBrandPortal ? brandMenu : isCampaignPage ? campaignMenu : mobileMenu}
        mobileMenuShortcuts={mobileMenuShortcuts}
        mobileMenuId={mobileMenuId}
        isBrandPortal={isBrandPortal}
        isCampaignPage={isCampaignPage}
      />
    </div>
  );
};

export default RenderMenu;
