import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import TimeLine from "@afa-shared/afa-components/dist/Timeline";
import RichTextRenderer from "@components/RichTextRenderer";

import { Context } from "types/context/Context";

import { TimelineBlockType_timelineBlock } from "./queries/contentfulTypes/TimelineBlockType";

interface ITimelineBlockProps {
  blockData: TimelineBlockType_timelineBlock;
  uniqueKey?: string;
  context: Context;
}

export const TimelineBlock = ({ blockData: timelineBlock, context }: ITimelineBlockProps) => {
  const timelineItems = timelineBlock?.stepsCollection?.items?.map(
    (item: any, index: number): any => {
      return (
        <RichTextRenderer
          key={index}
          json={item?.restrictedRichTextBlock?.body?.json}
          links={item?.restrictedRichTextBlock?.body?.links}
        />
      );
    }
  );

  return (
    <Grid
      data-blockname="tidslinje"
      data-contentful-entry-id={timelineBlock?.sys?.id}
      data-contentful-field-id="name"
    >
      {timelineBlock?.heading && (
        <Heading variant={context?.heading?.parentLevel}>{timelineBlock?.heading}</Heading>
      )}
      {timelineBlock?.text && <Text variant={"paragraph"} children={timelineBlock?.text} />}
      <TimeLine>{timelineItems}</TimeLine>
    </Grid>
  );
};
export default TimelineBlock;
