import { HeadingContext } from "types/context/HeadingContext";

export const getHeadingVariants = (heading: string) => {
  const headingContext: HeadingContext = {
    parentLevel: "h2",
    childLevel: "h3",
  };

  switch (heading) {
    case "h2":
      headingContext.parentLevel = "h2";
      headingContext.childLevel = "h3";
      break;

    case "h3":
      headingContext.parentLevel = "h3";
      headingContext.childLevel = "h4";
      break;

    case "h4":
      headingContext.parentLevel = "h4";
      headingContext.childLevel = "h5";
      break;

    case "h5":
      headingContext.parentLevel = "h5";
      headingContext.childLevel = "h6";
      break;
    case "h6":
      headingContext.parentLevel = "h6";
      headingContext.childLevel = "h6";
      break;

    default:
      headingContext.parentLevel = "h2";
      headingContext.childLevel = "h3";
      break;
  }

  return headingContext;
};
