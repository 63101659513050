import { PaymentDay } from "contexts/PaymentDaysContext";

import FactCard from "@afa-shared/afa-components/dist/FactCard";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Text from "@afa-shared/afa-components/dist/Text";

import { Context } from "types/context/Context";

import paymentDaysBlockStyles from "./PaymentDaysBlock.module.css";
import { getClosestPaymentDayAsObject } from "./paymentDaysHelper";
import { PaymentDaysBlockType_paymentDaysBlock } from "./queries/contentfulTypes/PaymentDaysBlockType";

interface PaymentDaysFactCardTwoDatesProps {
  paymentDaysMonthly: PaymentDay[];
  paymentDaysDaily: PaymentDay[];
  context?: Context;
  blockData: PaymentDaysBlockType_paymentDaysBlock;
}

export const PaymentFactCardTwoDates = ({
  paymentDaysMonthly,
  paymentDaysDaily,
  context,
  blockData,
}: PaymentDaysFactCardTwoDatesProps) => {
  const closestMonthlyPayment = getClosestPaymentDayAsObject(paymentDaysMonthly);
  const closestDailyPayment = getClosestPaymentDayAsObject(paymentDaysDaily);

  return (
    <div
      className={paymentDaysBlockStyles.paymentFactCard}
      data-contentful-entry-id={blockData.sys?.id}
      data-contentful-field-id="name"
    >
      <FactCard
        heading={"Nästa utbetalningsdag"}
        headingVariant={context?.heading?.parentLevel}
        type={"blue"}
        data-blockname="faktaruta"
        className={paymentDaysBlockStyles.factCard}
        tabIndex={0}
      >
        <Grid direction="row" className={paymentDaysBlockStyles.textContainer}>
          <Text variant="span" weight="bold" className={paymentDaysBlockStyles.text}>
            Månadsersättning:
          </Text>
          <Text variant="paragraph" className={paymentDaysBlockStyles.text}>
            {closestMonthlyPayment ? (
              <span>
                {closestMonthlyPayment.dayOfWeek} {closestMonthlyPayment.dayOfMonth}{" "}
                {closestMonthlyPayment.month}
              </span>
            ) : (
              <span>Något gick fel</span>
            )}
          </Text>
        </Grid>
        <Grid direction="row" className={paymentDaysBlockStyles.textContainer}>
          <Text variant="span" weight="bold" className={paymentDaysBlockStyles.text}>
            Dagsersättning:
          </Text>
          <Text variant="paragraph" className={paymentDaysBlockStyles.text}>
            {closestDailyPayment ? (
              <span>
                {closestDailyPayment.dayOfWeek} {closestDailyPayment.dayOfMonth}{" "}
                {closestDailyPayment.month}
              </span>
            ) : (
              <span>Något gick fel</span>
            )}
          </Text>
        </Grid>
      </FactCard>
    </div>
  );
};
export default PaymentFactCardTwoDates;
