export const pixelsToRem = (...pixels: Array<number>): string => {
  const rems = pixels?.map((i) => {
    return getRemFromPixel(i);
  });
  return `${rems.join(" ")}`;
};

const getRemFromPixel = (pixel: number) => {
  return `${Math.round((pixel / 16) * 10000) / 10000}rem`;
};
