import Message from "@afa-shared/afa-components/dist/Message";
import Text from "@afa-shared/afa-components/dist/Text";

const FormSendError = () => {
  return (
    <Message
      heading={"Ett fel har inträffat"}
      headingVariant={"h2"}
      type={"error"}
      children={
        <>
          <Text
            variant="paragraph"
            children={
              "Vi kunde inte skicka iväg ditt meddelande på grund av att ett tekniskt fel har inträffat. Var vänlig försök igen senare."
            }
          />
        </>
      }
    />
  );
};

export default FormSendError;
