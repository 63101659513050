import { useEffect, useId } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import LinkedHeading from "@components/LinkedHeading/LinkedHeading";
import CFContentArea from "@components/cFContentArea";
import { getBackgroundColor } from "@utils/pageHelpers/getBackgrundColor";
import { pixelsToRem } from "@utils/sizeHelper";
import { isNullOrEmpty } from "@utils/string";

import { Context } from "types/context/Context";
import { HeadingContext } from "types/context/HeadingContext";
import { SectionBlockContext } from "types/context/SectionBlockContext";

import { SectionBlockType_sectionblock } from "./queries/contentfulTypes/SectionBlockType";
import sectionStyles from "./section.module.css";

interface ISectionBlockProps {
  blockData: SectionBlockType_sectionblock;
  uniqueKey?: string;
  context: Context;
  maxWidth?: number;
}

export const SectionBlock = ({
  blockData: sectionblock,
  context,
  maxWidth,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ISectionBlockProps) => {
  //TODO: handle small images
  const sectionBlockContext: SectionBlockContext = {
    noHeading: !sectionblock?.showHeading,
    noImage: !sectionblock?.showImage,
    noText: !sectionblock?.showText,
    noLink: !sectionblock?.showLinks,
    coloredSection:
      sectionblock?.backgroundColor === "Ljusblå" || sectionblock?.backgroundColor === "Ljusgrön"
        ? true
        : false,
    backgroundColor: sectionblock?.backgroundColor,
    smallImages: sectionblock?.smallImages,
  };
  const headingContext: HeadingContext = {
    parentLevel: context?.heading?.parentLevel,
    childLevel:
      sectionblock?.heading && sectionblock?.showHeading
        ? context?.heading?.childLevel
        : context?.heading?.parentLevel,
  };
  context = {
    ...context,
    sectionBlock: sectionBlockContext,
    columns: sectionblock?.columns,
    heading: headingContext,
  };

  const backgroundColor = getBackgroundColor(sectionblock?.backgroundColor);
  const sectionId = useId();

  useEffect(() => {
    if (!sectionblock?.isWide) return;
    const details = navigator.userAgent;
    const regexp = /android|iphone|ipad/i;
    const isMobileDevice = regexp.test(details);

    if (!isMobileDevice && document !== undefined) {
      const scrollbarOffset = window?.innerWidth - document?.body?.clientWidth;

      const calculatedPadding = pixelsToRem(48 + scrollbarOffset / 2);
      document
        ?.getElementById(sectionId)
        ?.style?.setProperty("--desktop-padding", calculatedPadding);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headingDirectionClass =
    sectionblock?.contentDirection === "Centrerat"
      ? sectionStyles.alignCenter
      : sectionblock?.contentDirection === "Höger"
      ? sectionStyles.alignEnd
      : "";

  const hasPreamble = sectionblock?.preamble?.length > 0;

  return (
    <Grid
      className={`${sectionStyles.section} ${sectionblock?.isWide ? sectionStyles.isWide : ""} ${
        isNullOrEmpty(backgroundColor) ? "" : sectionStyles.backgroundColor
      } ${maxWidth >= 1240 ? sectionStyles.widePage : ""}`}
      container
      justifyContent="center"
      alignItems="center"
      backgroundColor={backgroundColor}
      id={sectionId}
      variant={"section"}
      data-contentful-entry-id={sectionblock?.sys?.id}
      data-contentful-field-id="name"
    >
      <Grid
        container
        maxWidth={pixelsToRem(1240)}
        backgroundColor={backgroundColor}
        className={sectionStyles.container}
      >
        <Grid
          className={`${sectionStyles.sectionWrapper} ${
            isNullOrEmpty(backgroundColor) ? "" : sectionStyles.hasBackground
          }`}
          container
          backgroundColor={backgroundColor}
          desktop={maxWidth === 1240 ? 12 : sectionblock?.isWide ? 8 : 12}
          margin={"0 auto"}
        >
          {sectionblock?.heading && sectionblock?.showHeading && (
            <div
              className={`${sectionStyles.headingWrapper} ${headingDirectionClass} ${
                hasPreamble ? sectionStyles.hasPreamble : ""
              }`}
            >
              {sectionblock?.linkOnSection ? (
                <LinkedHeading
                  url={sectionblock?.linkOnSection?.url}
                  headingLevel={context?.heading?.parentLevel}
                  heading={sectionblock?.heading}
                />
              ) : (
                <Heading variant={context?.heading?.parentLevel} removeMargin>
                  {sectionblock?.heading}
                </Heading>
              )}
            </div>
          )}
          {hasPreamble && (
            <Text variant="paragraph" className={sectionStyles.preamble}>
              {sectionblock.preamble}
            </Text>
          )}
          <CFContentArea
            mobileColumns={1}
            tabletColumns={1}
            notebookColumns={sectionblock?.columns}
            desktopColumns={sectionblock?.columns}
            contentAreaItems={sectionblock?.columnAreaCollection?.items}
            context={context}
            heading={context?.heading?.childLevel}
            maxWidth={maxWidth}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionBlock;
