import FactCard from "@afa-shared/afa-components/dist/FactCard";
import Message from "@afa-shared/afa-components/dist/Message";
import TrackedLink from "@components/TrackedLink";
import { getInternalOrExternalIcon } from "@utils/linkIconHelper";

import { Context } from "types/context/Context";

import RichTextRenderer from "../../components/RichTextRenderer";
import { getInformationType } from "./informationBlockHelper";
import { InformationBlockType_informationBlock } from "./queries/contentfulTypes/InformationBlockType";

interface IInformationProps {
  blockData: InformationBlockType_informationBlock;
  uniqueKey?: string;
  context?: Context;
}

const index = ({ blockData: infoBlock, context, uniqueKey }: IInformationProps) => {
  const url = infoBlock?.linkReference?.url;
  const externalReference = infoBlock?.linkReference?.url;
  const internalReference = infoBlock?.linkReference?.reference;
  const globalReference = infoBlock?.linkReference?.globalLinkItem;
  const linkVariant =
    infoBlock?.linkReference?.variant &&
    infoBlock?.linkReference?.variant?.toLowerCase() === "knapp"
      ? "button"
      : "link";
  const linkType = getInternalOrExternalIcon(externalReference, internalReference, globalReference);

  const type: any = getInformationType(infoBlock?.infoVariant?.toLowerCase());

  return (
    <div data-contentful-entry-id={infoBlock.sys.id} data-contentful-field-id="name">
      {type === "blue" || type === "" ? (
        <FactCard
          key={uniqueKey}
          heading={infoBlock?.heading}
          headingVariant={context?.heading?.parentLevel}
          type={type}
          linkType={
            infoBlock?.linkReference && url ? (
              <TrackedLink
                linkType={linkType}
                linkText={infoBlock?.linkReference?.linkText}
                url={url}
                linkVariant={linkVariant}
              />
            ) : (
              <></>
            )
          }
          data-blockname="faktaruta"
        >
          {infoBlock?.body?.json && (
            <RichTextRenderer json={infoBlock?.body?.json} links={infoBlock?.body?.links} />
          )}
        </FactCard>
      ) : (
        <Message
          key={uniqueKey}
          heading={infoBlock?.heading}
          headingVariant={context?.heading?.parentLevel}
          type={type}
          linkType={
            infoBlock?.linkReference &&
            url && (
              <TrackedLink
                linkType={linkType}
                linkText={infoBlock?.linkReference?.linkText}
                url={url}
                linkVariant={linkVariant}
              />
            )
          }
          data-blockname="infomation"
        >
          {infoBlock?.body?.json && (
            <RichTextRenderer json={infoBlock?.body?.json} links={infoBlock?.body?.links} />
          )}
        </Message>
      )}
    </div>
  );
};
export default index;
