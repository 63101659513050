import { useEffect, useState } from "react";

export interface Size {
  width: number | undefined;
  height: number | undefined;
  isLargeMobile: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isTabletOnly: boolean;
  isDesktop: boolean;
  isDesktopUp: boolean;
  isLargeDesktop: boolean;
}

// Hook
export const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
    isLargeMobile: false,
    isMobile: false,
    isTablet: false,
    isTabletOnly: false,
    isDesktop: false,
    isDesktopUp: false,
    isLargeDesktop: false,
  });
  useEffect(() => {
    function handleResize() {
      let windowSizeObject = {
        width: window.innerWidth,
        height: window.innerHeight,
        isLargeMobile: false,
        isMobile: false,
        isTablet: false,
        isTabletOnly: false,
        isDesktop: false,
        isDesktopUp: false,
        isLargeDesktop: false,
      };

      // Large desktop
      if (window.innerWidth >= 1920) {
        windowSizeObject.isLargeDesktop = true;
      }
      // Desktop up
      if (window.innerWidth >= 1240) {
        windowSizeObject.isDesktopUp = true;
      }

      // Desktop only
      if (window.innerWidth >= 1240 && window?.innerWidth < 1920) {
        windowSizeObject.isDesktop = true;
      }
      // Tablet only
      if (window.innerWidth < 1240 && window.innerWidth > 768) {
        windowSizeObject.isTabletOnly = true;
      }
      // Tablet down to zero px
      if (window.innerWidth < 1240) {
        windowSizeObject.isTablet = true;
      }
      // Mobile up to 400 pixels
      if (window.innerWidth <= 768 && window.innerWidth > 450) {
        windowSizeObject.isLargeMobile = true;
      }
      // Mobile up to 768 pixels
      if (window.innerWidth <= 768) {
        windowSizeObject.isMobile = true;
      }

      setWindowSize(windowSizeObject);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};
