import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect, useId, useRef, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import { FactCard } from "@afa-shared/afa-components";
import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Select from "@afa-shared/afa-components/dist/Inputs/Select";
import TextArea from "@afa-shared/afa-components/dist/Inputs/TextArea";
import TextInput from "@afa-shared/afa-components/dist/Inputs/TextInput";
import Text from "@afa-shared/afa-components/dist/Text";
import { FormProps } from "@components/FormBlock";
import TrackedLink from "@components/TrackedLink";
import { LinkType } from "@utils/linkIconHelper";

import FormErrorFeedback from "../Feedback/FormErrorFeedback";
import FormSendFeedback from "../Feedback/FormSendFeedback";
import FormSuccessFeedback from "../Feedback/FormSuccessFeedback";
import FormValidationFeedback from "../Feedback/FormValidationFeedback";
import {
  emailMaxInputLength,
  generalMaxInputLength,
  messageMaxInputLength,
  phoneMaxInputLength,
} from "../formConstants";
import { scrollIntoView, validate } from "../formHelper";
import { complaintsFormTracker, formTracker } from "../formTracker";
import formStyles from "../forms.module.css";

export const ComplaintsForm = ({ contentfulPreviewEntryId }: FormProps) => {
  const router = useRouter();
  const routerAsPath = router.asPath;
  const [formState, setFormState] = useState({
    name: { value: "", required: false },
    email: { value: "", required: true },
    phone: { value: null, required: false, checkValidIfNotEmpty: true },
    caseType: { value: "", required: true },
    message: { value: "", required: true },
    caseNumber: { value: "", required: false },
  });

  const [previewing, setPreviewing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    email: null,
    phone: null,
    caseType: null,
    message: null,
    approved: null,
  });

  const [displayForm, setDisplayForm] = useState<boolean>(true);
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [errorSendMail, setErrorSendMail] = useState<boolean>(false);
  const [hasSingleError, setHasSingleError] = useState<boolean>(false);

  const formRefs = {
    sendFeedback: useRef(null),
    errorInfobox: useRef(null),
    email: useRef(null),
    caseType: useRef(null),
    message: useRef(null),
    phone: useRef(null),
    previewRef: useRef(null),
  };

  const formIds = {
    name: useId(),
    email: useId(),
    phone: useId(),
    caseType: useId(),
    message: useId(),
    caseNumber: useId(),
    header: useId(),
  };

  const caseTypes = [
    { id: 1, name: "Sjukskrivning", value: "Sjukskrivning" },
    { id: 2, name: "Arbetsskada", value: "Arbetsskada" },
    { id: 3, name: "Dödsfall", value: "Dödsfall" },
    {
      id: 4,
      name: "Gravid/Föräldraledighet",
      value: "Gravid/Föräldraledighet",
    },
    { id: 5, name: "Uppsägning", value: "Uppsägning" },
    { id: 6, name: "Övrigt", value: "Övrigt" },
  ];

  const personuppgifterPath = "/personuppgifter";

  useEffect(() => {
    if (router.asPath.includes("#preview")) {
      const formErrors = validate(formState, errors);
      const foundErrors = Object.values(formErrors).filter((e) => e !== null).length;
      if (foundErrors) {
        setPreviewing(false);
        router.replace({ pathname: routerAsPath.replace("#preview", "") });
      } else {
        setPreviewing(true);
      }
    } else {
      setPreviewing(false);
    }
  }, [router.asPath, errors, formState, router, routerAsPath]);

  const goToPreview = (evt: any) => {
    evt.preventDefault();
    setHasSingleError(false);
    const formErrors = validate(formState, errors);
    setErrors(formErrors);
    const foundErrors = Object.values(formErrors).filter((e) => e !== null).length;

    if (foundErrors) {
      if (foundErrors === 1) {
        setHasSingleError(true);
        const key = Object.keys(formErrors).find((key) => formErrors[key] !== null);
        scrollIntoView(formRefs[key]);
      } else {
        scrollIntoView(formRefs?.errorInfobox);
      }
    } else {
      router.replace({ pathname: routerAsPath, hash: "preview" });
      scrollIntoView(formRefs?.previewRef);
    }
  };

  const editForm = () => {
    router.replace({ pathname: routerAsPath.replace("#preview", "") });
  };

  const handleFormChange = (e: any, key: string = null) => {
    const { value } = e.target;
    setFormState({ ...formState, [key]: { ...formState[key], value: value } });
    setErrors({ ...errors, [key]: null });
  };

  const handleErrorResponse = (): void => {
    setErrorSendMail(true);
    setDisplayForm(true);
    formTracker("felmeddelande", "klagomål");
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    let cleanFormState = {};
    for (const field in formState) {
      cleanFormState = { ...cleanFormState, [field]: formState[field].value };
    }
    setIsLoading(true);
    try {
      const res = await fetch("/api/sendComplaintMail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cleanFormState),
      });

      if (res?.ok) {
        setDisplayForm(false);
        complaintsFormTracker("inskickat", formState["caseType"].value);
        formRefs?.sendFeedback?.current?.focus();
      } else {
        handleErrorResponse();
      }
      setIsLoading(false);
      router.replace({ pathname: routerAsPath.replace("#preview", "") });
    } catch (err) {
      handleErrorResponse();
      setIsLoading(false);
    }
  };

  const handleReCaptchaVerify = (token) => {
    if (!token) {
      return;
    }
    token && setIsValidToken(true);
  };

  const renderErrorInfoBox = () => (
    <FormValidationFeedback
      formErrors={errors}
      formRefs={formRefs}
      hasSingleError={hasSingleError}
    />
  );
  const renderFormFeedback = () => (
    <FormSuccessFeedback
      messageHeading="Tack!"
      messageChildren={
        <>
          <Text variant="paragraph" children={"Ditt meddelande har skickats."} />
        </>
      }
      messageLink={
        <TrackedLink
          url={personuppgifterPath}
          linkText={"Så behandlar vi personuppgifter"}
          linkType={LinkType.Internal}
          linkVariant={"link"}
          target={"_self"}
        />
      }
    />
  );
  const renderErrorMessage = () => (
    <FormErrorFeedback
      messageHeading="Meddelandet kunde inte skickas"
      messageChildren={
        <>
          <Text
            variant="paragraph"
            children={
              <span>
                På grund av ett tekniskt fel kunde ditt meddelande inte skickas. Prova igen lite
                senare, eller mejla till{" "}
                <a href="mailto:klagomalsansvarig@afaforsakring.se">
                  klagomalsansvarig@afaforsakring.se
                </a>
                .
              </span>
            }
          />
        </>
      }
    />
  );

  const showFormFeedback: boolean = !displayForm && !errorSendMail;

  return (
    <Grid
      direction={"column"}
      className={"form-container"}
      data-contentful-entry-id={contentfulPreviewEntryId}
      data-contentful-field-id="name"
    >
      <GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
      {
        <FormSendFeedback
          formSendRef={formRefs?.sendFeedback}
          formErrorFeedback={renderErrorMessage()}
          formSuccessFeedback={renderFormFeedback()}
          showSuccessFeedback={showFormFeedback}
          showErrorFeedback={errorSendMail}
        />
      }
      <div id="preview"></div>
      {displayForm && previewing && (
        <div className={formStyles.formPreviewWrapper}>
          <FactCard
            heading="Kontrollera dina uppgifter"
            type={"blue"}
            headingVariant={"h2"}
            className={formStyles.formPreviewCard}
            data-blockname="faktaruta"
          >
            {/* Enables data suppression for hotjar */}
            <div className={"data-hj-suppress"}>
              <Text variant="span" weight="bold" className={formStyles.formPreviewQuestion}>
                Ditt förnamn och efternamn
              </Text>
              <Text variant="paragraph" className={formStyles.formPreviewAnswer}>
                {formState?.name?.value || "-"}
              </Text>
              <Text variant="span" weight="bold" className={`${formStyles.formPreviewQuestion}`}>
                Din e-postadress
              </Text>
              <Text variant="paragraph" className={formStyles.previewHelpText}>
                Kontrollera att du har stavat e-postadressen rätt - annars kan vi inte nå dig.
              </Text>
              <Text variant="paragraph" className={formStyles.formPreviewAnswer}>
                {formState?.email?.value || "-"}
              </Text>
              <Text variant="span" weight="bold" className={formStyles.formPreviewQuestion}>
                Ditt telefonnummer
              </Text>
              <Text variant="paragraph" className={formStyles.formPreviewAnswer}>
                {formState?.phone?.value || "-"}
              </Text>
              <Text variant="span" weight="bold" className={formStyles.formPreviewQuestion}>
                Vad gäller ditt ärende?
              </Text>
              <Text variant="paragraph" className={formStyles.formPreviewAnswer}>
                {formState?.caseType?.value || "-"}
              </Text>
              <Text variant="span" weight="bold" className={formStyles.formPreviewQuestion}>
                Beskriv hur vi kan hjälpa dig
              </Text>
              <Text variant="paragraph" className={formStyles.formPreviewAnswer}>
                {formState?.message?.value || "-"}
              </Text>
              <Text variant="span" weight="bold" className={formStyles.formPreviewQuestion}>
                Ärendenummer eller personnummer
              </Text>
              <Text variant="paragraph" className={formStyles.formPreviewAnswer}>
                {formState?.caseNumber?.value || "-"}
              </Text>
            </div>
            <Button
              buttonType="secondary"
              onClick={() => editForm()}
              label={"Ändra"}
              className={formStyles.editButton}
              iconName="arrow_back"
            />
          </FactCard>
          <Button
            type="submit"
            buttonType="primary"
            onClick={
              isValidToken
                ? onSubmit
                : (e) => {
                    e.preventDefault();
                  }
            }
            label={isLoading ? "" : "Skicka"}
            className={clsx(formStyles.submitPreviewButton, { [formStyles.spinner]: isLoading })}
          />
        </div>
      )}
      {displayForm && !previewing && (
        <div className={`${formStyles.formBackground} ${formStyles.formWrapper}`}>
          <div className={formStyles.formHeadingWrapper}>
            <Heading id={formIds?.header} variant="h2" removeMargin>
              Ditt meddelande
            </Heading>
            <Text variant="paragraph" removeMargin>
              * Obligatoriskt fält
            </Text>
          </div>
          {renderErrorInfoBox()}
          <form aria-labelledby={formIds?.header} noValidate className={formStyles.form}>
            {/* STEP ONE */}
            <div className={formStyles.formStep}>
              <TextInput
                label={"Ditt förnamn och efternamn"}
                id={formIds?.name}
                name="name"
                autoComplete="name"
                value={formState?.name?.value}
                type={"text"}
                maxLength={generalMaxInputLength}
                fullWidth
                onChange={(e) => handleFormChange(e, "name")}
              />
              <TextInput
                inputRef={formRefs?.email}
                label={"Din e-postadress *"}
                required
                id={formIds?.email}
                autoComplete="email"
                value={formState?.email?.value}
                type={"email"}
                maxLength={emailMaxInputLength}
                width={"100%"}
                fullWidth
                description="Kontrollera att du har stavat e-postadressen rätt - annars kan vi inte nå dig."
                error={errors?.email?.input}
                onChange={(e) => handleFormChange(e, "email")}
              />
              <TextInput
                inputRef={formRefs?.phone}
                label={"Ditt telefonnummer"}
                id={formIds?.phone}
                autoComplete="tel"
                width={"100%"}
                value={formState?.phone?.value}
                type={"tel"}
                maxLength={phoneMaxInputLength}
                fullWidth
                error={errors?.phone?.input}
                onChange={(e) => handleFormChange(e, "phone")}
              />
            </div>
            <div className={formStyles.formStep}>
              {/* STEP TWO */}
              <Select
                inputRef={formRefs?.caseType}
                labelText={"Vad gäller ditt ärende? *"}
                required
                placeholder="Välj ämne"
                options={caseTypes}
                error={errors?.caseType?.input}
                id={formIds?.caseType}
                selected={formState?.caseType?.value || ""}
                onChange={(e) => {
                  handleFormChange(e, "caseType");
                  setErrors({ ...errors, ["caseType"]: null });
                }}
                width={"100%"}
              />
              <div>
                <TextArea
                  label="Beskriv hur vi kan hjälpa dig *"
                  textAreaRef={formRefs?.message}
                  required
                  id={formIds?.message}
                  value={formState?.message?.value}
                  name="message"
                  width={"100%"}
                  maxLength={messageMaxInputLength}
                  rows={8}
                  error={errors?.message?.input}
                  description="Skriv inte känsliga uppgifter om till exempel hälsa här."
                  onChange={(e) => handleFormChange(e, "message")}
                />
              </div>

              <TextInput
                label={"Ärendenummer eller personnummer"}
                id={formIds?.caseNumber}
                name="caseNumber"
                width="100%"
                value={formState?.caseNumber?.value}
                type={"text"}
                description="Skriv ärendenummer eller personnummer om du vill"
                maxLength={generalMaxInputLength}
                onChange={(e) => handleFormChange(e, "caseNumber")}
              />
            </div>
            <TrackedLink
              url={personuppgifterPath}
              linkText={"Så behandlar vi personuppgifter"}
              linkType={LinkType.Internal}
              linkVariant={"link"}
              target={"_self"}
            />

            <Button
              buttonType="primary"
              //router.pushstate
              onClick={(e) => goToPreview(e)}
              label={"Nästa"}
              className={formStyles.submitButton}
            />
          </form>
        </div>
      )}
    </Grid>
  );
};
export default ComplaintsForm;
