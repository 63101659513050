export interface IImageSrc {
  url: string;
  media?: string;
}

const ImageSrcSet = ({ imageArray }): JSX.Element => {
  return (
    <>
      {imageArray?.map((image, index) => (
        <source key={`${image}${index}`} srcSet={image?.url} media={image?.media} />
      ))}
    </>
  );
};

export default ImageSrcSet;
