import afaImageStyles from "./AfaImage.module.css";
import { IImageSrc } from "./ImageSources";
import ImageSrcSet from "./ImageSources";

interface IImage {
  fallbackUrl: string;
  alt: string;
  imageSrcSet: IImageSrc[];
  contentfulPreviewEntryId?: string;
  contentfulPreviewImageFieldId?: "ivImage" | "ivMainImage";
  className: string;
}

const AfaImage = ({
  fallbackUrl,
  alt,
  imageSrcSet,
  contentfulPreviewEntryId,
  contentfulPreviewImageFieldId,
  className,
}: IImage): JSX.Element => {
  return fallbackUrl ? (
    <picture
      className={`${afaImageStyles.afaImage} ${className ? className : ""}`}
      data-contentful-entry-id={contentfulPreviewEntryId}
      data-contentful-field-id={contentfulPreviewImageFieldId}
    >
      <ImageSrcSet imageArray={imageSrcSet} />
      <img src={fallbackUrl} alt={alt} loading="lazy" />
    </picture>
  ) : null;
};

export default AfaImage;
