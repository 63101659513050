import { createContext } from "react";

import { InformationBlockType_informationBlock } from "@components/InformationBlock/queries/contentfulTypes/InformationBlockType";

export const PAYMENT_DAYS_TYPE_DAILY = "Dagsersättning";
export const PAYMENT_DAYS_TYPE_MONTHLY = "Månadsersättning";
export const PAYMENT_DAYS_TYPE_PARENTAL = "Föräldrapenning";

export const PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_DAILY = "Dagsersättning";
export const PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_MONTHLY = "Månadsersättning";
export const PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_PARENTAL = "Föräldrapenning";
export const PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_CALENDAR = "Kalendervy";

export type PaymentDayType = "Dagsersättning" | "Månadsersättning" | "Föräldrapenning";

export type PaymentDayInsurance = "Sjukförsäkring" | "Föräldrapenning";

export type PaymentDay = {
  id: string;
  date: string;
  type: PaymentDayType;
  locked: boolean;
  insurance: PaymentDayInsurance;
};

export type PaymentDaysContextValue = {
  paymentDays: PaymentDay[];
  informationBlock: InformationBlockType_informationBlock | null;
  informationBlockPlacement: string[] | null;
};

export const PaymentDaysContext = createContext<PaymentDaysContextValue>(undefined);
