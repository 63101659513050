import { motion } from "framer-motion";
import { ReactNode } from "react";

import Message from "@afa-shared/afa-components/dist/Message";

interface IFormSuccessFeedback {
  messageHeading?: string;
  messageChildren?: ReactNode;
  messageLink?: ReactNode;
}

const FormSuccessFeedback = ({
  messageHeading,
  messageChildren,
  messageLink,
}: IFormSuccessFeedback) => {
  const heading = messageHeading ? messageHeading : "Din information har skickats";
  const feedbackVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  return (
    <motion.div
      variants={feedbackVariants}
      initial="initial"
      animate="animate"
      transition={{ delay: 0.4, duration: 0.5 }}
    >
      <Message
        heading={heading}
        headingVariant={"h2"}
        type={"success"}
        children={messageChildren}
        linkType={messageLink}
      />
    </motion.div>
  );
};

export default FormSuccessFeedback;
