import { PaymentDay } from "contexts/PaymentDaysContext";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/sv";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface PaymentDate {
  dayOfWeek: string;
  dayOfMonth: string;
  month: string;
}

const timezoneString = "Europe/Stockholm";

const sortDatesAsDayjs = (paymentDays: PaymentDay[]): Dayjs[] => {
  return paymentDays
    ?.map((d) => dayjs(d.date).locale("sv"))
    .sort((a, b) => (a.isBefore(b) ? -1 : 1));
};

const getPaymentDate = (date: Dayjs): PaymentDate => {
  dayjs.locale("sv");
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return {
    month: dayjs.tz(date, timezoneString).format("MMMM"),
    dayOfWeek: dayjs.tz(date, timezoneString).format("dddd"),
    dayOfMonth: dayjs.tz(date, timezoneString).format("D"),
  };
};

const getClosestPaymentDay = (paymentDays: PaymentDay[]): Dayjs => {
  const lockedDays = paymentDays?.filter((item) => item?.locked);
  if (lockedDays?.length > 0) {
    const sortedLockedDays = sortDatesAsDayjs(lockedDays);
    return sortedLockedDays?.[0];
  }
  const yesterday = dayjs().subtract(1, "day").locale("sv");
  const sortedDates = sortDatesAsDayjs(paymentDays);

  return sortedDates?.find((date) => date.isAfter(yesterday));
};

export const getClosestPaymentDayAsObject = (paymentDays: PaymentDay[]): PaymentDate | null => {
  const closestDate = getClosestPaymentDay(paymentDays);
  if (!closestDate) {
    return null;
  }
  return getPaymentDate(closestDate);
};

export const sortDates = (paymentDays: PaymentDay[]): PaymentDate[] => {
  return sortDatesAsDayjs(paymentDays).map(getPaymentDate);
};
