import {
  IS_DESKTOP,
  IS_DESKTOP_LARGE,
  IS_DESKTOP_UP,
  IS_MOBILE,
  IS_NOTEBOOK,
  IS_TABLET,
  IS_TABLET_ONLY,
} from "constants/MediaQueryConstants";
import { useState } from "react";
import { useEffect } from "react";

type queryType =
  | "mobile"
  | "tablet"
  | "tabletOnly"
  | "notebook"
  | "desktop"
  | "desktopup"
  | "desktopLarge";

interface UseMediaQueryOptions {
  fallback?: boolean | boolean[];
  ssr?: boolean;
}

const stringToMediaQuery = (mediaQuery: queryType) => {
  switch (mediaQuery) {
    case "mobile":
      return IS_MOBILE;
    case "tablet":
      return IS_TABLET;
    case "tabletOnly":
      return IS_TABLET_ONLY;
    case "notebook":
      return IS_NOTEBOOK;
    case "desktop":
      return IS_DESKTOP;
    case "desktopup":
      return IS_DESKTOP_UP;
    case "desktopLarge":
      return IS_DESKTOP_LARGE;
    default:
      return IS_TABLET;
  }
};

const useMediaQueryWrapper = (mediaQuery: queryType, options?: UseMediaQueryOptions) =>
  useMediaQuery(stringToMediaQuery(mediaQuery), options)[0];
export default useMediaQueryWrapper;

function useMediaQuery(query: string | string[], options: UseMediaQueryOptions = {}): boolean[] {
  const { ssr = true, fallback } = options;

  const queries = Array.isArray(query) ? query : [query];

  let fallbackValues = Array.isArray(fallback) ? fallback : [fallback];
  fallbackValues = fallbackValues.filter((v) => v != null) as boolean[];

  const [value, setValue] = useState(
    queries.map((query, index) => ({
      media: query,
      matches: ssr ? !!fallbackValues[index] : window.matchMedia(query).matches,
    }))
  );

  useEffect(() => {
    setValue(
      queries.map((query) => ({
        media: query,
        matches: window.matchMedia(query).matches,
      }))
    );

    const mediaQueryList = queries.map((query) => window.matchMedia(query));

    const handler = (event: MediaQueryListEvent) => {
      setValue((prev) => {
        return prev.slice().map((item) => {
          if (item.media === event.media) return { ...item, matches: event.matches };
          return item;
        });
      });
    };

    mediaQueryList.forEach((mediaQuery) => {
      mediaQuery.addEventListener("change", handler);
    });

    return () => {
      mediaQueryList.forEach((mediaQuery) => {
        mediaQuery.removeEventListener("change", handler);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value.map((item) => item.matches);
}
