import { useRef, useState } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Text from "@afa-shared/afa-components/dist/Text";
import FormErrorFeedback from "@components/AfaForms/Feedback/FormErrorFeedback";
import FormSendFeedback from "@components/AfaForms/Feedback/FormSendFeedback";
import FormSuccessFeedback from "@components/AfaForms/Feedback/FormSuccessFeedback";
import { OrderBlockType_orderBlock_mainAreaOrderCollection_items } from "@components/OrderBlock/queries/contentfulTypes/OrderBlockType";

import { FileBlockDataType } from "types/FileBlockDataType";

import OrderForm from "../AfaForms/OrderFilesForm/orderFilesForm";
import orderBlockStyles from "./orderBlock.module.css";
import OrderFiles from "./orderFiles";
import { OrderBlockType_orderBlock } from "./queries/contentfulTypes/OrderBlockType";

export interface IOrderBlockData extends OrderBlockType_orderBlock_mainAreaOrderCollection_items {
  fileData: FileBlockDataType;
}
interface IOrderBlockProps {
  blockData?: OrderBlockType_orderBlock;
  uniqueKey?: string;
}
export type OrderListType = {
  orderItem: IOrderBlockData;
  amount: number;
};

export const OrderBlock = ({ blockData: orderBlock }: IOrderBlockProps) => {
  const orderFiles = orderBlock?.mainAreaOrderCollection?.items?.filter(
    (file: IOrderBlockData) => file?.metaData?.orderAmount > 0 && file?.metaData?.orderable
  );
  const [orders, setOrders] = useState<OrderListType[]>([]);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [errorSendMail, setErrorSendMail] = useState<boolean>(false);

  const largeThumbnail = orderBlock?.thumbnailFormat;

  const feedbackRef = useRef(null);
  const orderListRef = useRef(null);

  const renderFormFeedback = () => (
    <FormSuccessFeedback
      messageHeading="Tack för din beställning"
      messageChildren={
        <Text variant="paragraph" removeMargin>
          Du kommer att få en bekräftelse via e-post så snart vi hanterat din order.
        </Text>
      }
    />
  );
  const renderErrorMessage = () => <FormErrorFeedback />;

  const showFormFeedback: boolean = displayForm && !errorSendMail;

  return (
    orderFiles?.length > 0 && (
      <Grid
        className={orderBlockStyles.orderBlock}
        data-contentful-entry-id={orderBlock.sys?.id}
        data-contentful-field-id="name"
      >
        <Grid direction={"column"}>
          {
            <FormSendFeedback
              formSendRef={feedbackRef}
              formErrorFeedback={renderErrorMessage()}
              formSuccessFeedback={renderFormFeedback()}
              showSuccessFeedback={showFormFeedback}
              showErrorFeedback={errorSendMail}
            />
          }
          {!displayForm && (
            <>
              <ul
                className={orderBlockStyles.list}
                ref={orderListRef}
                tabIndex={-1}
                data-temp-keep-index
              >
                {orderFiles?.map((item: IOrderBlockData, index: number) => (
                  <OrderFiles
                    item={item}
                    orders={orders}
                    setOrders={setOrders}
                    largeThumbnail={largeThumbnail}
                    key={index}
                    hideDownloadLinks={orderBlock.hideDownloadLinks}
                  />
                ))}
              </ul>

              <div
                className={`${orderBlockStyles.formWrapper} ${
                  orders.some((o) => o.amount !== 0) && orders.length
                    ? ""
                    : orderBlockStyles.noOrders
                }`}
              >
                <OrderForm
                  blockId={orderBlock.sys?.id}
                  orderList={orders?.filter((o) => o.amount > 0)}
                  setFormSucceded={setDisplayForm}
                  setErrorSendMail={setErrorSendMail}
                  orderListRef={orderListRef}
                  sendFeedbackRef={feedbackRef}
                />
              </div>
              <div
                className={`${orderBlockStyles.textWrapper} ${
                  orders.some((o) => o.amount !== 0) ? orderBlockStyles.hasOrders : ""
                }`}
              >
                <Text variant="span" as="paragraph" align="left">
                  Skriv hur mycket material du vill beställa i rutan &quot;Antal&quot;. Då dyker det
                  upp ett formulär där du fyller i dina kontaktuppgifter och leveransadress.
                </Text>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    )
  );
};

export default OrderBlock;
