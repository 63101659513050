import { ReactNode, RefObject } from "react";

import formStyles from "../forms.module.css";

interface IFormSendFeedback {
  formSendRef: RefObject<any>;
  formErrorFeedback: ReactNode;
  formSuccessFeedback: ReactNode;
  showErrorFeedback: boolean;
  showSuccessFeedback: boolean;
}

const FormValidationFeedback = ({
  formSendRef,
  formErrorFeedback,
  formSuccessFeedback,
  showErrorFeedback,
  showSuccessFeedback,
}: IFormSendFeedback) => {
  return (
    <div
      aria-live="polite"
      className={`${
        showSuccessFeedback || showErrorFeedback ? formStyles.formFeedbackVisible : ""
      }`}
      ref={formSendRef}
      tabIndex={-1}
      data-temp-keep-index
    >
      {showSuccessFeedback && formSuccessFeedback}
      {showErrorFeedback && formErrorFeedback}
    </div>
  );
};

export default FormValidationFeedback;
