import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import Accordion from "@afa-shared/afa-components/dist/Accordion";
import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import { getBaseUrl } from "@utils/urlResolver";

import useMediaQuery from "hooks/useMediaQuery";

import previewStyles from "./preview.module.css";

export const Preview = () => {
  const router = useRouter();
  const [isCopying, setIsCopying] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [editUrl, setEditUrl] = useState<string>(null);
  const isTablet = useMediaQuery("tablet");

  useEffect(() => {
    const slugs = router.asPath.split("/");
    const currentslug = slugs[slugs.length - 1];
    const isInsuranceHandbookPage = router.asPath.includes("/forsakringshandboken/");
    fetch(
      `/api/preview/edit?isInsuranceHandbookPage=${isInsuranceHandbookPage}&slug=${currentslug}`
    ).then(async (response) => {
      if (!response.ok) {
        throw new Error("Unable to fetch preview copy url");
      }
      const data = await response.json();
      setEditUrl(data.url);
    });
  }, [router.asPath]);

  const exitPreview = () => {
    window.location.href = `/api/preview/exit?path=${encodeURIComponent(
      getBaseUrl() + router.asPath
    )}`;
  };

  const copyToClipboard = async () => {
    try {
      setIsCopying(true);
      const response = await fetch(`/api/preview/copy?path=${encodeURIComponent(router.asPath)}`);
      if (!response.ok) {
        throw new Error("Unable to fetch preview copy url");
      }
      const data = await response.json();
      const url = `${window.origin}${data.path}`;
      await navigator.clipboard.writeText(url);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000);
    } catch {
      setCopySuccess(false);
    } finally {
      setIsCopying(false);
    }
  };

  const copyButtonText = isCopying ? "Kopierar..." : copySuccess ? "Kopierad!" : "Kopiera länk";

  const buttons = () => {
    return (
      <>
        <Grid className={previewStyles.buttonGrid} direction="row" width="auto">
          <a target={"_blank"} href={editUrl}>
            <Button label={"Redigera"} buttonType="secondary" iconName="edit" />
          </a>
          <Button
            label={copyButtonText}
            buttonType="secondary"
            onClick={copyToClipboard}
            iconName={"content_copy"}
          />
        </Grid>
        <Button
          label={"Avsluta förhandsgranskning"}
          buttonType="red"
          onClick={exitPreview}
          iconName="close"
        />
      </>
    );
  };

  return (
    <div className={previewStyles.preview}>
      <Grid container alignItems={"center"} className={previewStyles.content}>
        {isTablet ? (
          <Accordion variant={"default"}>
            <div data-header="Förhandsgranskning" data-headervariant="h3">
              <Grid className={previewStyles.mobileGrid} direction="column">
                {buttons()}
              </Grid>
            </div>
          </Accordion>
        ) : (
          <Grid
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            padding={"1rem 0"}
          >
            <Heading variant="h3" removeMargin>
              Förhandsgranskning
            </Heading>
            <Grid className={previewStyles.desktopGrid} direction="row" width="auto">
              {buttons()}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
