import { ListBlockFull } from "@components/PageList";

import { Context } from "types/context/Context";

import PageList from "../PageList";

interface ListBlockProps {
  blockData: ListBlockFull;
  uniqueKey: string;
  context?: Context;
}

export const ListBlock = ({ blockData: listBlock, uniqueKey, context }: ListBlockProps) => {
  return <PageList listBlock={listBlock} uniqueKey={uniqueKey} context={context} />;
};
export default ListBlock;
