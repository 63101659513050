import { RefObject, useEffect, useRef } from "react";

import Modal from "@afa-shared/afa-components/dist/Modal";
import ContactForm from "@components/QuizBlock/Forms/ContactForm/ContactForm";
import { setQuizModalClosed } from "@utils/globalClassHelper";
import { pixelsToRem } from "@utils/sizeHelper";

import { useEscape } from "hooks/useEscape";
import { useTab } from "hooks/useTab";

import modalStyles from "../Modal.module.css";

interface IContactModal {
  handleModalClose: () => void;
  setFormIsSubmitted: () => void;
  containerRef: RefObject<HTMLDivElement>;
  quizId: string;
  quizType: string;
}

const ContactModal = ({
  handleModalClose,
  setFormIsSubmitted,
  containerRef,
  quizId,
  quizType,
}: IContactModal) => {
  const refs = {
    modalCloseButton: useRef(null),
    formSubmitButton: useRef(null),
    hiddenTabHelperTop: useRef(null),
    hiddenTabHelperBottom: useRef(null),
  };

  useEffect(() => {
    refs?.hiddenTabHelperTop?.current?.focus();
    return () => {
      setQuizModalClosed();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEscape(containerRef, () => {
    handleModalClose();
  });

  useTab(
    () => {
      if (document?.activeElement === refs?.formSubmitButton?.current) {
        refs.hiddenTabHelperTop?.current?.focus();
      }
    },
    () => {
      if (
        document?.activeElement === refs?.modalCloseButton?.current ||
        document?.activeElement === refs?.hiddenTabHelperTop?.current
      ) {
        refs.hiddenTabHelperBottom?.current?.focus();
      }
    }
  );

  return (
    <div className={modalStyles.modalOverlay}>
      <div className={`${modalStyles.modalWrapper} ${modalStyles.narrow}`}>
        <div ref={refs.hiddenTabHelperTop} tabIndex={-1} data-temp-keep-index />
        <Modal
          modalHeading="Kontakta oss"
          modalOnClose={() => handleModalClose()}
          modalCloseButtonRef={refs.modalCloseButton}
          modalMaxHeight={pixelsToRem(813)}
          children={
            <div className={modalStyles.modalContent}>
              <ContactForm
                submitButtonRef={refs.formSubmitButton}
                setFormIsSubmitted={setFormIsSubmitted}
                closeModal={() => handleModalClose()}
                quizId={quizId}
                quizType={quizType}
              />
            </div>
          }
        />
        <div ref={refs.hiddenTabHelperBottom} tabIndex={-1} data-temp-keep-index />
      </div>
    </div>
  );
};

export default ContactModal;
