import * as ga from "../../../lib/ga";

export const footerLinksTracker = (title: string, section: string) => {
  const trackingData = {
    event: "navigation",
    event_parameters: {
      page_location: window.location.href,
      navigationstyp: "footer",
      sektion: section,
      select_navigation: title?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};
