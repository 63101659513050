import { FileListResultType_fileBlockCollection_items_relatedInfoLink_reference } from "@components/FileList/queries/contentfulTypes/FileListResultType";

//Todo: export const enum from styleguide
export enum LinkType {
  External = "open_in_new",
  Internal = "arrow_forward",
  File = "file_download",
}

export const getInternalOrExternalIcon = (
  externalReference: string,
  internalReference: FileListResultType_fileBlockCollection_items_relatedInfoLink_reference,
  globalLink: any
): LinkType => {
  //if there is an internal reference. Return LinkType.Internal
  if (globalLink?.value) {
    return LinkType.Internal;
  } else if (internalReference?.__typename === "Page" && internalReference?.slug) {
    return LinkType.Internal;
  } else if (internalReference?.__typename === "FileBlock" && internalReference?.imageVaultFile) {
    return LinkType.File;
  }
  //If there is an external reference, then check if the external reference infludes afa.se, localhost or afaforsakring.se
  else if (externalReference) {
    if (
      externalReference?.includes("mailto:") ||
      externalReference?.includes("tel:") ||
      externalReference?.includes("fax:")
    ) {
      return LinkType.Internal;
    }
    return externalReference?.includes("afa.se") ||
      externalReference?.includes("afaforsakring.se") ||
      externalReference?.includes("localhost") ||
      externalReference?.startsWith("/")
      ? LinkType.Internal
      : LinkType.External;
  }
  return LinkType.Internal;
};
