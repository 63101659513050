import { useEffect, useState } from "react";

export const useWasSeen = (ref, options, callback) => {
  const [wasSeen, setWasSeen] = useState(typeof IntersectionObserver !== "function");

  useEffect(() => {
    if (ref.current && !wasSeen) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setWasSeen(true);
          callback();
        }
      }, options);
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [wasSeen]);
};
