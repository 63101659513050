import Link from "next/link";
import { useEffect, useRef, useState } from "react";

import Heading from "@afa-shared/afa-components/dist/Heading";
import Icon from "@afa-shared/afa-components/dist/Icon";
import { sectorTracker, sectorViewTracker } from "@components/sectorGuide/sectorTracker";
import { virtualPageviewTracker } from "@containers/Layout/layoutTracker";
import { getPersonalInfoByAudienceId } from "@utils/targetGroup/getPersonalInfo";
import targetGroupOptions from "@utils/targetGroup/targetGroupOptions.json";

import { CookieConsentCategory, useCookieConsent } from "hooks/useCookieConsent";
import { useWasSeen } from "hooks/useWasSeen";
import { IPageInfo, PageInfo, usePageInfo } from "hooks/zustand/usePageInfo";
import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { PersonalInfo } from "types/PersonalInfo";
import { Context } from "types/context/Context";

import InsurancePickerForm from "../InsurancePickerForm/InsurancePickerForm";
import { insuransPickerTracker } from "./InsuransPickerTracker";
import insurancePickerStyles from "./insurancePicker.module.css";
import {
  InsurancePickerBlockType_insurancePickerBlock,
  InsurancePickerBlockType_insurancePickerBlock_linksCollection_items,
} from "./queries/contentfulTypes/InsurancePickerBlockType";

interface IInsurancePickerBlockProps {
  blockData: InsurancePickerBlockType_insurancePickerBlock;
  uniqueKey?: string;
  context: Context;
}

const InsurancePickerBlock = ({ blockData: insurancePicker }: IInsurancePickerBlockProps) => {
  const necessaryCookieConsentGiven = useCookieConsent(CookieConsentCategory.necessary);
  const selectedSector: PersonalInfo = useSectorToggler(
    (state: ISectorToggler) => state.selectedSector
  );
  const isLoading = useSectorToggler((state: ISectorToggler) => state.isLoading);
  const setSelectedSector = useSectorToggler((state: ISectorToggler) => state.setSelectedSector);
  const pageInfo: IPageInfo = usePageInfo((state: PageInfo) => state.pageInfo);
  const [insuranceLinks, setInsuranceLinks] = useState([]);
  const [currentAudienceId, setCurrentAudienceId] = useState("");
  const [wasSeen, setWasSeen] = useState(false);
  const insurancePickerRef = useRef(null);
  const [userSector, setUserSector] = useState(null);
  const [defaultRole, setDefaultRole] = useState(
    targetGroupOptions.roles.find((role) => role.roleId === "privatperson")
  );

  useWasSeen(insurancePickerRef, { threshold: 0.5 }, () => setWasSeen(true));

  useEffect(() => {
    if (!necessaryCookieConsentGiven || isLoading || !wasSeen) return;
    sectorViewTracker("målgruppsväljare_brödtext");
  }, [isLoading, necessaryCookieConsentGiven, wasSeen]);

  useEffect(() => {
    if (isLoading) return;
    updateInsuranceLinks(selectedSector?.audienceId);
    if (selectedSector?.audienceId !== currentAudienceId) {
      updateInsuranceLinks(selectedSector?.audienceId);
      setCurrentAudienceId(selectedSector?.audienceId);
    }
    setUserSector(selectedSector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedSector?.audienceId]);

  const updateInsuranceLinks = (audienceId) => {
    setInsuranceLinks(
      insurancePicker?.linksCollection?.items?.filter((a: any) =>
        a?.audienceCollection?.items?.some((f: any) => f?.audianceId === audienceId)
      )
    );
  };

  const onSectorChange = async (event) => {
    const sectorId = event?.target?.value;
    const audienceId = `${
      selectedSector?.role?.roleId ? selectedSector?.role?.roleId : defaultRole?.roleId
    }-${sectorId}`;
    await handleSubmit(audienceId);
    updateInsuranceLinks(audienceId);
  };

  const onRoleChange = async (event) => {
    const roleId = event?.target?.value;
    setDefaultRole(targetGroupOptions.roles.find((role) => role.roleId === roleId));
    const audienceId = `${roleId}-${selectedSector?.sector?.sectorId}`;
    await handleSubmit(audienceId);
    updateInsuranceLinks(audienceId);
  };

  const handleSubmit = async (audienceId) => {
    await fetch("/api/personalization/personalization", {
      method: "POST",
      headers: { contentType: "application/json" },
      body: JSON.stringify({ audienceId }),
    });
    const newPersonalInfo = getPersonalInfoByAudienceId(audienceId);
    sectorTracker(
      newPersonalInfo?.sector?.defaultName,
      newPersonalInfo?.role?.defaultName,
      "bekräfta",
      "målgruppsväljare_brödtext"
    );
    setSelectedSector(newPersonalInfo);

    //Målgruppen har ändrats. Skicka ett event om detta.
    const targetGroupChangedEvent = new CustomEvent("targetGroupChanged", {
      detail: audienceId,
    } as CustomEventInit);
    document.dispatchEvent(targetGroupChangedEvent);

    virtualPageviewTracker(
      newPersonalInfo?.role,
      newPersonalInfo?.sector,
      pageInfo?.firstLevelPageName,
      pageInfo?.secondLevelPageName,
      pageInfo?.pageRefferer,
      pageInfo?.pageName,
      pageInfo?.insuranceName,
      pageInfo?.isCoveredByInsurance
    );
  };

  return (
    <div className={insurancePickerStyles.insurancePicker} ref={insurancePickerRef}>
      <div className={insurancePickerStyles.picker}>
        <InsurancePickerForm
          onRoleChange={onRoleChange}
          onSectorChange={onSectorChange}
          userSector={userSector}
          defaultRole={defaultRole}
          blockData={insurancePicker}
        />
        {insuranceLinks?.length > 0 && (
          <div className={insurancePickerStyles.listResult}>
            {insurancePicker.insurancesHeading && (
              <Heading
                variant="h2"
                as="h4"
                removeMargin
                children={insurancePicker.insurancesHeading}
              />
            )}
            <ul className={insurancePickerStyles.list}>
              {insuranceLinks?.map(
                (
                  item: InsurancePickerBlockType_insurancePickerBlock_linksCollection_items,
                  i: number
                ) => (
                  <li key={i}>
                    <div className={insurancePickerStyles.linkContainer}>
                      <Link
                        href={item?.url}
                        className={insurancePickerStyles.link}
                        onClick={() => insuransPickerTracker(item?.linkText, "gå vidare")}
                      >
                        <span className={insurancePickerStyles.label}>
                          <span className={insurancePickerStyles.linkText}>{item?.linkText}</span>
                          <span className={insurancePickerStyles.labelText}>
                            {item?.linkDescription}
                          </span>
                        </span>
                        <Icon
                          size={"sm"}
                          name={"chevron_right"}
                          className={insurancePickerStyles.iconChevron}
                        />
                      </Link>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default InsurancePickerBlock;
