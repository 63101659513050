import * as ga from "../../lib/ga";

export const contactTracker = (channel: string) => {
  const trackingData = {
    event: "kontakt",
    event_parameters: {
      page_location: window.location.href,
      kanal: channel,
    },
  };
  ga.gaTracking(trackingData);
};
