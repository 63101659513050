import Message from "@afa-shared/afa-components/dist/Message";
import Text from "@afa-shared/afa-components/dist/Text";
import { getErrorAmount } from "@utils/getAmount";

import { scrollIntoView } from "../formHelper";
import formStyles from "../forms.module.css";

interface IFormValidationFeedback {
  formErrors: any;
  formRefs: any;
  hasSingleError: boolean;
}

const FormValidationFeedback = ({
  formErrors,
  formRefs,
  hasSingleError,
}: IFormValidationFeedback) => {
  const getRef = (type: string) => formRefs[type];
  const numErrors = getErrorAmount(
    Object.keys(formErrors).filter((key) => !key?.includes("Input") && formErrors[key]).length
  );

  return (
    <div
      ref={formRefs?.errorInfobox}
      className={`${
        Object.keys(formErrors).some((key) => formErrors[key]) ? formStyles.formFeedbackVisible : ""
      }`}
      aria-live="polite"
      tabIndex={-1}
      data-temp-keep-index
    >
      {Object.keys(formErrors).some((key) => formErrors[key]) && !hasSingleError && (
        <Message
          heading={`Det finns ${numErrors} fel i formuläret`}
          headingVariant="h3"
          type={"error"}
          children={
            <>
              <Text variant="paragraph">
                Informationen kan inte skickas, korrigera i följande fält:
              </Text>
              <ul>
                {Object.keys(formErrors).map(
                  (key) =>
                    formErrors[key] && (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                      <li
                        key={key}
                        onClick={() => {
                          scrollIntoView(getRef(key), key === "amount" ? "start" : "center");
                        }}
                        className={formStyles.errorListItem}
                      >
                        <button className={formStyles.errorLink} type="button">
                          {formErrors[key]?.linkText}
                        </button>
                        {` ${formErrors[key]?.boxDescription}`}
                      </li>
                    )
                )}
              </ul>
            </>
          }
        />
      )}
    </div>
  );
};

export default FormValidationFeedback;
