import * as ga from "../../lib/ga";

export const formTracker = (status: string, name: string) => {
  const trackingData = {
    event: "formulär",
    event_parameters: {
      page_location: window.location.href,
      status: status?.toLowerCase(),
      formulär_tema: name?.toLowerCase(),
    },
  };

  ga.gaTracking(trackingData);
};

export const contactFormTracker = (status: string, errand: string) => {
  const trackingData = {
    event: "formulär",
    event_parameters: {
      page_location: window.location.href,
      status: status?.toLowerCase(),
      formulär_tema: "kontakt",
      ärende: errand?.toLowerCase(),
    },
  };

  ga.gaTracking(trackingData);
};

export const complaintsFormTracker = (status: string, errand: string) => {
  const trackingData = {
    event: "formulär",
    event_parameters: {
      page_location: window.location.href,
      status: status?.toLowerCase(),
      formulär_tema: "klagomål",
      ärende: errand?.toLowerCase(),
    },
  };

  ga.gaTracking(trackingData);
};

export const newsletterTracker = (status: string, name: string) => {
  const trackingData = {
    event: "nyhetsbrev",
    event_parameters: {
      page_location: window.location.href,
      status: status?.toLowerCase(),
      nyhetsbrev_tema: name?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};
