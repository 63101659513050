import { RefObject, useEffect, useRef } from "react";

import Heading from "@afa-shared/afa-components/dist/Heading";
import Message from "@afa-shared/afa-components/dist/Message";
import Modal from "@afa-shared/afa-components/dist/Modal";
import Text from "@afa-shared/afa-components/dist/Text";
import { QuizBlockType_quizBlock_questionAreaCollection_items } from "@components/QuizBlock/queries/contentfulTypes/QuizBlockType";
import { setQuizModalClosed } from "@utils/globalClassHelper";

import { useEscape } from "hooks/useEscape";
import { useTab } from "hooks/useTab";

import modalStyles from "../Modal.module.css";

interface IQuizConclusionModal {
  questions: Array<QuizBlockType_quizBlock_questionAreaCollection_items>;
  userQuizAnswers: Array<string>;
  userCorrectAnswers: number;
  conclusions: Array<string>;
  handleModalClose: () => void;
  containerRef: RefObject<HTMLDivElement>;
}

const QuizConclusionModal = ({
  questions,
  userQuizAnswers,
  userCorrectAnswers,
  conclusions,
  handleModalClose,
  containerRef,
}: IQuizConclusionModal) => {
  const refs = {
    hiddenTabHelperTop: useRef(null),
    hiddenTabHelperBottom: useRef(null),
    modalCloseButtonRef: useRef(null),
  };

  useEffect(() => {
    refs?.hiddenTabHelperTop?.current?.focus();
    return () => {
      setQuizModalClosed();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEscape(containerRef, () => {
    handleModalClose();
  });

  useTab(
    () => {
      refs.hiddenTabHelperTop?.current?.focus();
    },
    () => {
      refs.hiddenTabHelperBottom?.current?.focus();
    }
  );

  return (
    <div className={modalStyles.modalOverlay}>
      <div className={modalStyles.modalWrapper}>
        <div ref={refs.hiddenTabHelperTop} tabIndex={-1} data-temp-keep-index />
        <Modal
          modalHeading="Mina svar"
          modalOnClose={() => handleModalClose()}
          children={
            <div className={modalStyles.modalContent}>
              <Heading
                variant="h3"
                children={`Ditt resultat: ${userCorrectAnswers} av ${questions?.length}`}
                removeMargin
              />
              <ol className={modalStyles.orderedList}>
                {questions?.map((question, index) => {
                  const isCorrectAnswer = userQuizAnswers[index] === conclusions[index];
                  return (
                    <li className={modalStyles.listItem} key={index}>
                      <Heading variant="h4" children={`${index + 1}. ${question?.question}`} />
                      <div className={modalStyles.messageWrapper}>
                        <Message
                          heading={`${isCorrectAnswer ? "Rätt" : "Fel"} - Du svarade: ${
                            userQuizAnswers[index]
                          }`}
                          headingVariant={"h3"}
                          type={isCorrectAnswer ? "success" : "error"}
                          children={
                            <Text
                              variant="span"
                              children={
                                isCorrectAnswer
                                  ? question?.feedbackCorrectAnswer
                                  : question?.feedbackWrongAnswer
                              }
                            />
                          }
                        />
                      </div>
                    </li>
                  );
                })}
              </ol>
            </div>
          }
        />
        <div ref={refs.hiddenTabHelperBottom} tabIndex={-1} data-temp-keep-index />
      </div>
    </div>
  );
};

export default QuizConclusionModal;
