import Link from "next/link";
import { useRef, useState } from "react";

import Icon from "@afa-shared/afa-components/dist/Icon";
import ImageModal from "@components/ImageBlock/ImageModal/ImageModal";
import IvImageBlockImage from "@components/Medias/Image/IvImageBlockImage";
import PhotoInfo from "@components/PhotoInfo/PhotoInfo";
import ShareImage from "@components/ShareImage";
import { BlockSize } from "@components/cFContentArea";
import { setImageModalClosed, setImageModalOpen } from "@utils/globalClassHelper";
import { getImageBlockImage } from "@utils/imageHelpers/imageBlockImageHelper";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

import { useExternalTracking } from "hooks/useExternalTracking";
import { Context } from "types/context/Context";

import imageBlockStyles from "./imageBlock.module.css";
import { ImageBlockType_imageBlock } from "./queries/contentfulTypes/ImageBlockType";

interface IImageProps {
  blockData?: ImageBlockType_imageBlock;
  uniqueKey?: string;
  context?: Context;
  className?: string;
  maxWidth?: number;
}

const ImageBlock = ({ blockData: imageBlock, context, className, maxWidth }: IImageProps) => {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);
  const url = imageBlock?.link?.url;

  const whereToOpenLink = imageBlock?.link?.newTab === true ? "_blank" : "_self";

  const imageData = imageBlock?.ivImage?.[0];
  const squareImageData = imageBlock?.squareImage?.[0];
  const image =
    typeof window !== "undefined"
      ? window?.innerWidth > 768
        ? imageData?.mediaUrl
        : getImageBlockImage(imageData, window.innerWidth)?.url
      : getImageBlockImage(imageData, 1240)?.url;

  const metadata = imageData?.Metadata;

  const handleModalToggle = () => {
    setScrollPosition(window?.scrollY);
    !openImageModal ? setImageModalOpen() : setImageModalClosed();
    setOpenImageModal(!openImageModal);
    if (openImageModal) {
      containerRef?.current?.focus();
      window?.scrollTo(0, scrollPosition);
    }
  };

  const thisLinkRef = useRef(null);

  useExternalTracking({ url: url, ref: thisLinkRef });

  return (
    <>
      {imageBlock?.shareImage && imageBlock?.squareImage ? (
        <ShareImage
          imageData={squareImageData}
          shareHeading={imageBlock?.shareHeading}
          shareRichtext={imageBlock?.shareRichtext}
          shareLinkText={imageBlock?.shareLinkText}
          context={context}
          maxWidth={maxWidth}
          contentfulPreviewEntryId={imageBlock.sys?.id}
        />
      ) : (
        image && (
          <figure
            className={`${imageBlockStyles.imageBlock} ${className} ${
              context?.sizes?.desktop === BlockSize.Full ||
              context?.sizes?.notebook === BlockSize.Full
                ? "full-width"
                : ""
            }`}
            data-blockname="bildblock"
            data-contentful-entry-id={imageBlock.sys?.id}
            data-contentful-field-id="ivImage"
          >
            <div className={imageBlockStyles.imageWrapper}>
              {url ? (
                <Link href={url} prefetch={false} legacyBehavior={true}>
                  <a
                    href={url}
                    className={imageBlockStyles.link}
                    target={whereToOpenLink}
                    ref={thisLinkRef}
                  >
                    <IvImageBlockImage image={imageData} />
                  </a>
                </Link>
              ) : (
                <IvImageBlockImage image={imageData} />
              )}
            </div>
            {(imageBlock?.imageDescription || metadata?.photographer) && (
              <figcaption className={imageBlockStyles.figCaption}>
                <PhotoInfo
                  description={imageBlock?.imageDescription}
                  photographer={metadata?.photographer}
                  contentfulPreviewEntryId={imageBlock.sys?.id}
                  contentfulPreviewImageTextFieldId="imageDescription"
                />
                {imageBlock?.enableZoom && (
                  <button
                    className={imageBlockStyles.button}
                    onClick={() => handleModalToggle()}
                    ref={containerRef}
                    data-contentful-entry-id={imageBlock.sys?.id}
                    data-contentful-field-id="enableZoom"
                  >
                    <Icon
                      name="open_in_full"
                      viewBox="0 0 20 20"
                      width="18"
                      height="18"
                      referenceSize="span"
                      className={imageBlockStyles.icon}
                    />
                    <span>Förstora bild</span>
                  </button>
                )}
              </figcaption>
            )}
            {openImageModal && (
              <ImageModal
                imageUrl={replaceImageVaultBasePath(image)}
                imageAltText={metadata?.altText}
                containerRef={containerRef}
                handleModalClose={handleModalToggle}
              />
            )}
          </figure>
        )
      )}
    </>
  );
};
export default ImageBlock;
