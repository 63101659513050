import TrackedLink from "@components/TrackedLink";
import { getInternalOrExternalIcon } from "@utils/linkIconHelper";

import { LinkBlockType_link } from "./queries/contentfulTypes/LinkBlockType";

interface ILinkProps {
  blockData?: LinkBlockType_link;
  uniqueKey?: string;
}

const index = ({ blockData: linkBlock }: ILinkProps) => {
  const url = linkBlock?.url;
  const externalReference = linkBlock?.url;
  const globalLink = linkBlock?.globalLinkItem;
  const internalReference = linkBlock?.reference;
  const linkVariant = linkBlock?.variant?.toLowerCase() === "knapp" ? "button" : "link";
  const linkType = getInternalOrExternalIcon(externalReference, internalReference, globalLink);
  const target =
    externalReference?.includes("mailto:") || externalReference?.includes("tel:")
      ? "_self"
      : linkBlock?.newTab
      ? "_blank"
      : "_self";

  return (
    <TrackedLink
      linkType={linkType}
      linkText={linkBlock?.linkText}
      url={url}
      linkVariant={linkVariant}
      target={target}
      dataContentfulEntryId={linkBlock.sys?.id}
      dataContentfulFieldId="name"
    />
  );
};
export default index;
