import FileList, { FileListBlockResult } from "@components/FileList";

import { Context } from "types/context/Context";

interface ListBlockProps {
  blockData?: FileListBlockResult;
  uniqueKey?: string;
  context?: Context;
}

export const FileListBlock = ({ blockData: fileListBlock, uniqueKey, context }: ListBlockProps) => {
  return <FileList fileListBlock={fileListBlock} uniqueKey={uniqueKey} context={context} />;
};
export default FileListBlock;
