export const setSectorGuideOpen = () => {
  document.body.classList.add("sector-picker-open");
};

export const setSectorGuideClosed = () => {
  document.body.classList.remove("sector-picker-open");
};

export const setQuizModalOpen = () => {
  document.body.classList.add("quiz-modal-open");
};

export const setQuizModalClosed = () => {
  document.body.classList.remove("quiz-modal-open");
};

export const setImageModalOpen = () => {
  document.body.classList.add("image-modal-open");
};

export const setImageModalClosed = () => {
  document.body.classList.remove("image-modal-open");
};

export const setShareImageModalOpen = () => {
  document.body.classList.add("share-image-modal-open");
};

export const setShareImageModalClosed = () => {
  document.body.classList.remove("share-image-modal-open");
};
