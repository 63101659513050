import { ReactNode } from "react";

import Message from "@afa-shared/afa-components/dist/Message";
import Text from "@afa-shared/afa-components/dist/Text";

interface IFormErrorFeedback {
  messageHeading?: string;
  messageChildren?: ReactNode;
}

const FormErrorFeedback = ({ messageHeading, messageChildren }: IFormErrorFeedback) => {
  const heading = messageHeading ? messageHeading : "Ett fel har inträffat";
  const children = messageChildren ? (
    messageChildren
  ) : (
    <Text
      variant="paragraph"
      children={
        "Vi kunde inte skicka iväg ditt meddelande på grund av att ett tekniskt fel har inträffat. Var vänlig försök igen senare."
      }
    />
  );
  return <Message heading={heading} headingVariant={"h2"} type={"error"} children={children} />;
};

export default FormErrorFeedback;
