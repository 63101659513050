import * as ga from "../../../lib/ga";

export const TopbarNavlinksTracker = (section: string, item: string) => {
  const trackingData = {
    event: "navigation",
    event_parameters: {
      page_location: window.location.href,
      navigationstyp: "headermeny",
      sektion: section?.toLowerCase(),
      select_navigation: item?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};

export const breadcumbsLinksTracker = (breadcrumb: string, section: string) => {
  const trackingData = {
    event: "navigation",
    event_parameters: {
      page_location: window.location.href,
      navigationstyp: "breadcrumbs",
      sektion: section?.toLowerCase(),
      select_navigation: breadcrumb?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};
