import { IRole, ISector } from "types/PersonalInfo";

import * as ga from "../../lib/ga";

export const layoutScrollTracker = (scrollLevel: number) => {
  const trackingData = {
    event: "scroll",
    event_parameters: {
      page_location: window.location.href,
      scroll_level: scrollLevel,
    },
  };
  ga.gaTracking(trackingData);
};

export const externalLinksTracker = (
  domain: string,
  url: string,
  context: string,
  type: string,
  text: string
) => {
  const trackingData = {
    event: "click",
    event_parameters: {
      page_location: window.location.href,
      link_domain: domain?.toLowerCase(),
      link_url: url?.toLowerCase(),
      link_type: type,
      link_text: text,
      feature_block_namn: context?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};

export const virtualPageviewTracker = (
  role: IRole,
  sector: ISector,
  headCategory: string,
  subCategory: string,
  pageReferrer: string,
  pageTitle: string,
  insuranceName: string,
  isCoveredByInsurance: string
) => {
  const trackingData = {
    event: "virtual_pageview",
    user_properties: {
      roll: role ? role?.defaultName?.toLowerCase() : "",
      sektor: sector ? sector?.defaultName?.toLowerCase() : "",
    },
    event_parameters: {
      sidtyp_huvudkategori: headCategory,
      sidtyp_subkategori: subCategory,
      page_referrer: pageReferrer || "",
      page_location: window.location.href,
      page_title: pageTitle,
      insurance_selector: insuranceName,
      page_personalize: isCoveredByInsurance,
    },
  };
  ga.gaTracking(trackingData);
};
