export type lineHeight =
  | "xs"
  | "sm"
  | "base"
  | "m"
  | "lg"
  | "xl"
  | "xxl"
  | "xxxl"
  | "xxxxl"
  | "xxxxxl";

export type tag =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "ingress"
  | "p"
  | "span"
  | "smallParagraph"
  | "quote"
  | "link"
  | "label";

type device = "mobile" | "tablet" | "desktop";

const lineHeights = {
  xs: 16,
  sm: 20,
  base: 22,
  m: 26,
  lg: 28,
  xl: 32,
  xxl: 36,
  xxxl: 48,
  xxxxl: 48,
  xxxxxl: 64,
};

const tags = {
  h1: [lineHeights.xxl, lineHeights.xxxl, lineHeights.xxxxxl],
  h2: [lineHeights.xl, lineHeights.xxl, lineHeights.xxxl],
  h3: [lineHeights.lg, lineHeights.xl, lineHeights.xxl],
  h4: [lineHeights.m, lineHeights.lg, lineHeights.xl],
  h5: [lineHeights.m, lineHeights.lg, lineHeights.xl],
  h6: [lineHeights.m, lineHeights.lg, lineHeights.xl],
  ingress: [lineHeights.lg, lineHeights.xl, lineHeights.xxl],
  p: [lineHeights.m, lineHeights.m, lineHeights.lg],
  span: [lineHeights.m, lineHeights.m, lineHeights.lg],
  smallParagraph: [lineHeights.base, lineHeights.base, lineHeights.base],
  quote: [lineHeights.xl, lineHeights.xl, lineHeights.xxxl],
  link: [lineHeights.m, lineHeights.m, lineHeights.lg],
  label: [lineHeights.m, lineHeights.m, lineHeights.lg],
};

const deviceIndex = {
  mobile: 0,
  tablet: 1,
  desktop: 2,
};

export const calculateOffset = (
  referenceSize: lineHeight | tag,
  device: device,
  iconHeight: number
) => {
  return (getLineHeight(referenceSize, device) - iconHeight) * 0.5;
};

export const getLineHeight = (referenceSize: lineHeight | tag, device: device) => {
  if (referenceSize in lineHeights) {
    return lineHeights[referenceSize as lineHeight];
  } else if (referenceSize in tags) {
    return tags[referenceSize as tag][deviceIndex[device]];
  } else {
    return 0;
  }
};

export const getLineHeightForDevice = (referenceSize: lineHeight | tag, device: number) => {
  if (referenceSize in lineHeights) {
    return lineHeights[referenceSize as lineHeight];
  } else if (referenceSize in tags) {
    return tags[referenceSize as tag][device];
  } else {
    return 0;
  }
};

export const getIconOffsets = (
  referenceSizes: Array<lineHeight | tag | undefined>,
  iconHeights: Array<number>
) => {
  const offsetArray: Array<string> = [];
  referenceSizes.map((size, index) => {
    if (typeof size === "undefined") {
      offsetArray.push("0");
    } else {
      const lineHeight = getLineHeightForDevice(size, index);
      const offset = ((lineHeight - iconHeights[index]) * 0.5) / 16;
      offsetArray.push(`${offset}rem`);
    }
  });
  return offsetArray;
};
