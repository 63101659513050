import Link from "next/link";

import DocumentType from "@afa-shared/afa-components/dist/DocumentType";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Icon from "@afa-shared/afa-components/dist/Icon";
import Text from "@afa-shared/afa-components/dist/Text";
import { FileListBlockType_fileListBlock } from "@components/FileListBlock/queries/contentfulTypes/FileListBlockType";
import IvFileListImage from "@components/Medias/Image/ivFileListImage";
import { LinkType, getInternalOrExternalIcon } from "@utils/linkIconHelper";
import { isNullOrEmpty } from "@utils/string";

import { FileBlockDataType } from "types/FileBlockDataType";
import { Context } from "types/context/Context";

import fileListCardStyles from "./fileListCard.module.css";
import { fileDownloadTracker } from "./fileTracker";
import { FileListResultType_fileBlockCollection_items } from "./queries/contentfulTypes/FileListResultType";

interface IFileInListData extends FileListResultType_fileBlockCollection_items {
  fileData: FileBlockDataType;
}

interface IFilesInList {
  item: IFileInListData;
  fileListBlock: FileListBlockType_fileListBlock;
  index: number;
  context: Context;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const FilesInList = ({ item, fileListBlock, index, context }: IFilesInList) => {
  const largeThumbnail = fileListBlock?.thumbnailSize === "Stor bild" ? true : false;
  const showImage =
    fileListBlock?.thumbnailSize === "Ingen bild" || isNullOrEmpty(fileListBlock?.thumbnailSize)
      ? false
      : true;
  const headingType = fileListBlock?.headingType;
  const showRelatedInfo = fileListBlock?.showRelatedInfo;
  const teaserText = fileListBlock?.teaserText && item?.metaData?.teaserText;
  const hasImage = item?.fileData?.hasImage;

  const fNumber = item?.metaData?.fNumber?.toUpperCase();
  const monthCategory =
    fileListBlock?.showMonth &&
    item?.metaData?.Categories?.filter(
      (category: any) => category?.parent === "Månad eller kvartal"
    )[0]?.name;
  const yearCategory =
    fileListBlock?.showYear &&
    item?.metaData?.Categories?.filter((category: any) => category?.parent === "Årtal")[0]?.name;

  const heading =
    headingType === "Rubrik på webb"
      ? item?.metaData?.headingWeb
      : item?.metaData?.headingFileListWeb;

  const fileSize = item?.fileData?.fileSize;
  const fileExtension = item?.metaData?.fileExtension;
  const relatedInfoText = item?.relatedInfoLink?.linkText;
  const relatedInfoLink = item?.relatedInfoLink?.url;

  const relatedInfoLinkType = getInternalOrExternalIcon(
    item?.relatedInfoLink?.url,
    item?.relatedInfoLink?.reference,
    item?.relatedInfoLink?.globalLinkItem
  );
  const relatedInfoIsInternal = relatedInfoLinkType === LinkType.Internal;
  const relatedInfoTarget = "_self";
  const relatedInfoIconAriaLabel = relatedInfoIsInternal ? "" : `Extern länk.`;

  const handleClick = (event, url, fileExtension, name): void => {
    fileDownloadTracker(name, fileExtension, context?.insuranceName || "");
  };

  return (
    item?.fileData &&
    item?.fileData?.fileUrl && (
      <article className={fileListCardStyles.fileListCard} key={index} data-blockname="filblock">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={`${fileListCardStyles.wrapper} ${
            largeThumbnail ? fileListCardStyles.largeThumbnail : ""
          }`}
          onClick={(event) => handleClick(event, item?.fileData?.fileUrl, fileExtension, heading)}
        >
          {hasImage && showImage && (
            <IvFileListImage fileData={item?.fileData} isLargeImage={largeThumbnail} />
          )}
          <Grid
            direction="column"
            justifyContent="space-between"
            wrap="nowrap"
            className={fileListCardStyles.content}
          >
            <div>
              <Link
                href={item?.fileData?.fileUrl}
                prefetch={false}
                className={fileListCardStyles.link}
                target={"_self"}
                rel="noopener"
                aria-label={`${heading} (${fileExtension}, ${fileSize})`}
              >
                <DocumentType
                  type={fileExtension}
                  referenceSize={"h3"}
                  className={fileListCardStyles.documentType}
                />
                <Heading
                  variant={context?.heading?.childLevel}
                  as="h3"
                  className={fileListCardStyles.heading}
                >
                  {heading}
                </Heading>
              </Link>
              {teaserText && (
                <Text className={fileListCardStyles.paragraph} variant="paragraph">
                  {teaserText}
                </Text>
              )}
              {(yearCategory || monthCategory) && (
                <span className={fileListCardStyles.fileInfo}>
                  Publicerad: {monthCategory} {yearCategory}
                </span>
              )}
              {fNumber && <span className={fileListCardStyles.fileInfo}>Filnummer: {fNumber}</span>}
            </div>
            <div className={fileListCardStyles.downloadSection}>
              <Icon name="file_download" size="sm" referenceSize="span" />
              <span>{`Ladda ner ${fileSize ? "(" + fileSize + ")" : ""}`}</span>
            </div>
          </Grid>
        </div>
        {showRelatedInfo && relatedInfoLink && relatedInfoText && (
          <Link
            href={relatedInfoLink}
            prefetch={false}
            target={relatedInfoTarget}
            className={`${fileListCardStyles.relatedInfoLink} ${
              relatedInfoIsInternal ? fileListCardStyles.internalLink : ""
            } ${hasImage && showImage ? fileListCardStyles.useMargin : ""} ${
              largeThumbnail ? fileListCardStyles.largeThumbnail : ""
            }`}
          >
            <Icon
              name={relatedInfoLinkType}
              size="sm"
              referenceSize="span"
              aria-label={relatedInfoIconAriaLabel}
            />
            <span>{relatedInfoText}</span>
          </Link>
        )}
      </article>
    )
  );
};
export default FilesInList;
