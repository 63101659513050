import Link from "next/link";
import React, { CSSProperties, useRef } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import ListCard from "@afa-shared/afa-components/dist/ListCard";
import Message from "@afa-shared/afa-components/dist/Message";
import LinkedHeading from "@components/LinkedHeading/LinkedHeading";
import { ListBlockType_listBlock } from "@components/ListBlock/queries/contentfulTypes/ListBlockType";
import IvTeaserImage from "@components/Medias/Image/IvTeaserImage";
import PaginationBlock from "@components/Pagination";
import { PressreleaseListBlockType_pressreleaseListBlock } from "@components/PressreleaseListBlock/queries/contentfulTypes/PressreleaseListBlockType";
import RichTextRenderer from "@components/RichTextRenderer";
import TrackedLink from "@components/TrackedLink";
import { BlockSize } from "@components/cFContentArea";
import { LinkType } from "@utils/linkIconHelper";

import { PaginationContainer } from "types/ListItem";
import { Context } from "types/context/Context";
import { HeadingContext } from "types/context/HeadingContext";

import pageListStyles from "./pageList.module.css";

export interface ListBlockFull extends ListBlockType_listBlock {
  listresult: PaginationContainer;
}
export interface PressreleaseListBlockFull extends PressreleaseListBlockType_pressreleaseListBlock {
  listresult: PaginationContainer;
}
interface PageListProps {
  listBlock: ListBlockFull | PressreleaseListBlockFull;
  uniqueKey?: string;
  context?: Context;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const PageList = ({ listBlock, uniqueKey, context }: PageListProps) => {
  const listRef = useRef(null);
  const listItems = listBlock?.listresult;
  const resultItems = listItems?.listResult;
  const totalItems = listItems?.totalItems;
  const columnSize = resultItems?.length > 2 ? 4 : resultItems?.length === 2 ? 6 : 12;
  const columnWidth = 100 / (12 / columnSize);
  const linkOnPageList = listBlock?.linkOnList;
  const usePagination =
    listBlock?.pagination && resultItems && totalItems > 0 && totalItems > listBlock?.numberOfItems;

  const url = linkOnPageList?.url;
  const hiddenForPagetype = (type: string) => {
    return type === "Landingpage" || type === "Standardpage" || type === "Insurancepage";
  };
  const getNewsType = (type: string): any => {
    switch (type) {
      case "Eventpage":
        return "event";
      case "pressrelease":
        return "pressrelease";
      default:
        return "default";
    }
  };

  const getLinkText = (item) => {
    return (
      item?.type !== "Newspage" &&
      item?.type !== "Eventpage" &&
      item?.type !== "Reportpage" &&
      item?.linkText
    );
  };

  const getDateAndTimeArray = (item) => {
    const dateAndTimeArray = item?.dateAndTimeArray;
    if (getNewsType(item?.type) === "event" && !item?.isOngoingEvent) {
      return ["Genomfördes " + dateAndTimeArray[0]];
    }
    return dateAndTimeArray;
  };

  const headingContext: HeadingContext = {
    parentLevel: context?.heading?.parentLevel,
    childLevel: listBlock?.heading ? context?.heading?.childLevel : context?.heading?.parentLevel,
  };
  context = { ...context, heading: headingContext };

  const listText = () => (
    <>
      {listBlock?.heading && (
        <div className={pageListStyles.headingWrapper}>
          {linkOnPageList && listBlock?.linkedHeading && resultItems ? (
            <LinkedHeading
              url={url}
              headingLevel={context?.heading?.parentLevel}
              heading={listBlock?.heading}
            />
          ) : (
            <Heading
              variant={context?.heading?.parentLevel}
              removeMargin
              children={listBlock?.heading}
            />
          )}
        </div>
      )}
      {listBlock?.text && (
        <RichTextRenderer json={listBlock?.text?.json} links={listBlock?.text?.links} />
      )}
    </>
  );

  const listStyles: CSSProperties = {
    "--column-width": `${columnWidth}%`,
  } as CSSProperties;

  return resultItems?.length > 0 ? (
    <div
      className={`${pageListStyles.pageList} ${
        context?.sizes?.desktop === BlockSize.Full || context?.sizes?.notebook === BlockSize.Full
          ? "full-width"
          : ""
      }`}
      style={listStyles}
      data-contentful-entry-id={listBlock.sys?.id}
      data-contentful-field-id="name"
    >
      {listBlock?.heading && listBlock?.text ? (
        <div className={pageListStyles.textWrapper}>{listText()}</div>
      ) : (
        listText()
      )}
      {listBlock?.listStyle === "Lista" ? (
        <ul
          className={`${pageListStyles.list} ${pageListStyles.column} ${
            context?.sectionBlock?.coloredSection ? "" : pageListStyles.border
          }`}
          ref={listRef}
          tabIndex={-1}
          data-temp-keep-index
        >
          {resultItems?.map((item, index) => {
            const url = item?.url;
            return (
              <li className={pageListStyles.listItem} key={index}>
                <Link href={url || ""} prefetch={false} className={pageListStyles.columnLink}>
                  <ListCard
                    nextImage={
                      <IvTeaserImage
                        image={item?.ivTeaserImage?.[0]}
                        pressImageUrl={item?.imageUrl}
                        pressImageAltText={item?.imageAltText}
                      />
                    }
                    key={`card-${uniqueKey}`}
                    direction={"row"}
                    heading={item?.heading}
                    headingVariant={context?.heading?.childLevel}
                    text={item?.text}
                    categories={item?.categories}
                    variant={getNewsType(item?.type)}
                    dateSignDay={item?.dateSignDay}
                    dateSignMonth={item?.dateSignMonth}
                    isOngoingEvent={item?.isOngoingEvent}
                    dateAndTimeArray={getDateAndTimeArray(item)}
                    hideDate={item?.hideDate || hiddenForPagetype(item?.type)}
                    linkText={getLinkText(item)}
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      ) : (
        <ul
          className={`${pageListStyles.list} ${pageListStyles.row} ${
            context?.sectionBlock?.coloredSection ? "" : pageListStyles.border
          }`}
          ref={listRef}
          tabIndex={-1}
          data-temp-keep-index
        >
          {resultItems?.map((item, index) => {
            return (
              <li className={pageListStyles.listItem} key={index}>
                <Link href={item?.url || ""} prefetch={false} className={pageListStyles.rowLink}>
                  <ListCard
                    nextImage={
                      <IvTeaserImage
                        image={item?.ivTeaserImage?.[0]}
                        pressImageUrl={item?.imageUrl}
                        pressImageAltText={item?.imageAltText}
                      />
                    }
                    key={`card-${uniqueKey}`}
                    direction={"column"}
                    heading={item?.heading}
                    headingVariant={context?.heading?.childLevel}
                    text={item?.text}
                    categories={item?.categories}
                    variant={getNewsType(item?.type)}
                    dateSignDay={item?.dateSignDay}
                    dateSignMonth={item?.dateSignMonth}
                    isOngoingEvent={item?.isOngoingEvent}
                    dateAndTimeArray={getDateAndTimeArray(item)}
                    hideDate={item?.hideDate || hiddenForPagetype(item?.type)}
                    linkText={getLinkText(item)}
                    data-contentful-entry-id={item.id}
                    data-contentful-field-id="name"
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      )}
      {usePagination && (
        <PaginationBlock
          totalItems={totalItems}
          navbarLabel={"Paginering för sidlistning"}
          itemsPerPage={listBlock?.numberOfItems}
          listRef={listRef}
        />
      )}
      {linkOnPageList && !listBlock?.linkedHeading && resultItems && (
        <Grid direction="row" justifyContent="center">
          <TrackedLink
            linkType={LinkType.Internal}
            linkText={linkOnPageList?.linkText}
            url={url}
            linkVariant="button"
            buttonStyle="secondary"
          />
        </Grid>
      )}
    </div>
  ) : (
    <div className={`${pageListStyles.fallbackWrapper}`}>
      {listBlock?.heading && listBlock?.text ? (
        <div className={pageListStyles.textWrapper}>{listText()}</div>
      ) : (
        listText()
      )}
      <div
        className={`${pageListStyles.errorWrapper} ${
          context?.sectionBlock?.coloredSection ? "" : pageListStyles.border
        }`}
      >
        <Message
          type="info"
          heading={
            (listBlock as ListBlockFull).contentTypes &&
            (listBlock as ListBlockFull)?.contentTypes?.length === 1 &&
            (listBlock as ListBlockFull)?.contentTypes[0]?.toLowerCase() === "evenemang"
              ? "Just nu har vi inga inplanerade evenemang. Återkom gärna vid ett senare tillfälle."
              : "Just nu finns det inga aktuella uppdateringar."
          }
          headingVariant={context?.heading?.childLevel}
        />
      </div>
    </div>
  );
};
export default PageList;
