export const getErrorAmount = (amount: number) => {
  switch (amount) {
    case 1:
      return "ett";
    case 2:
      return "två";
    case 3:
      return "tre";
    case 4:
      return "fyra";
    case 5:
      return "fem";
    case 6:
      return "sex";
    case 7:
      return "sju";
    case 8:
      return "åtta";
    default:
      return "inga";
  }
};
