import { Dispatch, SetStateAction, useEffect, useId, useState } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import TextInput from "@afa-shared/afa-components/dist/Inputs/TextInput";
import Text from "@afa-shared/afa-components/dist/Text";
import IvFileListImage from "@components/Medias/Image/ivFileListImage";
import TrackedLink from "@components/TrackedLink";
import { LinkType } from "@utils/linkIconHelper";

import { IOrderBlockData, OrderListType } from ".";
import orderFilesStyles from "./orderFiles.module.css";

interface IOrderFileList {
  item: IOrderBlockData;
  orders: Array<OrderListType>;
  setOrders: Dispatch<SetStateAction<OrderListType[]>>;
  largeThumbnail: boolean;
  hideDownloadLinks: boolean;
}
const OrderFiles = ({
  item,
  orders,
  setOrders,
  largeThumbnail,
  hideDownloadLinks,
}: IOrderFileList) => {
  const amountId = useId();

  const metaData = item?.metaData;
  const hasImage = item?.fileData?.hasImage;
  const fNumber = metaData?.fNumber?.toUpperCase();
  const isTooHighAmount = orders?.some((order: OrderListType) =>
    order.orderItem?.sys?.id === item?.sys?.id ? order?.amount > item?.metaData?.orderAmount : false
  );

  const errorMessage = `Det går inte att beställa fler än ${item?.metaData?.orderAmount} exemplar.`;
  const [value, setValue] = useState<number>(
    orders?.find((order: OrderListType) => order.orderItem?.sys?.id === item?.sys?.id)?.amount
  );

  useEffect(() => {
    if (value && +value < 0) {
      setValue(0);
    }
    setOrders(orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const itemExist = (item: IOrderBlockData) =>
    orders.some((o: OrderListType) => o.orderItem === item);

  const handleOnChange = (e: any, item: IOrderBlockData, setValue: any) => {
    const { value } = e.target;
    setValue(value);

    if (itemExist(item)) {
      const updatedOrderList = [...orders.filter((a) => a.orderItem !== item)];
      updatedOrderList.push({ orderItem: item, amount: +value });
      setOrders(updatedOrderList);
    } else {
      setOrders([...orders, { orderItem: item, amount: +value }]);
    }
  };

  const onValueChange = (e, item) => {
    handleOnChange(e, item, setValue);
  };

  return (
    <li
      className={`${orderFilesStyles.orderFile} ${
        largeThumbnail ? orderFilesStyles.largeThumbnail : ""
      }`}
    >
      <div
        className={orderFilesStyles.grid}
        data-contentful-entry-id={item.sys?.id}
        data-contentful-field-id="name"
      >
        {hasImage ? (
          <IvFileListImage fileData={item?.fileData} isLargeImage={largeThumbnail} />
        ) : (
          <></>
        )}
        <Grid justifyContent="space-between">
          <div>
            <Heading variant="h3">{metaData?.headingFileListWeb}</Heading>
            {fNumber && (
              <Text variant="paragraph" as="span">
                Filnummer: {fNumber}
              </Text>
            )}
            {metaData?.teaserText && (
              <Text variant="smallParagraph" className={"smallParagraph"}>
                {metaData?.teaserText}
              </Text>
            )}
          </div>
          {!hideDownloadLinks && item?.fileData?.fileUrl && (
            <TrackedLink
              url={item?.fileData?.fileUrl}
              linkText={`Ladda ner ${
                item?.fileData?.fileSize ? "(" + item?.fileData?.fileSize + ")" : ""
              }`}
              linkType={LinkType.File}
            />
          )}
        </Grid>
      </div>
      <div className={orderFilesStyles.amountGrid}>
        <TextInput
          type="number"
          min={0}
          max={metaData?.orderAmount}
          id={amountId}
          name="amount"
          label="Antal:"
          value={value?.toString()}
          onChange={(e) => onValueChange(e, item)}
          error={isTooHighAmount ? errorMessage : null}
          className={orderFilesStyles.textInput}
        />
      </div>
    </li>
  );
};

export default OrderFiles;
