const phoneRegex = new RegExp("^[0-9+-]+$");
const stateRegex = new RegExp("^[a-zA-ZåäöÅÄÖ +-]+( [a-zA-ZåäöÅÄÖ]+)*$");
const zipCodeRegex = new RegExp("^(([0-9]{5})||([0-9]{3} [0-9]{2}))$");
const birthyearRegex = new RegExp("(?:(?:19|20)[0-9]{2})+$");

const generalMaxInputLength = 50;
const phoneMaxInputLength = 15; //api does not work with a larger number
const emailMaxInputLength = 100;
const addressMaxInputLength = 100;
const messageMaxInputLength = 1000;

export {
  phoneRegex,
  stateRegex,
  zipCodeRegex,
  birthyearRegex,
  phoneMaxInputLength,
  emailMaxInputLength,
  messageMaxInputLength,
  generalMaxInputLength,
  addressMaxInputLength,
};
