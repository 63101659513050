import { RefObject, useEffect, useRef } from "react";

import Modal from "@afa-shared/afa-components/dist/Modal";
import AfaImage from "@components/Medias/Image/AfaImage";
import { setImageModalClosed } from "@utils/globalClassHelper";
import { pixelsToRem } from "@utils/sizeHelper";

import { useEscape } from "hooks/useEscape";
import { useTab } from "hooks/useTab";

import modalStyles from "./Modal.module.css";

interface IImageModal {
  handleModalClose: () => void;
  containerRef: RefObject<HTMLDivElement>;
  imageUrl: string;
  imageAltText: string;
}

const ImageModal = ({ handleModalClose, imageUrl, imageAltText, containerRef }: IImageModal) => {
  const refs = {
    modalCloseButton: useRef(null),
    formSubmitButton: useRef(null),
    hiddenTabHelperTop: useRef(null),
    hiddenTabHelperBottom: useRef(null),
  };

  useEffect(() => {
    refs?.hiddenTabHelperTop?.current?.focus();
    return () => {
      setImageModalClosed();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEscape(containerRef, () => {
    handleModalClose();
  });

  useTab(
    () => {
      if (document?.activeElement === refs?.modalCloseButton?.current) {
        refs.hiddenTabHelperTop?.current?.focus();
      }
    },
    () => {
      if (
        document?.activeElement === refs?.modalCloseButton?.current ||
        document?.activeElement === refs?.hiddenTabHelperTop?.current
      ) {
        refs.hiddenTabHelperBottom?.current?.focus();
      }
    }
  );

  return (
    <div className={modalStyles.modalOverlay}>
      <div
        className={`${modalStyles.modalWrapper} ${modalStyles.wide} ${modalStyles.noTopBarBorder} ${modalStyles.autoHeight}`}
      >
        <div ref={refs.hiddenTabHelperTop} tabIndex={-1} data-temp-keep-index />
        <Modal
          modalOnClose={() => handleModalClose()}
          modalCloseButtonRef={refs.modalCloseButton}
          modalMaxHeight={pixelsToRem(1036)}
          children={
            <div className={modalStyles.modalContent}>
              <AfaImage
                alt={imageAltText}
                fallbackUrl={imageUrl}
                imageSrcSet={[]}
                className={modalStyles.modalImage}
              />
            </div>
          }
        />
        <div ref={refs.hiddenTabHelperBottom} tabIndex={-1} data-temp-keep-index />
      </div>
    </div>
  );
};

export default ImageModal;
