import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Table from "@afa-shared/afa-components/dist/Table";

import { Context } from "types/context/Context";

import { TableBlockType_tableBlock } from "./queries/contentfulTypes/TableBlockType";

interface ITableBlockProps {
  blockData: TableBlockType_tableBlock;
  uniqueKey?: string;
  context?: Context;
  className?: string;
}

export const TableBlock = ({ blockData: tableBlock, context, className }: ITableBlockProps) => {
  const tableHeadings = tableBlock.table.tableData?.slice(0)[0];
  const tableRows = tableBlock.table.tableData?.slice(1);

  // 1-based in Contentful admin app for ease of use for editors, need to convert to 0-based here
  const rightAlignedColumns = tableBlock.rightAlignedColumns?.map((x) => parseInt(x) - 1);

  return (
    <Grid
      container
      className={className}
      data-blockname="tabell"
      data-contentful-entry-id={tableBlock.sys.id}
      data-contentful-field-id="name"
    >
      {tableBlock?.heading && (
        <Grid margin="0 0 1rem">
          <Heading
            variant={context?.heading?.parentLevel}
            removeMargin
            children={tableBlock?.heading}
          />
        </Grid>
      )}
      <Table
        tableHeadings={tableHeadings?.length && tableHeadings}
        tableRows={tableRows?.length && tableRows}
        rightAlignColumns={rightAlignedColumns}
      />
    </Grid>
  );
};

export default TableBlock;
