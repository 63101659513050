import ColorPalette from "@afa-shared/afa-components/dist/ColorPalette";
import RichTextRenderer from "@components/RichTextRenderer";

import { ColorPaletteBlockType_colorPaletteBlock } from "./queries/contentfulTypes/ColorPaletteBlockType";

interface IColorPaletteBlockProps {
  blockData?: ColorPaletteBlockType_colorPaletteBlock;
  uniqueKey?: string;
  context?: string;
}

export const ColorPaletteBlock = ({ blockData: colorPalette }: IColorPaletteBlockProps) => {
  const palette = getColorPalette(colorPalette?.selectedPalette);

  return (
    <div data-contentful-entry-id={colorPalette?.sys?.id} data-contentful-field-id="heading">
      <ColorPalette heading={colorPalette?.heading} palette={palette}>
        {colorPalette?.paletteDescription?.json && (
          <RichTextRenderer json={colorPalette?.paletteDescription?.json} />
        )}
      </ColorPalette>
    </div>
  );
};

type paletteOption =
  | "primary"
  | "secondary"
  | "complementary"
  | "blackAndWhite"
  | "extendedIllustrations"
  | "extendedInfoGraphic"
  | "contrasts";
const getColorPalette = (palette: string): paletteOption => {
  switch (palette) {
    case "Primärfärger":
      return "primary";
    case "Sekundärfärger":
      return "secondary";
    case "Komplementfärger":
      return "complementary";
    case "Svart och vitt":
      return "blackAndWhite";
    case "Utökad färgpalett för illustrationer":
      return "extendedIllustrations";
    case "Utökad färgpalett för infografik":
      return "extendedInfoGraphic";
    case "Kontrastfärger":
      return "contrasts";
    default:
      return "primary";
  }
};
