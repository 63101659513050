import Message from "@afa-shared/afa-components/dist/Message";
import Text from "@afa-shared/afa-components/dist/Text";
import { getErrorAmount } from "@utils/getAmount";

import { scrollIntoView } from "./FormHelper";
import formStyles from "./Forms.module.css";

interface IFormErrorBox {
  errors: any;
  refs: any;
}

const FormErrorBox = ({ errors, refs }: IFormErrorBox) => {
  const numErrors = getErrorAmount(
    Object.keys(errors).filter((key) => !key?.includes("Input") && errors[key]).length
  );

  return (
    <Message
      heading={`Det finns ${numErrors} fel i formuläret`}
      headingVariant="h3"
      type={"error"}
      children={
        <>
          <Text variant="paragraph">
            Informationen kan inte skickas, korrigera i följande fält:
          </Text>
          <ul>
            {Object.keys(errors).map(
              (key, index) =>
                errors[key] && (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                  <li
                    className={formStyles.errorListItem}
                    key={`${key}-${index}`}
                    onClick={() => scrollIntoView(refs[key])}
                  >
                    <button className={formStyles.errorLink} type="button">
                      {errors[key]?.linkText}
                    </button>
                    {` ${errors[key]?.boxDescription}`}
                  </li>
                )
            )}
          </ul>
        </>
      }
    />
  );
};

export default FormErrorBox;
