import { useRef } from "react";

import ContactCard from "@afa-shared/afa-components/dist/ContactCard";
import { BlockSize } from "@components/cFContentArea";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { getImageMetaData } from "@utils/imageHelpers/metadataImageHelper";
import { getPortraitImage } from "@utils/imageHelpers/portraitImageHelper";
import { getInternalOrExternalIcon } from "@utils/linkIconHelper";
import { shouldRemoveBorder } from "@utils/teaserHelper/teaserHelper";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

import { useMultiExternalTracking } from "hooks/useExternalTracking";
import { Context } from "types/context/Context";

import contactBlockStyles from "./contactBlock.module.css";
import { contactTracker } from "./contactTracker";
import { ContactBlockType_contactBlock } from "./queries/contentfulTypes/ContactBlockType";

interface ContactBlockData extends ContactBlockType_contactBlock {
  contactBlockUrl: string;
}

interface IContactBlockProps {
  blockData?: ContactBlockData;
  uniqueKey?: string;
  context?: Context;
}

export const ContactBlock = ({ blockData: contactBlock, context }: IContactBlockProps) => {
  const url = contactBlock?.contactBlockUrl;
  const linkType = getInternalOrExternalIcon(
    contactBlock?.externalLink,
    contactBlock?.internalLink,
    null
  );
  const variant = contactBlock?.linkVariant ? "button" : "link";
  const linkRef = useRef(null);
  const addressLinkRef = useRef(null);

  const cardLinks = [];
  if (contactBlock?.linkText && contactBlock?.contactBlockUrl)
    cardLinks.push({ url: contactBlock?.contactBlockUrl, ref: linkRef });
  if (contactBlock?.address && contactBlock?.addressLink)
    cardLinks.push({ url: contactBlock?.addressLink, ref: addressLinkRef });
  useMultiExternalTracking(cardLinks);

  const biography = documentToHtmlString(contactBlock?.biography?.json);
  const imageData = contactBlock?.ivImage?.[0];
  const image = getPortraitImage(imageData);
  const metadata = getImageMetaData(imageData);

  const contextType = context?.columns || 1;

  return (
    <div
      data-contentful-entry-id={contactBlock.sys.id}
      data-contentful-field-id="name"
      className={`${contactBlockStyles.contactBlock} ${
        contextType > 1 ? contactBlockStyles.multiColContext : ""
      } ${
        context?.sizes?.desktop === BlockSize.Full || context?.sizes?.notebook === BlockSize.Full
          ? "full-width"
          : ""
      }`}
    >
      <ContactCard
        personalName={contactBlock?.personalName}
        headingVariant={context?.heading?.parentLevel}
        imageUrl={replaceImageVaultBasePath(image?.url)}
        imageAlt={metadata?.altText}
        email={contactBlock?.email}
        emailTitleTag={contactBlock?.emailTitleTag}
        emailOnClick={() => contactTracker("epost")}
        phone={contactBlock?.phone}
        phoneLabel={contactBlock?.phoneLabel}
        phoneTitleTag={contactBlock?.phoneTitleTag}
        phoneOnClick={() => contactTracker("telefon")}
        phoneTwo={contactBlock?.phoneTwo}
        phoneTwoLabel={contactBlock?.phoneTwoLabel}
        phoneTwoTitleTag={contactBlock?.phoneTwoTitleTag}
        phoneTwoOnClick={() => contactTracker("telefon")}
        jobTitle={contactBlock?.jobTitel}
        extraInfo={contactBlock?.extraInfo}
        address={contactBlock?.address}
        addressType={contactBlock?.addressType}
        addressLink={contactBlock?.addressLink}
        addressRef={addressLinkRef}
        box={contactBlock?.box}
        zipCode={contactBlock?.zipCode}
        faxLabel={contactBlock?.faxLabel}
        fax={contactBlock?.fax}
        faxTitleTag={contactBlock?.faxTitleTag}
        state={contactBlock?.state}
        biography={biography && <div dangerouslySetInnerHTML={{ __html: biography }}></div>}
        linkUrl={url}
        linkType={linkType}
        linkText={contactBlock?.linkText}
        linkRef={linkRef}
        variant={variant}
        data-blockname="kontaktkort"
        removeBorder={shouldRemoveBorder(context)}
      />
    </div>
  );
};
