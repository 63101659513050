import Link from "next/link";
import router from "next/router";

import FileBlock from "@afa-shared/afa-components/dist/FileBlock";
import { fileDownloadTracker } from "@components/FileList/fileTracker";

import { FileBlockDataType } from "types/FileBlockDataType";
import { Context } from "types/context/Context";

import fileBlockStyles from "./fileBlock.module.css";
import { FileBlockType_fileBlock } from "./queries/contentfulTypes/FileBlockType";

interface FileBlockData extends FileBlockType_fileBlock {
  fileData: FileBlockDataType;
}

//TODO: file block props shoud not be any
interface IFileProps {
  blockData?: FileBlockData;
  uniqueKey?: string;
  IFileBlockProps?: any;
  context: Context;
}
export default function index({ blockData: fileBlock, context }: IFileProps) {
  const urls = fileBlock?.fileData?.urls;
  const thumbNail = fileBlock?.fileData?.hasExtraThumbnail
    ? urls?.size375 ||
      urls?.size768 ||
      urls?.original ||
      urls?.size180 ||
      urls?.size240 ||
      urls?.size480 ||
      ""
    : urls?.size180 ||
      urls?.size240 ||
      urls?.size375 ||
      urls?.size480 ||
      urls?.original ||
      urls?.size768 ||
      "";

  const onCardClick = (event: any) => {
    event.preventDefault();
    fileDownloadTracker(
      fileBlock?.metaData?.headingWeb,
      fileBlock?.metaData?.fileExtension,
      context?.insuranceName || ""
    );
    router.push(fileBlock?.fileData?.fileUrl);
  };

  return fileBlock?.fileData && fileBlock?.fileData?.fileUrl ? (
    <Link
      href={fileBlock?.fileData?.fileUrl}
      prefetch={false}
      className={fileBlockStyles.linkWrapper}
      legacyBehavior={true}
    >
      <div data-contentful-entry-id={fileBlock.sys.id} data-contentful-field-id="name">
        <FileBlock
          onCardClick={(event) => onCardClick(event)}
          fNumber={fileBlock.metaData?.fNumber && `Filnummer: ${fileBlock.metaData?.fNumber}`}
          heading={fileBlock.metaData?.headingWeb}
          headingVariant={context?.heading?.parentLevel}
          fileSource={fileBlock?.fileData?.fileUrl}
          fileSize={`${fileBlock.fileData?.transformedFileSize} (${fileBlock?.metaData?.fileExtension}, ${fileBlock.fileData?.fileSize})`}
          fileMetaTitle={fileBlock.metaData?.headingWeb}
          linkTitle={`Ladda ner ${
            fileBlock.fileData?.fileSize ? "(" + fileBlock.fileData?.fileSize + ")" : ""
          }`}
          thumbNailSource={thumbNail}
          thumbNailAlt={fileBlock.fileData?.thumbNailAlt || fileBlock.metaData?.metaAltText}
          fileType={fileBlock.metaData?.fileExtension}
          data-blockname="filblock"
          className={fileBlockStyles.fileBlock}
          data-contentful-entry-id={fileBlock.sys.id}
          data-contentful-field-id="imageVaultFile"
        />
      </div>
    </Link>
  ) : (
    <></>
  );
}
