import Link from "next/link";
import { CSSProperties } from "react";

import Heading from "@afa-shared/afa-components/dist/Heading";
import Icon from "@afa-shared/afa-components/dist/Icon";
import { getIconOffsets } from "@utils/IconHelper";

import linkedHeadingStyles from "./LinkedHeading.module.css";

interface ILinkedHeading {
  url: string;
  headingLevel: any;
  heading: string;
}

const LinkedHeading = ({ url, headingLevel, heading }: ILinkedHeading): JSX.Element => {
  const iconOffsets = getIconOffsets([headingLevel, headingLevel, headingLevel], [24, 32, 64]);

  const linkStyles: CSSProperties = {
    "--mobile-icon-offset": iconOffsets[0],
    "--tablet-icon-offset": iconOffsets[1],
    "--desktop-icon-offset": iconOffsets[2],
  } as CSSProperties;
  return (
    <div className={linkedHeadingStyles.linkedHeading} style={linkStyles}>
      <Link href={url} prefetch={false}>
        <Icon name="arrow_forward" size="sm" className={linkedHeadingStyles.headingLinkIcon} />
        <Heading
          variant={headingLevel}
          removeMargin
          children={heading}
          className={linkedHeadingStyles.heading}
        />
      </Link>
    </div>
  );
};

export default LinkedHeading;
