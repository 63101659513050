import { RefObject } from "react";
import create from "zustand";
import { devtools } from "zustand/middleware";

export type IFocusState = {
  overridePageTopFocus: boolean;
  setOverridePageTopFocus: (v: boolean) => void;
  onPageLoadRef: RefObject<any>;
  setOnPageLoadRef: (v: RefObject<any>) => void;
};
export const useFocusState = create<IFocusState>(
  devtools(
    (set) => ({
      overridePageTopFocus: false,
      setOverridePageTopFocus: (overridePageTopFocus: boolean) =>
        set((state: IFocusState) => ({ overridePageTopFocus })),
      onPageLoadRef: null,
      setOnPageLoadRef: (onPageLoadRef: RefObject<any>) =>
        set((state: IFocusState) => ({ onPageLoadRef })),
    }),
    { name: "FOCUS_STATE" }
  )
);
