import { externalLinksTracker } from "@containers/Layout/layoutTracker";

const trackExternalLink = (event) => {
  const url = event?.target?.closest("a")?.href;
  const domain = new URL("", url).origin;
  const context = event?.target?.closest("[data-blockname]")?.getAttribute("data-blockname") || "";
  const type = url.indexOf("afaforsakring.se") > -1 ? "intern" : "extern";
  const text = event?.target?.textContent || "";
  externalLinksTracker(domain, url, context, type, text);
};

const isExternalLink = (linkUrl, origin) => {
  const regex = `/(${origin})|(^\/)|(^#)|(tel:)|(mailto:)|(afa.imagevault)|(anpdm.com)/`;
  const isInternalLink = linkUrl?.match(regex);
  return !isInternalLink;
};

export { trackExternalLink, isExternalLink };
