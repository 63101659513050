import Link from "next/link";
import { RefObject, useId, useRef, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import TextInput from "@afa-shared/afa-components/dist/Inputs/TextInput";
import Text from "@afa-shared/afa-components/dist/Text";
import { quizFormActionTracker } from "@components/QuizBlock/QuizTracker";

import FormErrorBox from "../FormErrorBox";
import {
  emailMaxInputLength,
  generalMaxInputLength,
  phoneMaxInputLength,
  scrollIntoView,
  validate,
} from "../FormHelper";
import FormSendError from "../FormSendError";
import formStyles from "../Forms.module.css";

interface IContactForm {
  submitButtonRef: RefObject<any>;
  setFormIsSubmitted: () => void;
  closeModal: () => void;
  quizId: string;
  quizType: string;
}

const ContactForm = ({
  submitButtonRef,
  setFormIsSubmitted,
  closeModal,
  quizId,
  quizType,
}: IContactForm) => {
  const [formHasSendErrors, setFormHasSendErrors] = useState<boolean>(false);
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    organization: { value: "", required: true },
    role: { value: "", required: true },
    name: { value: "", required: true },
    email: { value: "", required: true },
    phone: { value: "", required: false },
  });
  const [errors, setErrors] = useState({
    organization: null,
    role: null,
    name: null,
    email: null,
  });
  const formIds = {
    header: useId(),
    organization: useId(),
    role: useId(),
    name: useId(),
    email: useId(),
    phone: useId(),
  };
  const formRefs = {
    organization: useRef(null),
    role: useRef(null),
    name: useRef(null),
    email: useRef(null),
    phone: useRef(null),
    errorInfoBox: useRef(null),
  };

  const numErrors = Object.keys(errors).filter((key) => errors[key]).length;

  const handleReCaptchaVerify = (token) => {
    if (!token) {
      return;
    }
    token && setIsValidToken(true);
  };

  const handleFormChange = (e: any, key: string = null) => {
    const { value } = e.target;
    setFormState({ ...formState, [key]: { ...formState[key], value: value } });
    setErrors({ ...errors, [key]: null });
  };

  const handleSubmitClick = (e: any) => {
    isValidToken
      ? handleSubmit(e)
      : (e) => {
          e.preventDefault();
        };
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formErrors = validate(formState, errors);
    setErrors(formErrors);
    const foundErrors = Object.values(formErrors).filter((e) => e !== null).length;

    if (foundErrors === 0) {
      let cleanFormState = {};
      for (const field in formState) {
        cleanFormState = { ...cleanFormState, [field]: formState[field].value };
      }
      try {
        const res = await fetch("/api/sendQuizContactRequest", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cleanFormState),
        });

        if (res?.ok) {
          setFormIsSubmitted();
          closeModal();
          sessionStorage.removeItem(`${quizId}-answers`);
          sessionStorage.removeItem(`${quizId}-modal-open`);
          quizFormActionTracker(quizType, "kontakt_quiz", "inskickat");
        } else {
          setFormHasSendErrors(true);
          scrollIntoView(formRefs?.errorInfoBox);
        }
      } catch (err) {
        setFormHasSendErrors(true);
        scrollIntoView(formRefs?.errorInfoBox);
      }
    } else {
      if (foundErrors === 1) {
        const key = Object.keys(formErrors).find((key) => formErrors[key] !== null);
        scrollIntoView(formRefs[key]);
      } else {
        scrollIntoView(formRefs?.errorInfoBox);
      }
    }
  };

  return (
    <div>
      <GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
      <Grid
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={formStyles.formHeading}
      >
        <Heading id={formIds.header} variant="h2" removeMargin>
          Fyll i dina uppgifter
        </Heading>
        <Text variant="span">* Obligatoriskt fält</Text>
      </Grid>
      <div
        ref={formRefs.errorInfoBox}
        aria-live="polite"
        className={`${numErrors > 1 || formHasSendErrors ? formStyles.formFeedbackVisible : ""}`}
        tabIndex={-1}
        data-temp-keep-index
      >
        {numErrors > 1 ? (
          <FormErrorBox errors={errors} refs={formRefs} />
        ) : formHasSendErrors ? (
          <FormSendError />
        ) : (
          <></>
        )}
      </div>
      <div className={formStyles.termsText}>
        <Text variant="smallParagraph">
          {"Mer information om dina rättigheter och "}
          <Link
            href={
              "/kundtjanst/information-for-olika-kategorier/anvandare-och-mottagare-av-digital-kommunikation"
            }
            prefetch={false}
            className={formStyles.termsLink}
          >
            {"hur vi behandlar dina personuppgifter"}
          </Link>
          {"."}
        </Text>
      </div>
      <form className={formStyles.form} aria-labelledby={formIds.header} noValidate>
        <TextInput
          inputRef={formRefs.organization}
          label={"Din organisation *"}
          id={formIds.organization}
          name="organisation"
          required
          value={formState.organization.value}
          type={"text"}
          error={errors?.organization?.input}
          maxLength={generalMaxInputLength}
          fullWidth
          onChange={(e) => handleFormChange(e, "organization")}
        />
        <TextInput
          inputRef={formRefs.role}
          label={"Din roll *"}
          id={formIds.role}
          name="role"
          required
          value={formState.role.value}
          type={"text"}
          error={errors?.role?.input}
          maxLength={generalMaxInputLength}
          fullWidth
          onChange={(e) => handleFormChange(e, "role")}
        />
        <TextInput
          inputRef={formRefs.name}
          label={"Ditt förnamn och efternamn *"}
          id={formIds.name}
          name="name"
          required
          autoComplete="name"
          value={formState.name.value}
          type={"text"}
          error={errors.name?.input}
          maxLength={generalMaxInputLength}
          onChange={(e) => handleFormChange(e, "name")}
        />
        <TextInput
          inputRef={formRefs.email}
          label={"Din e-postadress (kontrollera stavningen) *"}
          required
          id={formIds.email}
          autoComplete="email"
          value={formState.email.value}
          type={"email"}
          error={errors.email?.input}
          maxLength={emailMaxInputLength}
          onChange={(e) => handleFormChange(e, "email")}
        />
        <TextInput
          inputRef={formRefs.phone}
          label={"Ditt telefonnummer"}
          id={formIds.phone}
          autoComplete="tel"
          value={formState.phone.value}
          type={"tel"}
          maxLength={phoneMaxInputLength}
          error={""}
          onChange={(e) => handleFormChange(e, "phone")}
        />
        <div className={formStyles.consentWrapper}>
          <Button
            type="submit"
            buttonType="primary"
            onClick={(e) => handleSubmitClick(e)}
            label={"Skicka"}
            buttonRef={submitButtonRef}
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
