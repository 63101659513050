import Link from "next/link";

import ItemBox from "@afa-shared/afa-components/dist/ItemBox";
import { shouldRemoveBorder } from "@utils/teaserHelper/teaserHelper";

import { Context } from "types/context/Context";

import {
  ShortCutsBlockType_shortCutsBlock,
  ShortCutsBlockType_shortCutsBlock_linksCollection_items,
} from "./queries/contentfulTypes/ShortCutsBlockType";
import { shortCutsTracker } from "./shortCutsTracker";
import shortCutsStyles from "./shotCuts.module.css";

interface IShortCutsBlockProps {
  blockData: ShortCutsBlockType_shortCutsBlock;
  context?: Context;
  maxWidth?: number;
}

export const ShortCutsBlock = ({
  blockData: shortCuts,
  context,
  maxWidth,
}: IShortCutsBlockProps) => {
  const hasColoredContext = shouldRemoveBorder(context);
  const columnClass = maxWidth === 1240 ? shortCutsStyles.wideContext : "";

  return (
    <section
      className={`${shortCutsStyles.shortCuts} ${
        context?.sectionBlock ? shortCutsStyles.shortCutsInSection : ""
      }`}
      data-contentful-entry-id={shortCuts.sys.id}
      data-contentful-field-id="name"
    >
      <ul className={`${shortCutsStyles.list} ${columnClass}`} data-blockname="snabblänkar">
        {shortCuts?.linksCollection?.items?.map(
          (item: ShortCutsBlockType_shortCutsBlock_linksCollection_items, index: number) => {
            const itemUrl = item?.url;
            const icon = item?.shortcutIcon ? `/images/icons/${item?.shortcutIcon}.svg` : "";
            return (
              <li key={index}>
                <Link
                  href={itemUrl}
                  prefetch={false}
                  className={shortCutsStyles.link}
                  onClick={() => shortCutsTracker(item?.linkText)}
                >
                  <ItemBox
                    icon={icon}
                    label={item?.linkText}
                    className={shortCutsStyles.itemBox}
                    removeContentBorder={hasColoredContext}
                    useIconBorder={hasColoredContext}
                  />
                </Link>
              </li>
            );
          }
        )}
      </ul>
    </section>
  );
};

export default ShortCutsBlock;
