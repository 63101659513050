import { RefObject } from "react";

import { isNullOrEmpty, isValidEmail } from "@utils/string";

import formErrors from "./Forms.json";

const telephoneRegex = new RegExp("^[0-9+-]+$");
const cityRegex = new RegExp("^[a-zA-ZåäöÅÄÖ +-]+( [a-zA-ZåäöÅÄÖ]+)*$");
const zipCodeRegex = new RegExp("^(([0-9]{5})||([0-9]{3} [0-9]{2}))$");
const phoneMaxInputLength = 20;
const generalMaxInputLength = 50;
const emailMaxInputLength = 100;
const addressMaxInputLength = 100;

const validate = (formState: any, errors: any) => {
  let updatedErrors = { ...errors };
  for (const field of Object.keys(formState)) {
    if (formState[field].required) {
      for (const errorType of Object.keys(formErrors[field])) {
        if (!isValidField(field, formState[field].value, errorType)) {
          updatedErrors = {
            ...updatedErrors,
            [field]: {
              ...formErrors[field][errorType],
            },
          };
        }
      }
    }
  }
  return updatedErrors;
};

const isValidField = (fieldName: string, fieldValue: string, key: string) => {
  switch (true) {
    case fieldName === "email" && key === "invalid":
      return isValidEmail(fieldValue);
    case fieldName === "zipCode" && key === "invalid":
      return zipCodeRegex.test(fieldValue);
    case fieldName === "city" && key === "invalid":
      return cityRegex.test(fieldValue);
    case fieldName === "phone" && key === "invalid":
      return telephoneRegex.test(fieldValue);
    case fieldName === "consent" && key === "invalid":
      return fieldValue;
    case key === "empty":
      return !isNullOrEmpty(fieldValue);
    default:
      return true;
  }
};

const scrollIntoView = (ref: RefObject<any>) => {
  ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  setTimeout(() => {
    ref?.current?.focus();
  }, 700);
};

export {
  phoneMaxInputLength,
  generalMaxInputLength,
  emailMaxInputLength,
  addressMaxInputLength,
  validate,
  scrollIntoView,
};
