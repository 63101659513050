import clsx from "clsx";

import Text from "@afa-shared/afa-components/dist/Text";
import TrackedLink from "@components/TrackedLink";
import { getInternalOrExternalIcon } from "@utils/linkIconHelper";

import { Context } from "types/context/Context";

import bannerBlockStyles from "./bannerBlock.module.css";
import { BannerBlockType_bannerBlock } from "./queries/contentfulTypes/BannerBlockType";

interface IBannerBlockProps {
  blockData: BannerBlockType_bannerBlock;
  context?: Context;
  uniqueKey?: string;
}

export const BannerBlock = ({ blockData: bannerBlock }: IBannerBlockProps) => {
  const colorCombination = bannerBlock.colorCombination.toLowerCase();

  const banner = clsx(bannerBlockStyles.banner, "clear-blue", {
    ["dark-green"]: colorCombination.includes("mörkgrön"),
    ["dark-blue"]: colorCombination.includes("mörkblå"),
  });

  const url = bannerBlock.linkReference?.url;
  const globalLink = bannerBlock.linkReference?.globalLinkItem;
  const internalReference = bannerBlock.linkReference?.reference;
  const linkType = getInternalOrExternalIcon(url, internalReference, globalLink);
  const linkText = bannerBlock?.linkReference?.linkText;

  if (!url) {
    console.error("Bannerblock: Url is missing");
    return null;
  }
  return (
    <div
      className={banner}
      data-contentful-entry-id={bannerBlock?.sys?.id}
      data-contentful-field-id="name"
    >
      <Text variant={"paragraph"} weight="bold" removeMargin>
        {bannerBlock.text}
      </Text>
      <TrackedLink linkType={linkType} linkText={linkText} url={url} linkVariant={"link"} />
    </div>
  );
};
