import { useId, useState } from "react";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Icon from "@afa-shared/afa-components/dist/Icon";
import ErrorMessage from "@afa-shared/afa-components/dist/Inputs/ErrorMessage";
import RadioButton from "@afa-shared/afa-components/dist/Inputs/RadioButton";
import Text from "@afa-shared/afa-components/dist/Text";

import QuizStep from "../QuizStep/QuizStep";
import { quizEndTracker } from "../QuizTracker";
import { QuizBlockType_quizBlock_questionAreaCollection_items } from "../queries/contentfulTypes/QuizBlockType";
import quizQuestionStyles from "./QuizQuestions.module.css";

interface IQuizQuestions {
  questions: Array<QuizBlockType_quizBlock_questionAreaCollection_items>;
  currentQuestion: number;
  userQuizAnswers: Array<string>;
  handleQuestionChange: (increase: boolean, calculateAnswers?: boolean) => void;
  handleQuizAnswer: (index: number, answer: string) => void;
  formLabel: string;
  quizType: string;
}

const QuizQuestions = ({
  questions,
  currentQuestion,
  userQuizAnswers,
  handleQuestionChange,
  handleQuizAnswer,
  formLabel,
  quizType,
}: IQuizQuestions): JSX.Element => {
  const [showErrorMessage, setShowErrorMessage] = useState<Array<boolean>>(
    Array(questions?.length).fill(false)
  );
  const inputIds = [];
  const errorIds = [];

  questions?.forEach(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputId = useId();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const errorId = useId();
    inputIds.push(inputId);
    errorIds.push(errorId);
  });

  const handleButtonClick = (e, increase: boolean, currentIndex: number) => {
    e.preventDefault();
    if (typeof userQuizAnswers[currentIndex] === "undefined") {
      const errorArray = [...showErrorMessage];
      errorArray[currentIndex] = true;
      setShowErrorMessage(errorArray);
    } else {
      handleQuestionChange(increase, currentIndex === questions?.length - 1);
    }
  };

  const handleRadioChange = (currentIndex: number, answer: string) => {
    const errorArray = [...showErrorMessage];
    errorArray[currentIndex] = false;
    setShowErrorMessage(errorArray);
    handleQuizAnswer(currentIndex, answer);
  };

  const buttons = (isNotebookDown: boolean, index: number) => (
    <div
      className={`${quizQuestionStyles.navigationArea} ${
        index === 0 ? quizQuestionStyles.isFirstStep : ""
      } ${isNotebookDown ? "" : quizQuestionStyles.desktopWrapper}`}
    >
      <Button
        type={index === questions?.length - 1 ? "submit" : "button"}
        buttonType="primary"
        onClick={(e) => {
          handleButtonClick(e, true, index);
          if (index === questions?.length - 1) {
            quizEndTracker(quizType);
          }
        }}
        label={index < questions?.length - 1 ? "Nästa" : "Skicka in svar"}
        aria-label={index < questions?.length - 1 ? `Nästa fråga, fråga ${index + 2}` : ""}
      />
      {index > 0 && (
        <button
          className={quizQuestionStyles.linkButton}
          type="button"
          onClick={() => handleQuestionChange(false)}
          aria-label={`Föregående fråga, fråga ${index}`}
        >
          <Icon name="arrow_back" />
          <Text variant="span" children={"Föregående"} />
        </button>
      )}
    </div>
  );

  return (
    <form aria-labelledby={formLabel} noValidate className={quizQuestionStyles.quizQuestions}>
      {questions?.map((question, index) => {
        const answers = [
          question.answerOne,
          question.answerTwo,
          question.answerThree,
          question.answerFour,
        ].filter((x) => Boolean(x));
        return (
          <div
            className={`${quizQuestionStyles.stepWrapper} ${
              currentQuestion === index + 1 ? quizQuestionStyles.isCurrentStep : ""
            }`}
            key={index}
          >
            <QuizStep
              ivImage={question?.ivImage?.[0]}
              children={
                <>
                  <h3 className={quizQuestionStyles.stepIndicator}>
                    Fråga {index + 1} av {questions?.length}
                  </h3>
                  <legend className={quizQuestionStyles.legend}>{question?.question}</legend>
                  <div>
                    <div
                      className={`${quizQuestionStyles.answerArea} ${
                        showErrorMessage[index] ? quizQuestionStyles.error : ""
                      }`}
                      id={inputIds[index]}
                      aria-describedby={showErrorMessage[index] && errorIds[index]}
                    >
                      {answers.map((answer) => {
                        return (
                          <div
                            key={`answer-${answer}`}
                            className={quizQuestionStyles.answerWrapper}
                          >
                            <RadioButton
                              checked={userQuizAnswers[index] === answer}
                              label={answer}
                              onChange={() => handleRadioChange(index, answer)}
                              onClick={() => handleRadioChange(index, answer)}
                              value={answer}
                              className={quizQuestionStyles.radioButton}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {showErrorMessage[index] && (
                      <ErrorMessage
                        errorMessage={"Du måste markera ett svarsalternativ innan du kan fortsätta"}
                        errorId={errorIds[index]}
                      />
                    )}
                  </div>
                  {buttons(false, index)}
                </>
              }
              notebookDownButtonWrapper={buttons(true, index)}
            />
          </div>
        );
      })}
    </form>
  );
};

export default QuizQuestions;
