import { RefObject, useEffect, useRef, useState } from "react";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Message from "@afa-shared/afa-components/dist/Message";
import Text from "@afa-shared/afa-components/dist/Text";
import ContactModal from "@components/QuizBlock/Modals/ContactModal/ContactModal";
import { quizEndActionTracker, quizFormActionTracker } from "@components/QuizBlock/QuizTracker";
import { QuizBlockType_quizBlock_questionAreaCollection_items } from "@components/QuizBlock/queries/contentfulTypes/QuizBlockType";
import { setQuizModalClosed, setQuizModalOpen } from "@utils/globalClassHelper";

import QuizStep from "../../QuizStep/QuizStep";
import { getQuizResult, getQuizResultImage } from "../ResultHelper";
import { ResultModel } from "../ResultModel";
import organizationTestResultStyles from "./OrgaizationTestResult.module.css";

const headingYourResult = "Ditt resultat";

const defaultResultModel: ResultModel = {
  insufficient: {
    heading: headingYourResult,
    message:
      "Ni har lite att jobba med, men det är precis vad vi kan hjälpa er göra på ett bra sätt!",
  },
  average: {
    heading: headingYourResult,
    message: "Ni svarade lite av varje, vilket innebär att det finns förbättringspotential!",
  },
  great: {
    heading: headingYourResult,
    message:
      "Det ser väldigt bra ut med ert arbete, fortsätt jobba så. Ta del av vårt material och bli ännu bättre.",
  },
  perfect: {
    heading: headingYourResult,
    message:
      "Det ser väldigt bra ut med ert arbete, fortsätt jobba så. Ta del av vårt material och bli ännu bättre.",
  },
  contactFeedback: {
    heading: "Tack för din förfrågan",
    message: "Din kontaktinformation har skickats. Vi återkommer till dig.",
  },
};

interface IOrgaizationTestResult {
  questions: Array<QuizBlockType_quizBlock_questionAreaCollection_items>;
  userCorrectAnswers: number;
  resetQuiz: () => void;
  resultInsufficientMessage: string | null | undefined;
  resultBronzeMessage: string | null | undefined;
  resultSilverMessage: string | null | undefined;
  resultGoldMessage: string | null | undefined;
  bronzeImage: any;
  silverImage: any;
  goldImage: any;
  availableQuizForm: boolean;
  quizId: string;
  showForm: boolean;
  containerRef: RefObject<HTMLDivElement>;
  quizType: string;
}

const OrgaizationTestResult = ({
  questions,
  userCorrectAnswers,
  resetQuiz,
  resultInsufficientMessage,
  resultBronzeMessage,
  resultSilverMessage,
  resultGoldMessage,
  bronzeImage,
  silverImage,
  goldImage,
  availableQuizForm,
  quizId,
  showForm,
  containerRef,
  quizType,
}: IOrgaizationTestResult) => {
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [formIsSubmitted, setFormIsSubmitted] = useState<boolean>(false);
  const sumbitionFeedbackRef = useRef(null);
  const defaultResultMessageModel = getQuizResult(
    userCorrectAnswers,
    questions?.length,
    defaultResultModel,
    resultInsufficientMessage,
    resultBronzeMessage,
    resultSilverMessage,
    resultGoldMessage
  );
  const resultImage = getQuizResultImage(
    userCorrectAnswers,
    questions?.length,
    bronzeImage,
    silverImage,
    goldImage
  );

  useEffect(() => {
    if (showForm && availableQuizForm) {
      handleModalToggle(true);
    } else {
      containerRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalToggle = (setOpen: boolean) => {
    if (setOpen) {
      setScrollPosition(window?.scrollY);
      sessionStorage.setItem(`${quizId}-modal-open`, "true");
    }
    setOpen ? setQuizModalOpen() : setQuizModalClosed();
    setContactModalOpen(setOpen);
    if (!setOpen) {
      window?.scrollTo(0, scrollPosition);
      containerRef?.current?.focus();
      sessionStorage.setItem(`${quizId}-modal-open`, "false");
    }
  };

  const buttons = (isNotebookDown: boolean) => {
    return (
      <div
        className={`${organizationTestResultStyles.buttonWrapper} ${
          isNotebookDown ? "" : organizationTestResultStyles.desktopWrapper
        }`}
      >
        {availableQuizForm && !formIsSubmitted && (
          <Button
            buttonType="primary"
            onClick={() => {
              handleModalToggle(true);
              quizFormActionTracker(quizType, "kontakt_quiz", "påbörjat");
            }}
            label={"Lämna kontaktuppgifter"}
          />
        )}

        <Button
          buttonType={availableQuizForm ? "secondary" : "primary"}
          onClick={() => {
            quizEndActionTracker(quizType, "Gör om testet");
            resetQuiz();
          }}
          label={"Gör om testet"}
        />
      </div>
    );
  };

  return (
    <div className={organizationTestResultStyles.organizationTestResult}>
      <QuizStep
        ivImage={resultImage}
        children={
          <>
            <Message
              heading={
                formIsSubmitted
                  ? defaultResultModel?.contactFeedback?.heading
                  : defaultResultMessageModel?.heading
              }
              headingVariant={"h3"}
              type={"success"}
              children={
                <p>
                  {formIsSubmitted
                    ? defaultResultModel?.contactFeedback?.message
                    : defaultResultMessageModel?.message}
                </p>
              }
              ref={sumbitionFeedbackRef}
            />
            {availableQuizForm && !formIsSubmitted && (
              <Text
                variant="span"
                children={
                  "Vill du veta mer om hur er organisation kan informera om försäkringsskyddet? Lämna dina kontaktuppgifter så återkommer vi till dig."
                }
              />
            )}

            {buttons(false)}
          </>
        }
        notebookDownButtonWrapper={buttons(true)}
      />
      {contactModalOpen && (
        <ContactModal
          handleModalClose={() => handleModalToggle(false)}
          containerRef={containerRef}
          setFormIsSubmitted={() => setFormIsSubmitted(true)}
          quizId={quizId}
          quizType={quizType}
        />
      )}
    </div>
  );
};

export default OrgaizationTestResult;
