import { Context } from "types/context/Context";
import { HeadingContext } from "types/context/HeadingContext";
import { RelatedContentContext } from "types/context/RelatedContentContext";
import { SectionBlockContext } from "types/context/SectionBlockContext";

const defaultContext: Context = {
  accordion: false,
  relatedContent: null,
  sectionBlock: null,
  columns: null,
  insuranceName: null,
  heading: null,
};

const defaultSectionBlockContext: SectionBlockContext = {
  noHeading: false,
  noImage: false,
  noText: false,
  noLink: false,
  coloredSection: false,
  backgroundColor: null,
  smallImages: false,
};

const defaultHeadingContext: HeadingContext = {
  parentLevel: null,
  childLevel: null,
};

const defaultRelatedContentContext: RelatedContentContext = {
  smallImages: false,
};

export {
  defaultContext,
  defaultSectionBlockContext,
  defaultHeadingContext,
  defaultRelatedContentContext,
};
