import Text from "@afa-shared/afa-components/dist/Text";

import photoInfoStles from "./PhotoInfo.module.css";

interface IPhotoDescription {
  photographer?: string;
  description?: string;
  contentfulPreviewEntryId?: string;
  contentfulPreviewImageTextFieldId?: "imageDescription" | "topImageText";
}

export const PhotoInfo = ({
  photographer,
  description,
  contentfulPreviewEntryId,
  contentfulPreviewImageTextFieldId,
}: IPhotoDescription) => {
  return (
    <div className={photoInfoStles.photoInfo}>
      {description && (
        <Text
          variant="smallParagraph"
          className={photoInfoStles.description}
          data-contentful-entry-id={contentfulPreviewEntryId}
          data-contentful-field-id={contentfulPreviewImageTextFieldId}
        >
          {description}
        </Text>
      )}
      {photographer && (
        <Text variant="smallParagraph" className={photoInfoStles.photographer}>
          {"Foto: " + photographer}
        </Text>
      )}
    </div>
  );
};
export default PhotoInfo;
