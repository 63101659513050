import { PaymentDay } from "contexts/PaymentDaysContext";

import paymentDaysBlockStyles from "./PaymentDaysBlock.module.css";
import { sortDates } from "./paymentDaysHelper";
import { PaymentDaysBlockType_paymentDaysBlock } from "./queries/contentfulTypes/PaymentDaysBlockType";

interface PaymentDaysListProps {
  paymentDays: PaymentDay[];
  blockData: PaymentDaysBlockType_paymentDaysBlock;
}

const PaymentDaysList = ({ paymentDays, blockData }: PaymentDaysListProps) => {
  return paymentDays.length ? (
    <ul
      className={paymentDaysBlockStyles.paymentDaysList}
      data-contentful-entry-id={blockData.sys?.id}
      data-contentful-field-id="name"
    >
      {sortDates(paymentDays).map((date, key) => (
        <li className={paymentDaysBlockStyles.listItem} key={key}>
          {date.dayOfMonth} <span className={paymentDaysBlockStyles.bold}>{date.month}</span> (
          {date.dayOfWeek})
        </li>
      ))}
    </ul>
  ) : (
    <span>Något gick fel</span>
  );
};
export default PaymentDaysList;
