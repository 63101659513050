import ItemBox from "@afa-shared/afa-components/dist/ItemBox";
import { getHeroShortcutLinkColor } from "@utils/pageHelpers/heroHelper";

import { MobileMenuShortcut } from "types/MobileMenuShortcut";

import targetGroupStyles from "./targetGroup.module.css";

interface IMobileMenuLinks {
  mobileMenuShortcuts: Array<MobileMenuShortcut>;
}
const TargetGroup = ({ mobileMenuShortcuts }: IMobileMenuLinks): JSX.Element => {
  return (
    <ul className={targetGroupStyles.targetGroup}>
      {mobileMenuShortcuts?.map((item: MobileMenuShortcut, index: number) => {
        const tempIcon = `/images/icons/${item?.iconName}.svg`;
        return (
          <li key={index}>
            <a className={targetGroupStyles.targetGroupLink} href={`${item?.url}`}>
              <ItemBox
                icon={tempIcon}
                iconBackground={
                  index === 0 || index === 1
                    ? item?.iconBackground
                    : getHeroShortcutLinkColor(index)
                }
                label={item?.text}
                removeContentBorder
                narrow
              />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default TargetGroup;
