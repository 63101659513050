import router from "next/router";
import { useRef } from "react";

import { ImageSize } from "@afa-shared/afa-components/dist/Card";
import Card from "@afa-shared/afa-components/dist/Card";
import IvTeaserImage from "@components/Medias/Image/IvTeaserImage";
import { relatedContentTracker } from "@components/RelatedContent/relatedContentTracker";
import { BlockSize } from "@components/cFContentArea";
import { getInternalOrExternalIcon } from "@utils/linkIconHelper";
import { getBackgroundColor } from "@utils/pageHelpers/getBackgrundColor";
import { columnSize } from "@utils/sectionHelper";
import {
  getDirection,
  getReverse,
  getTeaserImageSize,
  shouldRemoveBorder,
  shouldRemovePadding,
  shouldUseNotebookSmallImages,
  shouldUseSmallImages,
} from "@utils/teaserHelper/teaserHelper";

import { useExternalTrackingByParent } from "hooks/useExternalTracking";
import useMediaQuery from "hooks/useMediaQuery";
import { Context } from "types/context/Context";

import { TeaserBlockType_teaserBlock } from "./queries/contentfulTypes/TeaserBlockType";

interface TeaserBlockData extends TeaserBlockType_teaserBlock {
  teaserBlockUrl: string;
  fileSize: string;
  fileName: string;
  fileExtension: string;
  id: string;
}
interface ITeaserBlockProps {
  blockData: TeaserBlockData | any;
  uniqueKey?: string;
  context: Context;
  maxWidth: number;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const TeaserBlock = ({ blockData: teaserBlock, context, maxWidth }: ITeaserBlockProps) => {
  const url = teaserBlock.teaserBlockUrl;
  const linkType = getInternalOrExternalIcon(
    teaserBlock?.externalLink,
    teaserBlock?.link,
    teaserBlock?.globalLinkItem
  );
  const cardRef = useRef(null);
  useExternalTrackingByParent({ url: url, ref: cardRef });

  const headingText =
    context?.sectionBlock && context?.sectionBlock?.noHeading ? null : teaserBlock?.heading;
  const text = context?.sectionBlock && context?.sectionBlock?.noText ? null : teaserBlock?.text;
  let linkText =
    context?.sectionBlock && context?.sectionBlock?.noLink ? null : teaserBlock?.linkText;
  let customAriaLabel: string;
  if (teaserBlock?.fileSize) {
    linkText = linkText + " (" + teaserBlock?.fileSize + ")";
    customAriaLabel =
      headingText +
      " (" +
      (teaserBlock?.fileExtension ? teaserBlock?.fileExtension + ", " : "") +
      teaserBlock?.fileSize +
      ")";
  }

  const isMobile = useMediaQuery("mobile");
  const isTabletOnly = useMediaQuery("tabletOnly");
  const isNotebook = useMediaQuery("notebook");
  const imageData = teaserBlock?.ivImage?.[0];
  const getImage = (imageData: any) => {
    return context?.sectionBlock && context?.sectionBlock?.noImage ? null : (
      <IvTeaserImage image={imageData} />
    );
  };

  let size: number = 12;
  const contextSize = context?.columns || 1;
  if (context?.sectionBlock) {
    size = contextSize < 2 || isMobile ? 12 : contextSize === 2 ? 6 : 4;
  } else {
    size = columnSize(contextSize);
  }

  const handleClick = (event, url): void => {
    event.preventDefault();
    router.push(url);
    if (context?.relatedContent) {
      relatedContentTracker(headingText, context?.insuranceName || "");
    }
  };

  const useSmallImage = shouldUseSmallImages(size, maxWidth, context);
  const reverse = getReverse(size, maxWidth) && !teaserBlock?.isReverse;
  const direction = getDirection(size, maxWidth, !isNotebook && isTabletOnly);
  const imgSize: ImageSize = getTeaserImageSize(size, maxWidth, useSmallImage, context);
  const useNotebookSmallImages = shouldUseNotebookSmallImages(size, maxWidth);
  const noPadding = shouldRemovePadding(teaserBlock?.backgroundColor, context);

  //Temp solution for nofollow rel on "intressant vetande"-teaser
  const tempNofollow = teaserBlock?.id === "5L39saocxuRQdvNuNYuTXc" ? "nofollow" : "";

  return (
    <Card
      onCardClick={(event) => handleClick(event, url)}
      linkUrl={url}
      nextImage={getImage(imageData)}
      direction={direction}
      heading={headingText}
      headingVariant={context?.heading?.parentLevel}
      text={text}
      customAriaLabelForLinkedHeading={customAriaLabel}
      linkText={linkText}
      reverse={reverse}
      linkType={linkType}
      rel={tempNofollow as any}
      data-blockname={context?.relatedContent ? "relaterat innehåll" : "puff"}
      removeBorder={shouldRemoveBorder(context, teaserBlock?.backgroundColor)}
      backgroundColor={getBackgroundColor(teaserBlock?.backgroundColor)}
      linkVariant={teaserBlock?.useButtonLink ? "button" : "link"}
      imgSize={imgSize}
      fileExtension={teaserBlock?.fileExtension}
      notebookSmallImage={useNotebookSmallImages}
      removePadding={noPadding}
      cardRef={cardRef}
      className={
        context?.sizes?.desktop === BlockSize.Full || context?.sizes?.notebook === BlockSize.Full
          ? "full-width"
          : ""
      }
      data-contentful-entry-id={teaserBlock?.sys?.id}
      data-contentful-field-id="name"
    />
  );
};

export default TeaserBlock;
