import {
  PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_CALENDAR,
  PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_DAILY,
  PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_MONTHLY,
  PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_PARENTAL,
  PAYMENT_DAYS_TYPE_DAILY,
  PAYMENT_DAYS_TYPE_MONTHLY,
  PAYMENT_DAYS_TYPE_PARENTAL,
  PaymentDay,
  PaymentDayType,
  PaymentDaysContext,
} from "contexts/PaymentDaysContext";
import { useContext } from "react";

import InformationBlock from "@components/InformationBlock";

import { Context } from "types/context/Context";

import PaymentDaysCalendarView from "./PaymentDaysCalendarView";
import PaymentDaysList from "./PaymentDaysList";
import PaymentFactCard from "./PaymentFactCard";
import PaymentFactCardTwoDates from "./PaymentFactCardTwoDates";
import { PaymentDaysBlockType_paymentDaysBlock } from "./queries/contentfulTypes/PaymentDaysBlockType";

interface PaymentBlockProps {
  blockData?: PaymentDaysBlockType_paymentDaysBlock;
  uniqueKey?: string;
  context?: Context;
}

export const PAYMENT_DAY_LIST_INSURANCE_MONTHLY = "Lista - sjukförsäkring - månadsersättning";
export const PAYMENT_DAY_LIST_INSURANCE_DAILY = "Lista - sjukförsäkring - dagsersättning";
export const PAYMENT_DAY_LIST_PARENTAL_BENEFIT = "Lista - föräldrapenningtillägg";
export const PAYMENT_DAY_INSURANCE_NEXT_DAY_MONTHLY =
  "Nästkommande utbetalningsdag - sjukförsäkring - månadsersättning";
export const PAYMENT_DAY_INSURANCE_NEXT_DAY_DAILY =
  "Nästkommande utbetalningsdag - sjukförsäkring - dagsersättning";
export const PAYMENT_DAY_INSURANCE_NEXT_DAY_DAILY_AND_MONTHLY =
  "Nästkommande utbetalningsdag - sjukförsäkring - dagsersättning & månadsersättning";
export const PAYMENT_DAY_INSURANCE_NEXT_DAY_PARENTAL_BENEFIT =
  "Nästkommande utbetalningsdag - föräldrapenningtillägg";
export const PAYMENT_DAY_INSURANCE_NEXT_DAY_ALL = "Nästkommande utbetalningsdagar - samlad vy";

const filterPaymentDays = (paymentDays: PaymentDay[], type: PaymentDayType) =>
  paymentDays.filter((paymentDay) => paymentDay.type === type);

export const PaymentDaysBlock = ({ blockData, context }: PaymentBlockProps) => {
  const paymentDaysContext = useContext(PaymentDaysContext);
  const paymentDays = paymentDaysContext.paymentDays;
  const informationBlock = paymentDaysContext.informationBlock;
  const informationBlockPlacements = paymentDaysContext.informationBlockPlacement || [];

  switch (blockData.paymentDayType) {
    case PAYMENT_DAY_LIST_INSURANCE_MONTHLY:
      return (
        <>
          <PaymentDaysList
            paymentDays={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_MONTHLY)}
            blockData={blockData}
          />
          {informationBlockPlacements.includes(
            PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_MONTHLY
          ) && <InformationBlock blockData={informationBlock} context={context} />}
        </>
      );
    case PAYMENT_DAY_LIST_INSURANCE_DAILY:
      return (
        <>
          <PaymentDaysList
            paymentDays={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_DAILY)}
            blockData={blockData}
          />
          {informationBlockPlacements.includes(PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_DAILY) && (
            <InformationBlock blockData={informationBlock} context={context} />
          )}
        </>
      );
    case PAYMENT_DAY_LIST_PARENTAL_BENEFIT:
      return (
        <>
          <PaymentDaysList
            paymentDays={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_PARENTAL)}
            blockData={blockData}
          />
          {informationBlockPlacements.includes(
            PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_PARENTAL
          ) && <InformationBlock blockData={informationBlock} context={context} />}
        </>
      );
    case PAYMENT_DAY_INSURANCE_NEXT_DAY_MONTHLY:
      return (
        <>
          <PaymentFactCard
            paymentDays={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_MONTHLY)}
            context={context}
            blockData={blockData}
          />
          {informationBlockPlacements.includes(
            PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_MONTHLY
          ) && <InformationBlock blockData={informationBlock} context={context} />}
        </>
      );
    case PAYMENT_DAY_INSURANCE_NEXT_DAY_DAILY:
      return (
        <>
          <PaymentFactCard
            paymentDays={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_DAILY)}
            context={context}
            blockData={blockData}
          />
          {informationBlockPlacements.includes(PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_DAILY) && (
            <InformationBlock blockData={informationBlock} context={context} />
          )}
        </>
      );
    case PAYMENT_DAY_INSURANCE_NEXT_DAY_PARENTAL_BENEFIT:
      return (
        <>
          <PaymentFactCard
            paymentDays={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_PARENTAL)}
            context={context}
            blockData={blockData}
          />
          {informationBlockPlacements.includes(
            PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_PARENTAL
          ) && <InformationBlock blockData={informationBlock} context={context} />}
        </>
      );
    case PAYMENT_DAY_INSURANCE_NEXT_DAY_DAILY_AND_MONTHLY:
      return (
        <>
          <PaymentFactCardTwoDates
            paymentDaysMonthly={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_MONTHLY)}
            paymentDaysDaily={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_DAILY)}
            context={context}
            blockData={blockData}
          />
          {informationBlockPlacements.includes(
            PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_CALENDAR
          ) && <InformationBlock blockData={informationBlock} context={context} />}
        </>
      );

    case PAYMENT_DAY_INSURANCE_NEXT_DAY_ALL:
      return (
        <>
          <PaymentDaysCalendarView
            paymentDaysMonthly={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_MONTHLY)}
            paymentDaysDaily={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_DAILY)}
            paymentDaysParental={filterPaymentDays(paymentDays, PAYMENT_DAYS_TYPE_PARENTAL)}
            blockData={blockData}
          />
          {informationBlockPlacements.includes(
            PAYMENT_DAYS_INFORMATION_BLOCK_PLACEMENT_CALENDAR
          ) && <InformationBlock blockData={informationBlock} context={context} />}
        </>
      );
    default:
      return <></>;
  }
};
export default PaymentDaysBlock;
