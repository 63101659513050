import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import Icon from "@afa-shared/afa-components/dist/Icon";
import TargetGroup from "@components/TargetGroup/targetGroup";
import TrackedLink from "@components/TrackedLink";
import { TopbarNavlinksTracker } from "@containers/Layout/PageHeader/HeaderTracker";
import { hasVisibleChildrenForAudience } from "@utils/menuHelper";

import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { MenuItem } from "types/MenuItem";

import { navigationTracker } from "../NavigationTracker";
import mobileNavigationStyle from "./Mobile.module.css";

const MobileNavigation = (props: any): JSX.Element | any => {
  const [mobileMenuItems, setMobileMenuItems] = useState<Array<MenuItem>>(props.menuItems);
  const selectedSector = useSectorToggler((state: ISectorToggler) => state.selectedSector);

  const router = useRouter();

  const hasCustomMenu: boolean = props?.isBrandPortal || props?.isCampaignPage;

  useEffect(() => {
    setMobileMenuItems(props.menuItems);
    () => {
      setMobileMenuItems([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.asPath, selectedSector]);

  const renderSubMenu = (item: MenuItem, levelTwoParent: string) => {
    return (
      <ul
        className={`${mobileNavigationStyle.list} ${
          item?.isOpened ? mobileNavigationStyle.isOpen : ""
        }`}
        id={item.id}
        data-level={item?.level + 1}
      >
        {item.children.map((child: MenuItem) =>
          getMenuItemsRecursively(
            child,
            levelTwoParent,
            child?.hideForAudiences?.includes(selectedSector?.audienceId)
          )
        )}
      </ul>
    );
  };
  const TrackAndCloseMenu = (item, section, event) => {
    if (event?.target?.closest(".icon-wrapper") === null) {
      props.toggleMenu
        ? document.body.classList.remove("mobile-menu-open")
        : document.body.classList.add("mobile-menu-open");
      props.setToggleMenu();
      item?.level === 1
        ? TopbarNavlinksTracker(item?.name, item?.name)
        : navigationTracker(item?.name, section);
    }
  };
  const toggleItemRecursively = (item: MenuItem, array: Array<MenuItem>): Array<MenuItem> => {
    for (const menuItem of array) {
      if (menuItem?.id === item?.id) {
        menuItem.isOpened = !menuItem.isOpened;
      } else {
        toggleItemRecursively(item, menuItem?.children);
      }
    }
    return array;
  };

  const ToggleDropDown = (item: MenuItem) => {
    if (!item?.children?.length) return;
    const array = toggleItemRecursively(item, mobileMenuItems);
    setMobileMenuItems([...array]);
  };
  const getMenuItemsRecursively = (
    item: MenuItem,
    levelTwoParent: string,
    hidden: boolean
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    if (item?.hideInMenu) return null;
    const section = item?.level === 2 ? item?.name : levelTwoParent;
    const hasActiveHiddenChildren =
      !item?.isActive &&
      item?.hasActiveChildren &&
      !hasVisibleChildrenForAudience(item, selectedSector?.audienceId) &&
      item?.slug !== "varumarke" &&
      item?.slug !== "intressant-vetande" &&
      item?.slug !== "ingen-missad-ersattning";
    return (
      <li
        className={`${mobileNavigationStyle.listItem} ${
          hidden ? mobileNavigationStyle.hidden : ""
        } ${item?.isActive || hasActiveHiddenChildren ? mobileNavigationStyle.active : ""}`}
        key={item?.id}
        data-level={item?.level}
      >
        {item?.isContainer && hasVisibleChildrenForAudience(item, selectedSector?.audienceId) ? (
          <button
            className={`${mobileNavigationStyle.labelButton} ${
              item?.isActive || hasActiveHiddenChildren ? mobileNavigationStyle.active : ""
            } ${item?.hasActiveChildren ? mobileNavigationStyle.inActivePath : ""}`}
            aria-expanded={item?.isOpened}
            aria-controls={item.id}
            aria-label={`Växla undermeny för ${item?.name}`}
            onClick={() => {
              ToggleDropDown(item);
            }}
            data-level={item?.level}
            data-testid={`page-header-mobile-container-${item?.slug}`}
          >
            {item?.name}
            {item?.children?.length ? (
              <Icon
                name={item?.isOpened ? "remove" : "add"}
                className={mobileNavigationStyle.toggleIcon}
              />
            ) : null}
          </button>
        ) : (
          <div
            className={`${mobileNavigationStyle.label} ${
              item?.isActive || hasActiveHiddenChildren ? mobileNavigationStyle.active : ""
            }`}
            data-level={item?.level}
          >
            <Link
              href={item?.redirectsTo ? item?.redirectsTo : item?.url}
              prefetch={false}
              className={`${mobileNavigationStyle.labelLink} ${
                item?.isActive || hasActiveHiddenChildren ? mobileNavigationStyle.active : ""
              } ${item?.hasActiveChildren ? mobileNavigationStyle.inActivePath : ""}`}
              onClick={(event) => TrackAndCloseMenu(item, section, event)}
              data-level={item?.level}
              data-testid={`page-header-mobile-link-${item?.slug}`}
            >
              {item?.name}
            </Link>

            {item?.children?.length &&
            hasVisibleChildrenForAudience(item, selectedSector?.audienceId) ? (
              <button
                className={mobileNavigationStyle.iconButton}
                aria-expanded={item?.isOpened}
                aria-controls={item.id}
                aria-label={`Växla undermeny för ${item?.name}`}
                data-testid={`page-header-mobile-button-${item?.slug}`}
                onClick={(e) => {
                  e.preventDefault();
                  ToggleDropDown(item);
                }}
              >
                <Icon
                  name={item?.isOpened ? "remove" : "add"}
                  className={mobileNavigationStyle.toggleIcon}
                />
              </button>
            ) : null}
          </div>
        )}
        {item?.children?.length ? renderSubMenu(item, section) : null}
      </li>
    );
  };

  return (
    <div
      data-testid={"mobile-menu"}
      className={`${mobileNavigationStyle.mobileNavigation} ${
        props.showMenu ? mobileNavigationStyle.isOpen : ""
      }`}
      id={props.mobileMenuId}
    >
      <ul
        className={`${mobileNavigationStyle.list} ${
          props.showMenu ? mobileNavigationStyle.isOpen : ""
        }`}
        data-level={1}
      >
        {mobileMenuItems?.map((item: MenuItem) => {
          const hidden = item?.hideForAudiences?.includes(selectedSector?.audienceId);
          return getMenuItemsRecursively(item, "", hidden);
        })}
      </ul>
      {hasCustomMenu ? (
        <TrackedLink
          linkText={"Gå till Afa Försäkrings webbplats"}
          url={"/"}
          linkVariant="button"
          buttonStyle="secondary"
          target="_self"
        />
      ) : (
        <TargetGroup mobileMenuShortcuts={props?.mobileMenuShortcuts} />
      )}
    </div>
  );
};

export default MobileNavigation;
