import * as ga from "../../lib/ga";

export const fileDownloadTracker = (fileName: string, extention: string, insurance: string) => {
  const trackingData = {
    event: "file_download",
    event_parameters: {
      page_location: window.location.href,
      file_name: fileName?.toLowerCase(),
      file_extention: extention?.toLowerCase(),
      försäkring: insurance?.toLowerCase(),
    },
  };

  ga.gaTracking(trackingData);
};
