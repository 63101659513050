import clsx from "clsx";
import { useId, useRef, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import TextInput from "@afa-shared/afa-components/dist/Inputs/TextInput";
import Text from "@afa-shared/afa-components/dist/Text";
import TrackedLink from "@components/TrackedLink";

import FormErrorFeedback from "../Feedback/FormErrorFeedback";
import FormSendFeedback from "../Feedback/FormSendFeedback";
import FormSuccessFeedback from "../Feedback/FormSuccessFeedback";
import { emailMaxInputLength } from "../formConstants";
import { scrollIntoView, validate } from "../formHelper";
import formStyles from "../forms.module.css";
import newsletterStyles from "./newsletterForm.module.css";

interface INewsletterForm {
  mailingListId: string;
  title: string;
  description: string;
  simpleVersion: boolean;
  contentfulPreviewEntryId?: string;
}

export const NewsletterForm = ({
  mailingListId,
  title,
  description,
  simpleVersion,
  contentfulPreviewEntryId,
}: INewsletterForm) => {
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [errorSendMail, setErrorSendMail] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    email: { value: "", required: true },
  });

  const policyText = "Så behandlar vi personuppgifter";
  const policyLink = "/personuppgifter";

  const [errors, setErrors] = useState<any>({
    email: null,
  });

  const formRefs = {
    sendFeedback: useRef(null),
    errorInfobox: useRef(null),
    email: useRef(null),
    approved: useRef(null),
  };

  const formIds = {
    email: useId(),
    header: useId(),
  };

  const handleFormChange = (e: any, key: string = null) => {
    const { value } = e.target;
    setFormState({ ...formState, [key]: { ...formState[key], value: value } });
    setErrors({ ...errors, [key]: null });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const formErrors = validate(formState, errors);
    setErrors(formErrors);
    const foundErrors = Object.values(formErrors).filter((e) => e !== null).length;

    if (foundErrors) {
      scrollIntoView(formRefs?.email);
    } else {
      setIsLoading(true);
      try {
        const res = await fetch(`/api/manageSubscriptions`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: {
              emailAddress: formState.email.value,
            },
            subscriptions: [
              {
                messageId: "1",
                action: "subscribe",
                listId: mailingListId,
              },
            ],
          }),
        });

        if (res.ok) {
          setDisplayForm(true);
          formRefs?.sendFeedback?.current?.focus();
          //Oklart om detta ska trackas
          //newsletterTracker("ny prenumerant", formLabels[mailingListId]?.gaTitle?.toLowerCase());
        } else {
          setErrorSendMail(true);
          setDisplayForm(true);
          formRefs?.sendFeedback?.current?.focus();
          //Oklart om detta ska trackas
          //newsletterTracker("felmeddelande", newsletterTitle);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleReCaptchaVerify = (token) => {
    if (!token) {
      return;
    }
    token && setIsValidToken(true);
  };

  const renderFormFeedback = () => (
    <FormSuccessFeedback
      messageHeading="Din information har skickats"
      messageChildren={
        <Text variant="paragraph" removeMargin>
          Du får strax ett mejl från oss. Kontrollera att det inte fastnar i skräppostfiltret.
        </Text>
      }
      messageLink={<TrackedLink linkText={policyText} url={policyLink} />}
    />
  );

  const renderErrorMessage = () => <FormErrorFeedback />;

  const showFormFeedback: boolean = displayForm && !errorSendMail;
  return (
    <div
      className="form-container"
      data-contentful-entry-id={contentfulPreviewEntryId}
      data-contentful-field-id="name"
    >
      <Grid container className={newsletterStyles.newsletterFormWrapper}>
        {!simpleVersion && (
          <Grid
            mobile={12}
            tablet={12}
            desktop={6}
            position="relative"
            className={newsletterStyles.newsletterImageWrapper}
          ></Grid>
        )}
        <Grid
          mobile={12}
          tablet={12}
          desktop={6}
          className={newsletterStyles.newsletterContentWrapper}
          wrap="nowrap"
        >
          <Heading id={formIds?.header} variant="h2" removeMargin>
            {title ? title : "Prenumerera på nyhetsbrevet"}
          </Heading>
          {displayForm && (
            <FormSendFeedback
              formSendRef={formRefs?.sendFeedback}
              formErrorFeedback={renderErrorMessage()}
              formSuccessFeedback={renderFormFeedback()}
              showSuccessFeedback={showFormFeedback}
              showErrorFeedback={errorSendMail}
            />
          )}

          {!displayForm && (
            <>
              <GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />

              <form
                noValidate
                aria-labelledby={formIds?.header}
                className={`${formStyles.form} ${newsletterStyles.newsletterForm}`}
              >
                <Grid direction="column" justifyContent="space-between" width="100%" wrap="nowrap">
                  {description && (
                    <div className={newsletterStyles.formDescription}>
                      <Text variant="span">{description}</Text>
                    </div>
                  )}

                  <div className={newsletterStyles.inputWrapper}>
                    <TextInput
                      inputRef={formRefs?.email}
                      label={"Din e-postadress (kontrollera stavningen)*"}
                      id={formIds?.email}
                      autoComplete="email"
                      value={formState?.email?.value}
                      type={"email"}
                      maxLength={emailMaxInputLength}
                      width="100%"
                      error={errors?.email?.input}
                      onChange={(e) => handleFormChange(e, "email")}
                    />
                  </div>
                  <div className={newsletterStyles.termsLinkWrapper}>
                    <TrackedLink linkText={policyText} url={policyLink} />
                  </div>
                </Grid>
                <Button
                  type="submit"
                  buttonType="primary"
                  onClick={
                    isValidToken
                      ? onSubmit
                      : (e) => {
                          e.preventDefault();
                        }
                  }
                  margin="0 0 0 0"
                  label={isLoading ? "" : "Skicka"}
                  className={clsx(newsletterStyles.newsletterSubmitButton, {
                    [newsletterStyles.spinner]: isLoading,
                  })}
                  disabled={isLoading}
                />
              </form>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default NewsletterForm;
