import cx from "clsx";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";

import { Heading } from "@afa-shared/afa-components";
import { BlockSize } from "@components/cFContentArea";
import { getBackgroundColor } from "@utils/pageHelpers/getBackgrundColor";
import { columnSize } from "@utils/sectionHelper";
import { shouldRemoveBorder, shouldRemovePadding } from "@utils/teaserHelper/teaserHelper";

import { CookieConsentCategory, useCookieConsent } from "hooks/useCookieConsent";
import useMediaQuery from "hooks/useMediaQuery";
import { Context } from "types/context/Context";

import videoBlockStyles from "./VideoBlock.module.css";
import { VideoBlockType_videoblock } from "./queries/contentfulTypes/VideoBlockType";

const VideoCard = dynamic(() => import("@afa-shared/afa-components/dist/VideoCard"), {
  loading: () => <p>Loading...</p>,
});

interface IVideoBlockProps {
  blockData: VideoBlockType_videoblock;
  uniqueKey?: string;
  context: Context;
}

export const VideoBlock = ({ blockData: videoblock, context }: IVideoBlockProps) => {
  const marketingCookieConsentGiven = useCookieConsent(CookieConsentCategory.marketing);

  const linkToContent = videoblock?.externalLink;

  const headingText = context?.sectionBlock?.noHeading ? null : videoblock?.heading;
  const text = context?.sectionBlock?.noText ? null : videoblock?.text;
  const linkText = context?.sectionBlock?.noLink ? null : videoblock?.linkText;
  const videoTitle = videoblock?.videoTitle;

  const [playing, setPlaying] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const videoRef = useRef(null);

  const isMobile = useMediaQuery("mobile");

  const acceptMarketingCookies = () => {
    global.cookieTractor?.consent.add(global.cookieTractor?.category.marketing);
  };

  useScrollEffect(playing, videoRef, setIsVisible, setPlaying);
  useVisibilityEffect(isVisible, setPlaying);

  const size = calculateSize(context, isMobile);

  const showTextBeforeVideo = videoblock?.isReverse;
  const direction = videoblock?.isColumn || size < 6 ? "column" : "row";
  const noPadding = shouldRemovePadding(videoblock?.backgroundColor, context);

  const classnames = cx(videoBlockStyles.videoBlock, {
    "full-width":
      context?.sizes?.desktop === BlockSize.Full || context?.sizes?.notebook === BlockSize.Full,
  });

  return (
    <div
      className={classnames}
      ref={videoRef}
      data-blockname={context?.relatedContent ? "relaterat innehåll" : "videoblock"}
      data-videolinkurl={videoblock?.url}
      data-contentful-entry-id={videoblock.sys?.id}
      data-contentful-field-id="name"
    >
      <Heading variant="h2" tabIndex="0" className={videoBlockStyles.videoTitle}>
        Video: {videoTitle}
      </Heading>
      <VideoCard
        videoUrl={videoblock?.url}
        playing={playing}
        heading={headingText}
        headingVariant={context?.heading?.parentLevel}
        text={text}
        linkText={linkText}
        linkUrl={linkToContent}
        reverse={showTextBeforeVideo}
        direction={direction}
        onAcceptMarketingConsent={() => acceptMarketingCookies()}
        consentGivenForMarketing={marketingCookieConsentGiven}
        removeBorder={shouldRemoveBorder(context, videoblock?.backgroundColor)}
        backgroundColor={getBackgroundColor(videoblock?.backgroundColor)}
        linkVariant={videoblock?.useButtonLink ? "button" : "link"}
        removePadding={noPadding}
        videoOverlayImg={"/images/video-overlay.svg"}
        useDefaultImage={true}
        videoTitle={videoTitle}
      />
    </div>
  );
};

export default VideoBlock;

const calculateSize = (context: Context, isMobile: boolean): number => {
  const contextSize = context?.columns || 1;
  if (context?.sectionBlock) {
    return contextSize < 2 || isMobile ? 12 : contextSize === 2 ? 6 : 4;
  }
  return columnSize(contextSize) as number;
};

const useScrollEffect = (
  playing: boolean,
  videoRef: React.RefObject<HTMLDivElement>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  useEffect(() => {
    const onScroll = () => {
      if (!videoRef?.current) {
        setIsVisible(false);
        return;
      }
      const top = videoRef?.current?.getBoundingClientRect()?.top;
      const { clientHeight } = videoRef?.current;
      setIsVisible(top + clientHeight >= 0 && top <= window?.innerHeight);
    };

    const onAccordionClose = () => {
      setPlaying(false);
    };
    if (playing) {
      document.addEventListener("scroll", onScroll, true);
      const parentAccordion = videoRef?.current?.closest(".accordion")?.querySelector("button");
      parentAccordion?.addEventListener("click", onAccordionClose);

      return () => {
        document.removeEventListener("scroll", onScroll, true);
        parentAccordion?.removeEventListener("click", onAccordionClose);
      };
    }
    return () => {};
  }, [setPlaying, setIsVisible, playing, videoRef]);
};

const useVisibilityEffect = (
  isVisible: boolean,
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  useEffect(() => {
    if (!isVisible) {
      setPlaying(false);
    }
  }, [isVisible, setPlaying]);
};
