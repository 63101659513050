import Link from "next/link";
import { CSSProperties } from "react";

import Heading from "@afa-shared/afa-components/dist/Heading";
import Icon from "@afa-shared/afa-components/dist/Icon";
import Text from "@afa-shared/afa-components/dist/Text";
import IvSmallTeaserImage from "@components/Medias/Image/IvSmallTeaserImage";

import { Context } from "types/context/Context";

import teaserListStyles from "./TeaserList.module.css";
import {
  TeaserListBlockType_teaserListBlock,
  TeaserListBlockType_teaserListBlock_rightTeaserListContentAreaCollection_items,
} from "./queries/contentfulTypes/TeaserListBlockType";

interface TeaserBlockData
  extends TeaserListBlockType_teaserListBlock_rightTeaserListContentAreaCollection_items {
  teaserBlockUrl: string;
}

interface ITeaserList {
  blockData: TeaserListBlockType_teaserListBlock;
  context: Context;
}

const TeaserList = ({ blockData: teaserList, context }: ITeaserList): JSX.Element => {
  const oneList = teaserList.rightHeading ? false : true;
  const borderClass = context?.sectionBlock?.coloredSection ? "" : teaserListStyles.border;
  const multiListClass = oneList ? teaserListStyles.oneList : teaserListStyles.twoLists;
  const columnDifference =
    teaserList?.leftTeaserListContentAreaCollection.items?.length -
    teaserList?.rightTeaserListContentAreaCollection.items?.length;
  const lastColumnStyle: CSSProperties =
    columnDifference !== 0 && oneList
      ? columnDifference < 0
        ? ({
            "--leftColumnSpan": `span ${Math.abs(columnDifference) + 1};`,
          } as CSSProperties)
        : ({
            "--rightColumnSpan": `span ${columnDifference + 1};`,
          } as CSSProperties)
      : {};

  const renderCard = (item: TeaserBlockData, showTeaserText: boolean) => {
    //Temp solution for nofollow rel on "intressant vetande"-teaser
    const tempNofollow = item?.sys?.id === "5L39saocxuRQdvNuNYuTXc" ? "nofollow" : "";
    return (
      <Link
        href={item?.teaserBlockUrl}
        passHref
        prefetch={false}
        className={teaserListStyles.link}
        rel={tempNofollow}
      >
        <div className={teaserListStyles.imageWrapper}>
          <IvSmallTeaserImage image={item?.ivImage?.[0]} />
        </div>
        <div className={teaserListStyles.labelWrapper}>
          <Heading
            variant={context?.heading?.childLevel}
            children={
              <>
                {item.heading}
                {item?.externalLink && !item?.externalLink?.includes("om-oss/pressrum") && (
                  <Icon
                    name="open_in_new"
                    size="sm"
                    fill="#0d0d0d"
                    aria-label="Extern länk"
                    className={teaserListStyles.externalLinkIcon}
                  />
                )}
              </>
            }
            className={teaserListStyles.label}
            removeMargin
          />
          {showTeaserText && <Text variant="paragraph" children={item?.text} removeMargin />}
        </div>
      </Link>
    );
  };

  return (
    <div
      className={`${teaserListStyles.teaserList} ${multiListClass}`}
      data-contentful-entry-id={teaserList?.sys?.id}
      data-contentful-field-id="name"
    >
      <div className={teaserListStyles.listWrapper}>
        <Heading
          variant={context?.heading?.parentLevel}
          removeMargin
          children={teaserList?.leftHeading}
        />
        <ul
          className={`${teaserListStyles.list} ${borderClass} ${multiListClass}`}
          style={lastColumnStyle}
        >
          {teaserList?.leftTeaserListContentAreaCollection.items?.map(
            (item: TeaserBlockData, index) => {
              const itemIndexClass =
                index === 0
                  ? teaserListStyles.firstItem
                  : index === teaserList?.leftTeaserListContentAreaCollection.items?.length - 1
                  ? teaserListStyles.lastItem
                  : "";
              const lastItemSpanClass =
                index === teaserList?.leftTeaserListContentAreaCollection.items?.length - 1 &&
                columnDifference < 0
                  ? teaserListStyles.lastItemLeftFix
                  : "";
              return (
                <li
                  className={`${teaserListStyles.listItem} ${
                    oneList ? teaserListStyles.columnOne : ""
                  } ${itemIndexClass} ${lastItemSpanClass}`}
                  key={index}
                >
                  {renderCard(item, teaserList?.leftShowTeaserText)}
                </li>
              );
            }
          )}
          {oneList &&
            teaserList?.rightTeaserListContentAreaCollection.items?.map(
              (item: TeaserBlockData, index) => {
                const itemIndexClass =
                  index === 0
                    ? teaserListStyles.firstItem
                    : index === teaserList?.rightTeaserListContentAreaCollection.items?.length - 1
                    ? teaserListStyles.lastItem
                    : "";
                const lastItemSpanClass =
                  index === teaserList?.rightTeaserListContentAreaCollection.items?.length - 1 &&
                  columnDifference > 0
                    ? teaserListStyles.lastItemRightFix
                    : "";
                return (
                  <li
                    className={`${teaserListStyles.listItem} ${teaserListStyles.columnTwo} ${itemIndexClass} ${lastItemSpanClass}`}
                    key={index}
                  >
                    {renderCard(item, teaserList?.rightShowTeaserText)}
                  </li>
                );
              }
            )}
        </ul>
      </div>

      {!oneList && (
        <div className={teaserListStyles.listWrapper}>
          <Heading
            variant={context?.heading?.parentLevel}
            removeMargin
            children={teaserList?.rightHeading}
          />
          <ul className={`${teaserListStyles.list} ${borderClass} ${multiListClass}`}>
            {teaserList?.rightTeaserListContentAreaCollection.items?.map(
              (item: TeaserBlockData, index) => {
                return (
                  <li className={teaserListStyles.listItem} key={index}>
                    {renderCard(item, teaserList?.rightShowTeaserText)}
                  </li>
                );
              }
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TeaserList;
