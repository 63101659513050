import { useId } from "react";

import Heading from "@afa-shared/afa-components/dist/Heading";
import RadioButton from "@afa-shared/afa-components/dist/Inputs/RadioButton";
import Select from "@afa-shared/afa-components/dist/Inputs/Select";
import Text from "@afa-shared/afa-components/dist/Text";
import AfaTooltip from "@components/AfaTooltip/Index";
import TrackedLink from "@components/TrackedLink";
import { sectorTracker } from "@components/sectorGuide/sectorTracker";
import { LinkType } from "@utils/linkIconHelper";
import targetGroupOptions from "@utils/targetGroup/targetGroupOptions.json";

import { InsurancePickerBlockType_insurancePickerBlock } from "../InsurancePickerBlock/queries/contentfulTypes/InsurancePickerBlockType";
import insurancePickerFormStyles from "./insurancePickerForm.module.css";

const InsurancePickerForm = ({
  onRoleChange,
  onSectorChange,
  userSector,
  defaultRole,
  blockData,
}): JSX.Element => {
  const insurancePicker = blockData as InsurancePickerBlockType_insurancePickerBlock;
  const helpLinkUrl = blockData.helpLinkUrl;
  const sectorOptions = [];
  targetGroupOptions?.sectors?.map((sector) => {
    sectorOptions.push({
      name: sector?.sectorGuideModal,
      value: sector?.sectorId,
    });
  });
  const sectorSelectId = useId();
  const roleFieldsetId = useId();

  const trackLinkClick = () => {
    sectorTracker(
      userSector?.sector?.defaultName ? userSector?.sector?.defaultName : "",
      userSector?.role?.defaultName ? userSector?.role?.defaultName : "",
      "förklara mer",
      "målgruppsväljare_brödtext"
    );
  };

  const roleTooltip = (
    <div className={insurancePickerFormStyles.tooltipText}>
      <Text variant="paragraph">
        Välj <span className={insurancePickerFormStyles.bold}>privatperson</span> om du har eller
        har haft en anställning eller är egenföretagare. Även du som är anhörig väljer detta
        alternativ.
      </Text>
      <Text variant="paragraph">
        Välj{" "}
        <span className={insurancePickerFormStyles.bold}>
          arbetsgivare eller facklig representant
        </span>{" "}
        när du i ditt jobb, i ditt fackliga uppdrag eller som försäkringsinformatör vill uträtta ett
        ärende eller söka information åt dig själv eller någon annan.
      </Text>
    </div>
  );

  const sectorTooltip = (
    <div className={insurancePickerFormStyles.tooltipText}>
      <Text variant="paragraph">
        Som <span className={insurancePickerFormStyles.bold}>arbetare</span> jobbar du exempelvis på
        lager, som städare eller personlig assistent, i butik, på bygge eller kör lastbil. Andra
        exempel är inom restaurang eller hotell.
      </Text>
      <Text variant="paragraph">
        Som <span className={insurancePickerFormStyles.bold}>tjänsteman</span> jobbar du ofta på
        kontor, kanske med ekonomi eller IT. Ibland som chef eller arbetsledare.
      </Text>
    </div>
  );

  return (
    <div className={insurancePickerFormStyles.insurancePickerForm}>
      <div className={insurancePickerFormStyles.topSection}>
        <div className={insurancePickerFormStyles.textSection}>
          {insurancePicker.heading && (
            <Heading variant={"h2"} children={insurancePicker.heading} removeMargin />
          )}

          {insurancePicker.text && (
            <Text
              variant="span"
              children={insurancePicker.text}
              className={insurancePickerFormStyles.text}
            />
          )}
        </div>
        {helpLinkUrl && insurancePicker.helpLinkText && (
          <TrackedLink
            url={helpLinkUrl}
            linkText={insurancePicker.helpLinkText}
            linkType={LinkType.Internal}
            onClick={() => trackLinkClick()}
          />
        )}
      </div>
      <div className={insurancePickerFormStyles.formSection}>
        <div className={insurancePickerFormStyles.formField}>
          {insurancePicker.sectorHeading && (
            <div className={insurancePickerFormStyles.fieldHeadingWrapper}>
              {/* 
                useOverlayFullHeight={true}
             */}
              <label
                id="tooltip-heading-sector"
                htmlFor={sectorSelectId}
                className={insurancePickerFormStyles.fieldLabel}
              >
                {insurancePicker.sectorHeading}
              </label>
              <AfaTooltip
                label="Hjälp"
                showLabel={true}
                iconAriaLabel="Hjälp"
                tooltipContent={sectorTooltip}
                labelSize={"normal"}
                tooltipPositionX="right"
                tooltipPositionY="up"
                ariaLabelledBy="tooltip-heading-sector"
              />
            </div>
          )}

          <div className={insurancePickerFormStyles.selectWrapper}>
            <Select
              options={sectorOptions}
              id={sectorSelectId}
              placeholder={"Välj"}
              onChange={onSectorChange}
              selected={userSector?.sector?.sectorId || ""}
              width={"100%"}
              error={null}
              dataTestIdSelect={"insurancepicker-select"}
            />
          </div>
        </div>
        <div className={insurancePickerFormStyles.formField}>
          <fieldset className={insurancePickerFormStyles.fieldSet}>
            {insurancePicker?.roleHeading && (
              <div className={insurancePickerFormStyles.fieldHeadingWrapper}>
                <legend id="tooltip-heading-role" className={insurancePickerFormStyles.fieldLabel}>
                  {insurancePicker.roleHeading}
                </legend>
                <AfaTooltip
                  label="Hjälp"
                  showLabel={true}
                  iconAriaLabel="Hjälp"
                  tooltipContent={roleTooltip}
                  labelSize={"normal"}
                  tooltipPositionX="right"
                  tooltipPositionY="up"
                  ariaLabelledBy="tooltip-heading-role"
                />
              </div>
            )}

            <div className={insurancePickerFormStyles.radioWrapper} id={roleFieldsetId}>
              <div className={insurancePickerFormStyles.radioFocusHelper}>
                {targetGroupOptions?.roles?.map((radioButton, key) => (
                  <RadioButton
                    checked={
                      radioButton?.roleId ===
                      (userSector?.role?.roleId && userSector?.sector
                        ? userSector?.role?.roleId
                        : defaultRole?.roleId)
                    }
                    label={radioButton?.insuranceForm}
                    onChange={onRoleChange}
                    onClick={() => onRoleChange}
                    value={radioButton?.roleId}
                    key={key}
                    className={insurancePickerFormStyles.radioButton}
                    dataTestId={"insurancepicker-radiobutton-" + radioButton?.roleId}
                  />
                ))}
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default InsurancePickerForm;
