import * as ga from "../../../lib/ga";

export const insuransPickerTracker = (insuranstype: string, action: string) => {
  const trackingData = {
    event: "försäkringssida_välj_försäkring",
    event_parameters: {
      page_location: window.location.href,
      försäkring: insuranstype?.toLowerCase(),
      åtgärd: action?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};

export const insuransSelectTracker = (insuranstype: string, action: string) => {
  const trackingData = {
    event: "försäkringssida_gå_vidare_till_anmälan",
    event_parameters: {
      page_location: window.location.href,
      försäkring: insuranstype?.toLowerCase(),
      åtgärd: action?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};

export const insuransSigningTracker = (insuranstype: string, action: string) => {
  const trackingData = {
    event: "anmäl_försäkring",
    event_parameters: {
      page_location: window.location.href,
      försäkring: insuranstype?.toLowerCase(),
      åtgärd: action?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};
