import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import ImageBlock from "@components/ImageBlock";
import {
  ImageBlockType_imageBlock,
  ImageBlockType_imageBlock_link,
} from "@components/ImageBlock/queries/contentfulTypes/ImageBlockType";
import TableBlock from "@components/TableBlock";
import { TableBlockType_tableBlock } from "@components/TableBlock/queries/contentfulTypes/TableBlockType";
import TrackedLink from "@components/TrackedLink";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { LinkType, getInternalOrExternalIcon } from "@utils/linkIconHelper";
import { getFileSize, isNullOrEmpty } from "@utils/string";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

import richTextRendererStyles from "./richTextRenderer.module.css";

interface IRichTextRendererProps {
  json: any;
  links?: any;
  pageTextArea?: boolean;
}
interface linkProps {
  url: string;
  name: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function richTextOptions(links: any) {
  const entryMap = new Map();
  const linkMap = new Map();

  if (links?.entries) {
    if (links?.entries?.block) {
      for (const entry of links?.entries?.block) {
        entryMap.set(entry?.sys?.id, entry);
      }
    }
    if (links?.entries?.hyperlink) {
      for (const link of links?.entries?.hyperlink) {
        linkMap.set(link?.sys?.id, link);
      }
    }
  }

  return {
    renderMark: {
      [MARKS.CODE]: (text) => <Text children={text} variant={"paragraph"} />,
    },
    renderText: (text) => {
      const sanitizedText = text.replace(/\u2028/g, " "); //Replace LINE SEPERATOR (shift enter, soft break) character with normal whitespace
      return sanitizedText.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text variant="paragraph" children={children} />,
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading
          className={`${richTextRendererStyles.extendedHeading} ${richTextRendererStyles.h2}`}
          variant="h2"
          maxWidth={782}
          children={children}
        />
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading
          className={`${richTextRendererStyles.extendedHeading} ${richTextRendererStyles.h3}`}
          variant="h3"
          maxWidth={782}
          children={children}
        />
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading
          className={`${richTextRendererStyles.extendedHeading} ${richTextRendererStyles.h4}`}
          variant="h4"
          maxWidth={782}
          children={children}
        />
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Heading
          className={`${richTextRendererStyles.extendedHeading} ${richTextRendererStyles.h4}`}
          variant="h5"
          maxWidth={782}
          children={children}
        />
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Heading
          className={`${richTextRendererStyles.extendedHeading} ${richTextRendererStyles.h4}`}
          variant="h6"
          maxWidth={782}
          children={children}
        />
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className={richTextRendererStyles.extendedUl} children={children} />
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className={richTextRendererStyles.extendedOl} children={children} />
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className={richTextRendererStyles.extendedLi} children={children} />
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <Text className={richTextRendererStyles.extendedBlockQuote} variant="quote">
          {children}
        </Text>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = entryMap.get(node?.data?.target?.sys?.id);
        if (entry?.__typename === "TableBlock") {
          const tableData: TableBlockType_tableBlock = {
            __typename: "TableBlock",
            name: "TableBlock i brödtext",
            table: entry?.table,
            heading: entry?.heading,
            audienceCollection: null,
            rightAlignedColumns: entry?.rightAlignedColumns || null,
            sys: entry?.sys,
          };

          return (
            <TableBlock
              className={richTextRendererStyles.extendedTableBlock}
              blockData={tableData}
            />
          );
        }
        if (entry?.__typename === "ImageBlock") {
          const linkData: ImageBlockType_imageBlock_link = {
            __typename: "Link",
            globalLinkItem: entry?.link?.globalLinkItem,
            reference: entry?.link?.reference,
            url: entry?.link?.url,
            newTab: entry?.link?.newTab,
          };

          const imageData: ImageBlockType_imageBlock = {
            __typename: "ImageBlock",
            name: "ImageBlock i brödtext",
            ivImage: entry?.ivImage,
            imageDescription: entry?.imageDescription,
            link: linkData,
            enableZoom: entry?.enableZoom,
            audienceCollection: undefined,
            shareImage: false,
            squareImage: null,
            shareHeading: "",
            shareLinkText: "",
            shareRichtext: "",
            sys: entry?.sys?.id,
          };
          return (
            <ImageBlock
              className={richTextRendererStyles.extendedImageBlock}
              blockData={imageData}
            />
          );
        }
        return null;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const linkType = getInternalOrExternalIcon(node?.data?.uri, null, null);

        return (
          <TrackedLink
            className={`${richTextRendererStyles.extendedLink}`}
            linkType={linkType}
            linkText={children}
            url={node?.data?.uri}
            linkVariant={"link"}
            hideIcon={linkType !== LinkType.External}
          />
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const link = linkMap?.get(node?.data?.target.sys.id);

        if (link?.__typename === "FileBlock") {
          const file = link?.imageVaultFile && link?.imageVaultFile[0];
          const fileExtension = file?.fileExtension?.toUpperCase().slice(0, 3);
          const fileSize = getFileSize(file?.fileSize);

          const fileData =
            fileExtension && fileSize
              ? ` (${fileExtension}, ${fileSize})`
              : fileExtension
              ? ` (${fileExtension})`
              : fileSize
              ? ` (${fileSize})`
              : "";
          const linkText = children[0]?.props?.children
            ? children[0]?.props?.children
            : children[0]?.filter((x) => x) + fileData;

          return (
            file && (
              <TrackedLink
                className={`${richTextRendererStyles.extendedLink}`}
                url={replaceImageVaultBasePath(file?.mediaUrl)}
                linkText={linkText}
                hideIcon={true}
                linkVariant={"link"}
                target={"_self"}
              />
            )
          );
        } else {
          const linkData: linkProps = {
            url: link?.url,
            name: children || link?.url,
          };
          return linkData?.url ? (
            <TrackedLink
              className={`${richTextRendererStyles.extendedLink}`}
              linkText={linkData?.name}
              url={linkData?.url}
              linkVariant={"link"}
              hideIcon={true}
              target={"_self"}
            />
          ) : (
            <span>{linkData?.name}</span>
          );
        }
      },
    },
  };
}

const RichTextRenderer = ({ json, links, pageTextArea }: IRichTextRendererProps): JSX.Element => {
  let trimmedJson = json;

  /* Removes empty lines from bottom of rich text area. If empty, nothing is rendered */
  const trimLastLine = (jsonToTrim) => {
    const trimmedContent = jsonToTrim.content.slice(0, -1);
    jsonToTrim = { ...jsonToTrim, content: trimmedContent };
    const length = jsonToTrim.content.length;
    const lastElement = jsonToTrim?.content[length - 1]?.content[0]?.value;
    const contentLength = jsonToTrim?.content[length - 1]?.content?.length;
    const nodeType = jsonToTrim?.content[length - 1]?.content[0]?.nodeType;
    if (isNullOrEmpty(lastElement?.trim()) && contentLength === 1 && nodeType === "text") {
      return trimLastLine(jsonToTrim);
    }
    return jsonToTrim;
  };

  const length = json?.content?.length;
  const lastElement = json?.content[length - 1]?.content[0]?.value;
  const contentLength = json?.content[length - 1]?.content?.length;
  if (isNullOrEmpty(lastElement?.trim()) && contentLength === 1) {
    trimmedJson = trimLastLine(trimmedJson);
  }

  return trimmedJson?.content?.length > 0 ? (
    <div
      className={richTextRendererStyles.richTextRenderer}
      data-blockname={pageTextArea && "brödtext"}
    >
      {documentToReactComponents(trimmedJson, richTextOptions(links))}
    </div>
  ) : (
    <></>
  );
};

export default RichTextRenderer;
