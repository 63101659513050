import Cookies from "universal-cookie";
import create from "zustand";
import { devtools } from "zustand/middleware";

export type ISectorToggler = {
  selectedSector: any;
  isLoading: boolean;
  setSelectedSector: (selectedSector: any) => void;
};
export const useSectorToggler = create<ISectorToggler>(
  devtools(
    (set) => ({
      selectedSector: null,
      isLoading: true,
      setSelectedSector: (selectedSector: any) =>
        set((state: ISectorToggler) => ({ selectedSector, isLoading: false })),
    }),
    { name: "SECTOR_TOGGLER" }
  )
);
