import Link from "next/link";
import { RefObject, useRef } from "react";

import StandardLink from "@afa-shared/afa-components/dist/StandardLink";

import { useExternalTracking } from "hooks/useExternalTracking";

enum LinkType {
  External = "open_in_new",
  Internal = "arrow_forward",
  File = "file_download",
}

interface iTrackedLink {
  linkText: string;
  url: string;
  linkVariant?: "link" | "button";
  linkType?: LinkType;
  target?: string;
  onClick?: (value?: any) => void;
  hideIcon?: boolean;
  buttonStyle?: "primary" | "secondary";
  color?: string;
  ariaLabel?: string;
  linkRef?: RefObject<HTMLAnchorElement>;
  prefetch?: boolean;
  dataTestId?: string;
  className?: string;
  dataContentfulEntryId?: string;
  dataContentfulFieldId?: string;
}

const TrackedLink = ({
  url,
  linkText,
  linkVariant = "link",
  linkType = LinkType.Internal,
  target = "_self",
  onClick,
  hideIcon,
  buttonStyle,
  color,
  ariaLabel,
  linkRef,
  prefetch = false,
  dataTestId,
  className,
  dataContentfulEntryId,
  dataContentfulFieldId,
}: iTrackedLink): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const thisLinkRef = linkRef ? linkRef : useRef(null);
  useExternalTracking({ url: url, ref: thisLinkRef });

  const standardLink = (
    <StandardLink
      linkType={linkType}
      linkText={linkText}
      linkUrl={url}
      variant={linkVariant}
      target={target}
      buttonStyle={buttonStyle}
      color={color}
      className={className}
      hideIcon={hideIcon}
      onClick={onClick}
      linkMetaTitle={ariaLabel}
      ref={thisLinkRef}
      dataTestId={dataTestId}
      data-contentful-entry-id={dataContentfulEntryId}
      data-contentful-field-id={dataContentfulFieldId}
    />
  );

  if (linkType === LinkType.External) {
    return standardLink;
  }

  return (
    <Link href={url} prefetch={prefetch} legacyBehavior>
      {standardLink}
    </Link>
  );
};
export default TrackedLink;
