import { CSSProperties } from "react";

import { defaultImage } from "@utils/imageHelpers/imageHelper";
import { getImageMetaData } from "@utils/imageHelpers/metadataImageHelper";
import { getTeaserImage } from "@utils/imageHelpers/teaserImageHelper";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

import AfaImage from "./AfaImage";
import { IImageSrc } from "./ImageSources";

interface IImage {
  image: any;
  pressImageUrl?: string;
  pressImageAltText?: string;
  style?: CSSProperties;
  className?: string;
}

const IvTeaserImage = ({
  image,
  pressImageUrl,
  pressImageAltText,
  className,
}: IImage): JSX.Element => {
  if (pressImageUrl) {
    return (
      <AfaImage
        fallbackUrl={pressImageUrl}
        alt={pressImageAltText || defaultImage?.imageAltText}
        imageSrcSet={[{ url: pressImageUrl }]}
        className={className}
      />
    );
  }

  const metadata = getImageMetaData(image);

  if (image?.mediaUrl.endsWith(".svg")) {
    return (
      <AfaImage
        fallbackUrl={replaceImageVaultBasePath(image?.mediaUrl) || defaultImage?.url}
        alt={metadata?.altText || defaultImage?.imageAltText}
        imageSrcSet={[{ url: replaceImageVaultBasePath(image?.mediaUrl) }]}
        className={className}
      />
    );
  }

  const srcSet: IImageSrc[] = [
    {
      url: replaceImageVaultBasePath(getTeaserImage(image, 767)?.url),
      media: "(max-width: 768px)",
    },
    { url: replaceImageVaultBasePath(getTeaserImage(image, 768)?.url) },
  ];

  return (
    <AfaImage
      fallbackUrl={replaceImageVaultBasePath(getTeaserImage(image, 374)?.url) || defaultImage?.url}
      alt={metadata?.altText || defaultImage?.imageAltText}
      imageSrcSet={srcSet}
      className={className}
    />
  );
};

export default IvTeaserImage;
