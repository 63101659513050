import { useRef } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import PaginationBlock from "@components/Pagination";
import RichTextRenderer from "@components/RichTextRenderer";
import TrackedLink from "@components/TrackedLink";
import { LinkType } from "@utils/linkIconHelper";

import { FileBlockDataType } from "types/FileBlockDataType";
import { Context } from "types/context/Context";
import { HeadingContext } from "types/context/HeadingContext";

import { FileListBlockType_fileListBlock } from "../FileListBlock/queries/contentfulTypes/FileListBlockType";
import fileListStyles from "./fileList.module.css";
import FilesInList from "./filesInList";
import { FileListResultType_fileBlockCollection_items } from "./queries/contentfulTypes/FileListResultType";

export interface ExtendedFileListResultType extends FileListResultType_fileBlockCollection_items {
  fileData: FileBlockDataType;
}
export type FilesPaginationContainer = {
  listResult: Array<ExtendedFileListResultType>;
  totalItems: number;
};
export interface FileListBlockResult extends FileListBlockType_fileListBlock {
  listresult: FilesPaginationContainer;
}
interface FileListProps {
  fileListBlock: FileListBlockResult;
  uniqueKey: string;
  context?: Context;
}

export const FileList = ({ fileListBlock, uniqueKey, context }: FileListProps) => {
  const listRef = useRef(null);
  const numberOfResultItems = fileListBlock?.numberOfItems || 999;
  const listItems = fileListBlock?.listresult?.listResult;
  const totalItems = fileListBlock?.listresult?.totalItems;

  const linkOnFileList = fileListBlock?.linkOnFileList;
  const url = linkOnFileList?.url;

  const headingContext: HeadingContext = {
    parentLevel: context?.heading?.parentLevel,
    childLevel: fileListBlock?.heading
      ? context?.heading?.childLevel
      : context?.heading?.parentLevel,
  };
  context = { ...context, heading: headingContext };

  const fileListText = () => (
    <>
      {fileListBlock?.heading && (
        <Heading variant={context?.heading?.parentLevel} removeMargin>
          {fileListBlock?.heading}
        </Heading>
      )}

      {fileListBlock?.text && (
        <RichTextRenderer json={fileListBlock?.text?.json} links={fileListBlock?.text?.links} />
      )}

      {fileListBlock?.showTotalAmount && totalItems > 0 && (
        <Text
          variant={"paragraph"}
          className={fileListStyles.text}
          children={`${totalItems} filer`}
        />
      )}
    </>
  );

  return (
    listItems?.length > 0 && (
      <div
        className={fileListStyles.fileList}
        key={uniqueKey}
        data-contentful-entry-id={fileListBlock?.sys?.id}
        data-contentful-field-id="name"
      >
        {fileListBlock?.heading &&
        (fileListBlock?.text || (fileListBlock?.showTotalAmount && totalItems > 0)) ? (
          <div className={fileListStyles.textWrapper}>{fileListText()}</div>
        ) : (
          fileListText()
        )}

        <ul className={`${fileListStyles.grid}`} ref={listRef} tabIndex={-1} data-temp-keep-index>
          {listItems?.map((item: ExtendedFileListResultType, index: number) => (
            <li
              key={index}
              data-contentful-entry-id={item?.sys?.id}
              data-contentful-field-id="name"
            >
              <FilesInList
                item={item}
                fileListBlock={fileListBlock}
                index={index}
                context={context}
              />
            </li>
          ))}
        </ul>

        {fileListBlock?.pagination &&
          listItems &&
          totalItems > 0 &&
          numberOfResultItems < totalItems && (
            <PaginationBlock
              totalItems={totalItems}
              itemsPerPage={numberOfResultItems}
              navbarLabel={"Paginering för fillista"}
              listRef={listRef}
            />
          )}

        {linkOnFileList && listItems && (
          <Grid direction="row" justifyContent="center">
            <TrackedLink
              url={url}
              linkType={LinkType.Internal}
              linkText={linkOnFileList?.linkText}
              linkVariant="button"
              buttonStyle="secondary"
            />
          </Grid>
        )}
      </div>
    )
  );
};
export default FileList;
