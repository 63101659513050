import Link from "next/link";
import { ReactNode } from "react";

import Icon from "@afa-shared/afa-components/dist/Icon";

import { useWindowSize } from "hooks/useViewport";

import paginationStyles from "./Pagination.module.css";

export const GetDotedPagination = (
  totalPaginationPages: number,
  currentPaginationPage: number,
  url: string,
  queryString: string,
  onItemClick: () => void
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ReactNode[] => {
  const windowSize = useWindowSize();
  let paginationRange = getPaginationRange(totalPaginationPages, currentPaginationPage);

  if (windowSize.isLargeMobile) {
    paginationRange = getPaginationRange(totalPaginationPages, currentPaginationPage, 1);
  } else if (windowSize.isTabletOnly || windowSize.isDesktopUp) {
    paginationRange = getPaginationRange(totalPaginationPages, currentPaginationPage, 2);
  }
  // if (windowSize.isTabletOnly) {
  //   paginationRange = getPaginationRange(totalPaginationPages, currentPaginationPage, 2);
  // } else if (windowSize.isDesktop) {
  //   paginationRange = getPaginationRange(totalPaginationPages, currentPaginationPage, 2);
  // } else if (windowSize.isLargeDesktop) {
  //   paginationRange = getPaginationRange(totalPaginationPages, currentPaginationPage, 2);
  // }
  const items = [] as Array<ReactNode>;
  let beforeUrl =
    url.indexOf("sida-") > 0
      ? `${url.substring(0, url.indexOf("sida-"))}sida-${currentPaginationPage - 1}`
      : `${url}/sida-${1}`;
  const afterUrl =
    url.indexOf("sida-") > 0
      ? `${url.substring(0, url.indexOf("sida-"))}sida-${currentPaginationPage + 1}`
      : `${url}/sida-${2}`;
  if (currentPaginationPage === 2) beforeUrl = url.substring(0, url.indexOf("sida-"));
  items.push(
    currentPaginationPage > 1 ? (
      <Link
        key={"prev"}
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        href={`${beforeUrl}${queryString ? `?${queryString}` : ""}`}
        prefetch={false}
        aria-label={`Gå till sida ${currentPaginationPage - 1}`}
        onClick={onItemClick}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}

        <Icon name="chevron_left" size="sm" className={paginationStyles.chevron} />
      </Link>
    ) : (
      <span key={"prev"} className={paginationStyles.navHelper}>
        {/* <Icon name="chevron_left" size="sm" fill="gray" className={paginationStyles.chevron} /> */}
      </span>
    )
  );

  {
    paginationRange?.map((pageNumber) => {
      // If the pageItem is a DOT, render the DOTS unicode character
      if (pageNumber === "DOTS") {
        items.push(<span className={paginationStyles.navHelper}>&#8230;</span>);
      } else {
        let currentUrl =
          url.indexOf("sida-") > 0
            ? `${url.substring(0, url.indexOf("sida-"))}sida-${pageNumber}`
            : `${url}/sida-${pageNumber}`;
        if (pageNumber === 1)
          currentUrl = url.indexOf("sida-") > 0 ? url.substring(0, url.indexOf("sida-")) : url;
        items.push(
          <Link
            key={pageNumber}
            // eslint-disable-next-line sonarjs/no-nested-template-literals
            href={`${currentUrl}${queryString ? `?${queryString}` : ""}`}
            prefetch={false}
            className={pageNumber === currentPaginationPage ? "active" : ""}
            aria-label={
              pageNumber === currentPaginationPage
                ? `Sida ${pageNumber}, nuvarande sida`
                : `Gå till sida ${pageNumber}`
            }
            onClick={onItemClick}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}

            {pageNumber}
          </Link>
        );
        currentUrl = "";
      }
    });
  }

  items.push(
    currentPaginationPage < totalPaginationPages ? (
      <Link
        key={"next"}
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        href={`${afterUrl}${queryString ? `?${queryString}` : ""}`}
        prefetch={false}
        aria-label={`Gå till nästa sida, sida ${currentPaginationPage + 1}`}
        onClick={onItemClick}
      >
        <Icon name="chevron_right" size="sm" className={paginationStyles.chevron} />
      </Link>
    ) : (
      <span key={"next"} className={paginationStyles.navHelper}>
        {/* <Icon name="chevron_right" size="sm" fill="gray" className={paginationStyles.chevron} /> */}
      </span>
    )
  );

  return items;
};

const getPaginationRange = (totalPageCount, currentPage, siblingCount = 0) => {
  // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
  const totalPageNumbers = siblingCount + 5;

  /* Case 1: If the number of pages is less than the page numbers we want to show in our 
  paginationComponent, we return the range [1..totalPageCount] */
  if (totalPageNumbers >= totalPageCount) {
    return [...Array(totalPageCount)].map((_, i) => 1 + i);
  }

  /* Calculate left and right sibling index and make sure they are within range 1 and totalPageCount */
  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

  /* We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount.
  Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2 */
  const shouldShowLeftDots = leftSiblingIndex >= 2;
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

  const firstPageIndex = [1];
  const lastPageIndex = [totalPageCount];

  /* Case 2: No left dots to show, but rights dots to be shown */
  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftItemCount = 2 + 2 * siblingCount;
    const leftRange = [...Array(leftItemCount + 1)].map((_, i) => 1 + i);

    return [...leftRange, "DOTS", ...lastPageIndex];
  }

  /* Case 3: No right dots to show, but left dots to be shown */
  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightItemCount = 3 + 2 * siblingCount;
    const rightRange = [...Array(rightItemCount)].map(
      (_, i) => totalPageCount - rightItemCount + 1 + i
    );
    return [...firstPageIndex, "DOTS", ...rightRange];
  }

  /* Case 4: Both left and right dots to be shown */
  if (shouldShowLeftDots && shouldShowRightDots) {
    const middleRange = [...Array(rightSiblingIndex - leftSiblingIndex + 1)].map(
      (_, i) => leftSiblingIndex + i
    );
    return [...firstPageIndex, "DOTS", ...middleRange, "DOTS", ...lastPageIndex];
  }

  return [];
};
