import { ChangeEvent, RefObject } from "react";

import Icon from "@afa-shared/afa-components/dist/Icon";

import selectStyles from "./Select.module.css";

export type SelectOptions = {
  value: string;
  name: string;
};

export interface ISelectProps {
  labelText?: string;
  options: Array<SelectOptions>;
  placeholder?: string;
  id: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  selected?: any;
  width?: string;
  selectFieldRef?: RefObject<any>;
  [htmlAttributes: string]: any;
}

const Select = ({
  labelText,
  options,
  placeholder,
  id,
  onChange,
  selected,
  width,
  selectFieldRef,
  ...htmlAttributes
}: ISelectProps): JSX.Element => {
  const currentValue = selected || "Välj";
  return (
    <div className={selectStyles.select} style={{ width: width }}>
      <label htmlFor={id}>{labelText}</label>
      <div className={selectStyles.container}>
        <select
          className={selectStyles.inputSelect}
          id={id}
          value={currentValue}
          onChange={onChange}
          data-testid={"sector-popup-select"}
          {...htmlAttributes}
          ref={selectFieldRef}
        >
          <option hidden>{placeholder}</option>
          {options &&
            options.map((option, key) => (
              <option className={selectStyles.option} key={key} value={option.value}>
                {option.name}
              </option>
            ))}
        </select>
        <div className={selectStyles.arrow}>
          <Icon name="chevron_down" size="sm" />
        </div>
      </div>
    </div>
  );
};
export default Select;
