/* eslint sonarjs/no-duplicate-string: "off" */
import { useRouter } from "next/dist/client/router";
import { useEffect, useRef, useState } from "react";

import ToggleButton from "@afa-shared/afa-components/dist/Buttons/ToggleButton";
import Navbar from "@afa-shared/afa-components/dist/Navbar";
import { sectorTracker, sectorViewTracker } from "@components/sectorGuide/sectorTracker";
import { setSectorGuideClosed, setSectorGuideOpen } from "@utils/globalClassHelper";
import { getBrandMenu, getCampaignMenu, getDesktopMenu, getMobileMenu } from "@utils/menuHelper";
import {
  getRoleById,
  getSectorById,
  getSectorGuideButtonTargetGroupName,
} from "@utils/targetGroup/targetGroupHelper";

import useMediaQuery from "hooks/useMediaQuery";
import useParamsToggler from "hooks/useParamToggler";
import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { MenuItem } from "types/MenuItem";
import { MobileMenuShortcut } from "types/MobileMenuShortcut";

import SectorGuide from "../sectorGuide";
import RenderMenu from "./RenderMenu";
import navigationStyles from "./navigation.module.css";

interface INavigationProps {
  toggleMenu?: boolean;
  setToggleMenu?: (v?: any) => void;
  mobileMenuShortcuts: Array<MobileMenuShortcut>;
  menuItems: string;
  mobileMenuId?: string;
  is404: boolean;
}

const Navigation = ({
  toggleMenu,
  setToggleMenu,
  mobileMenuShortcuts,
  menuItems,
  mobileMenuId,
  is404,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
INavigationProps) => {
  const { isOpen, removeQueryParameter, appendQueryParameter } = useParamsToggler();

  const isLoading = useSectorToggler((state: ISectorToggler) => state.isLoading);
  const sectorToggleRef = useRef(null);
  const isTablet = useMediaQuery("tablet");
  const [navigationReady, setNavigationReady] = useState(false);

  const selectedSector = useSectorToggler((state: ISectorToggler) => state?.selectedSector);
  const router = useRouter();

  let path = router?.asPath?.replace(/\?.+/, "");

  let slug =
    router?.query?.slug && router?.query?.slug[0] !== "500" ? router?.query?.slug[0] : "start";

  if (path?.startsWith("/forsakringshandboken")) {
    slug = "forsakring";
    path = "/forsakring/utbildning-och-material/forsakringshandboken-redirect";
  }
  if (path?.startsWith("/om-oss/nyheter/")) {
    slug = "om-oss";
    path = "/om-oss/nyheter";
  }
  if (path?.startsWith("/om-oss/pressrum/")) {
    slug = "om-oss";
    path = "/om-oss/pressrum";
  }
  if (path?.startsWith("/om-oss/evenemang/")) {
    slug = "om-oss";
    path = "/om-oss/evenemang";
  }
  if (
    path?.startsWith("/ohalsa-och-arbetsskador/analys-och-statistik/aktuella-statistikrapporter/")
  ) {
    slug = "ohalsa-och-arbetsskador";
    path = "/ohalsa-och-arbetsskador/analys-och-statistik";
  }
  if (path?.startsWith("/kundtjanst/fragor-och-svar/")) {
    slug = "kundtjanst";
    path = "/kundtjanst/fragor-och-svar";
  }
  if (path?.startsWith("/anslutna-utbildningsanordnare")) {
    slug = "ohalsa-och-arbetsskador";
    path = "/ohalsa-och-arbetsskador/stod-for-arbetsmiljoutbildning";
  }
  if (path?.startsWith("/varumarke") && !isTablet) {
    slug = "varumarke";
  }
  if (path?.startsWith("/intressant-vetande")) {
    slug = "intressant-vetande";
  }
  if (path?.startsWith("/ingen-missad-ersattning")) {
    slug = "ingen-missad-ersattning";
  }
  if (path?.startsWith("/tank-pa-oss")) {
    slug = "tank-pa-oss";
  }
  if (path?.startsWith("/filer")) {
    slug = "kundtjanst";
    path = "/kundtjanst/sok-filer-och-blanketter";
  }

  const menuItemCollection = JSON.parse(menuItems);
  const mobileMenuItemCollection = JSON.parse(menuItems);
  const brandmenuItemCollection = JSON.parse(menuItems);
  const campaignMenuItemCollection = JSON.parse(menuItems);

  const hideDesktopNavigation =
    slug === "start" ||
    slug === "500" ||
    is404 ||
    router?.asPath?.startsWith("/om-webbplatsen") ||
    router?.asPath?.startsWith("/om-betasajten");

  const hideMobileMenu =
    ((slug === "start" && !path?.startsWith("/varumarke")) ||
      (slug === "start" && !path?.startsWith("/intressant-vetande")) ||
      (slug === "start" && !path?.startsWith("/ingen-missad-ersattning")) ||
      (slug === "start" && !path?.startsWith("/tank-pa-oss")) ||
      slug === "om-webbplatsen" ||
      slug === "om-betasajten") &&
    !toggleMenu;

  const mobileMenu: MenuItem[] = getMobileMenu(
    mobileMenuItemCollection,
    path,
    selectedSector,
    isTablet
  );
  const desktopMenu: MenuItem[] = getDesktopMenu(
    menuItemCollection,
    slug,
    path,
    selectedSector,
    isTablet
  );
  const brandMenu: MenuItem[] = getBrandMenu(
    brandmenuItemCollection,
    path,
    selectedSector,
    isTablet
  );
  const campaignMenu: MenuItem[] = getCampaignMenu(
    campaignMenuItemCollection,
    slug,
    path,
    null,
    isTablet
  );
  const displayName = getSectorGuideButtonTargetGroupName(
    selectedSector?.role?.roleId,
    selectedSector?.sector?.sectorId
  );
  const isForsakringPages = slug === "forsakring" || router?.asPath.startsWith("/forsakring/");

  const isBrandPortal = router?.asPath.startsWith("/varumarke") && !is404;
  const isCampaignPage =
    (router?.asPath.startsWith("/intressant-vetande") ||
      router?.asPath.startsWith("/ingen-missad-ersattning") ||
      router?.asPath.startsWith("/tank-pa-oss")) &&
    !is404;

  useEffect(() => {
    if (isOpen) {
      setSectorGuideOpen();
    } else {
      setSectorGuideClosed();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isLoading) {
      setNavigationReady(true);
    }
  }, [isLoading]);

  const handleSectorToggle = (closedBySubmit: boolean) => {
    if (isOpen && !closedBySubmit) {
      const sector = getSectorById(selectedSector?.sector?.sectorId);
      const role = getRoleById(selectedSector?.role?.roleId);
      sectorTracker(sector?.defaultName, role?.defaultName, "avbryt", "målgruppsväljare_pop_up");
    } else if (!isOpen) {
      sectorViewTracker("målgruppsväljare_pop_up");
    }
    if (isOpen) {
      removeQueryParameter("openSector");
    } else {
      appendQueryParameter("openSector", "true");
    }
    isOpen && sectorToggleRef?.current?.focus();
  };

  return (
    <>
      <div
        className={`${navigationStyles.navigationWrapper} ${
          hideMobileMenu ? navigationStyles.hideInMobile : ""
        } ${hideDesktopNavigation ? navigationStyles.hideInDesktop : ""} ${
          isForsakringPages ? "" : navigationStyles.hideColumnTwo
        }`}
        style={{ display: navigationReady ? "block" : "none" }}
      >
        <Navbar
          menu={
            <RenderMenu
              isForsakringPages={isForsakringPages}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
              isBrandPortal={isBrandPortal}
              brandMenu={brandMenu}
              isCampaignPage={isCampaignPage}
              campaignMenu={campaignMenu}
              mobileMenu={mobileMenu}
              mobileMenuShortcuts={mobileMenuShortcuts}
              mobileMenuId={mobileMenuId}
              desktopMenu={desktopMenu}
              path={path}
            />
          }
          useMobileMenu={isTablet}
          columnTwo={
            <>
              {isForsakringPages && (
                <div className={`${navigationStyles.sectorButtonWrapper}`}>
                  <ToggleButton
                    label={navigationReady ? displayName : "Laddar..."}
                    variant="none"
                    textColor={"black"}
                    fontweight={400}
                    iconBefore={"groups"}
                    onClick={() => handleSectorToggle(false)}
                    buttonRef={sectorToggleRef}
                    dataTestId={"sector-popup-button"}
                    className={navigationStyles.sectorButton}
                  />
                </div>
              )}
            </>
          }
          className={toggleMenu && !isOpen && navigationStyles.navbar}
        />
        {isOpen ? (
          <SectorGuide
            sectorToggleRef={sectorToggleRef}
            openClose={(closedBySubmit: boolean) => {
              handleSectorToggle(closedBySubmit);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div
        className={`${navigationStyles.navSkeleton} ${
          hideDesktopNavigation ? navigationStyles.hideInDesktop : ""
        } ${hideMobileMenu ? navigationStyles.hideInMobile : ""}`}
        style={{ display: navigationReady ? "none" : "block" }}
      />
    </>
  );
};

export default Navigation;
