import { RefObject, useId } from "react";

import Icon from "@afa-shared/afa-components/dist/Icon";

import closeSectionStyles from "./CloseSection.module.css";

interface ICloseSection {
  close?: () => void;
  noMargin?: boolean;
  closeButtonRef?: RefObject<HTMLButtonElement>;
}
export const CloseSection = ({ close, closeButtonRef }: ICloseSection) => {
  const buttonLabelId = useId();
  return (
    <button
      className={closeSectionStyles.closeSection}
      onClick={close}
      aria-labelledby={buttonLabelId}
      ref={closeButtonRef}
    >
      <span id={buttonLabelId}>Stäng</span>
      <Icon name="close" size="sm" />
    </button>
  );
};
export default CloseSection;
