import React, { RefObject, useEffect, useRef } from "react";

import Message from "@afa-shared/afa-components/dist/Message";
import Modal from "@afa-shared/afa-components/dist/Modal";

import { useEscape } from "hooks/useEscape";
import { useTab } from "hooks/useTab";

import modalStyles from "./shareImageModal.module.scss";

interface IShareImageModal {
  heading: string;
  errorMessage: string;
  handleModalClose: () => void;
  containerRef: RefObject<HTMLDivElement>;
}

const ShareImageModal = ({
  heading,
  errorMessage,
  handleModalClose,
  containerRef,
}: IShareImageModal) => {
  const refs = {
    modalCloseButton: useRef(null),
    confirmButton: useRef(null),
    hiddenTabHelperTop: useRef(null),
    hiddenTabHelperBottom: useRef(null),
  };
  useEffect(() => {
    refs.hiddenTabHelperTop?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEscape(containerRef, () => {
    handleModalClose();
  });

  useTab(
    () => {
      if (document?.activeElement === refs?.confirmButton?.current) {
        refs.hiddenTabHelperTop?.current?.focus();
      }
    },
    () => {
      if (
        document?.activeElement === refs?.modalCloseButton?.current ||
        document?.activeElement === refs?.hiddenTabHelperTop?.current
      ) {
        refs.hiddenTabHelperBottom?.current?.focus();
      }
    }
  );

  return (
    <div className={modalStyles.modalOverlay}>
      <div ref={refs.hiddenTabHelperTop} tabIndex={-1} data-temp-keep-index />
      <Modal
        modalOnClose={() => handleModalClose()}
        modalCloseButtonRef={refs.modalCloseButton}
        className={modalStyles.modal}
        children={
          <div className={modalStyles.modalContent}>
            <Message type={"warning"} heading={heading} children={<p>{errorMessage}</p>} />
          </div>
        }
      />
      <div ref={refs.hiddenTabHelperBottom} tabIndex={-1} data-temp-keep-index />
    </div>
  );
};

export default ShareImageModal;
