import { ResultMessageModel, ResultModel } from "./ResultModel";

const getQuizResult = (
  userCorrectAnswers: number,
  noOfQuestions: number,
  resultObject: ResultModel,
  resultInsufficientMessage: string | null | undefined,
  resultBronzeMessage: string | null | undefined,
  resultSilverMessage: string | null | undefined,
  resultGoldMessage: string | null | undefined
): ResultMessageModel => {
  switch (true) {
    case userCorrectAnswers <= Math.round(noOfQuestions * (1 / 3)):
      return {
        ...resultObject.insufficient,
        ...{
          message: resultInsufficientMessage || resultObject.insufficient.message,
        },
      };
    case userCorrectAnswers < Math.round(noOfQuestions * (2 / 3)):
      return {
        ...resultObject.average,
        ...{ message: resultBronzeMessage || resultObject.average.message },
      };
    case userCorrectAnswers < noOfQuestions:
      return {
        ...resultObject.great,
        ...{ message: resultSilverMessage || resultObject.great.message },
      };
    case userCorrectAnswers === noOfQuestions:
      return {
        ...resultObject.perfect,
        ...{ message: resultGoldMessage || resultObject.perfect.message },
      };
    default:
      throw new Error(`Unexpected number of correct answers: ${userCorrectAnswers}`);
  }
};

const getQuizResultImage = (
  userCorrectAnswers: number,
  noOfQuestions: number,
  bronzeImage: any,
  silverImage: any,
  goldImage: any
) => {
  switch (true) {
    case userCorrectAnswers < Math.round(noOfQuestions * (2 / 3)):
      return bronzeImage || null;
    case userCorrectAnswers < noOfQuestions:
      return silverImage || null;
    case userCorrectAnswers === noOfQuestions:
      return goldImage || null;
    default:
      return null;
  }
};

export { getQuizResult, getQuizResultImage };
