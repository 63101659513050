import Accordion from "@afa-shared/afa-components/dist/Accordion";
import Grid from "@afa-shared/afa-components/dist/Grid";
import RichTextRenderer from "@components/RichTextRenderer";

import { Context } from "types/context/Context";

import { faqTracker } from "./FAQTracker";
import { FAQType_faq } from "./queries/contentfulTypes/FAQType";

interface FaqBlockProps {
  blockData: FAQType_faq;
  uniqueKey?: string;
  context?: Context;
}
export const FAQBlock = ({ blockData: faq, context }: FaqBlockProps) => {
  const handleClick = (heading: string, index: number, isOpen: boolean) => {
    if (isOpen) faqTracker(heading, context?.insuranceName || "");
  };

  return (
    <Grid data-blockname="faq">
      <Accordion
        variant={"faq"}
        onClick={(index, isOpen) => handleClick(faq?.question, index, isOpen)}
        faqSvgImgUrl="/images/icons/help.svg"
        openSvgImgUrl="/images/add.svg"
        closeSvgImgUrl="/images/remove.svg"
      >
        <div data-headervariant={context?.heading?.parentLevel} data-header={faq?.question}>
          <RichTextRenderer json={faq?.answer?.json} links={faq?.answer?.links} />
        </div>
      </Accordion>
    </Grid>
  );
};

export default FAQBlock;
