import * as ga from "../../lib/ga";

export const relatedContentTracker = (selectedContent: string, insurance: string) => {
  const trackingData = {
    event: "select_content",
    event_parameters: {
      page_location: window.location.href,
      content_type: selectedContent?.toLowerCase(),
      feature_block_namn: "relaterat innehåll",
      försäkring: insurance?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};
