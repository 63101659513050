import { RefObject, useEffect } from "react";

import { isExternalLink, trackExternalLink } from "@utils/trackingHelpers/externalLinkHelper";
import { getBaseUrl } from "@utils/urlResolver";

type LinkObject = {
  url: string;
  ref: RefObject<any>;
};

const useExternalTracking = (linkObject: LinkObject) => {
  useEffect(() => {
    const link = linkObject?.ref?.current;
    handleLink(linkObject?.url, link);
  }, []);
};

const useExternalTrackingByParent = (linkObject: LinkObject) => {
  useEffect(() => {
    const link = linkObject?.ref?.current?.getElementsByTagName("a")[0];
    handleLink(linkObject?.url, link);
  }, []);
};

const useMultiExternalTracking = (linkObjects: Array<LinkObject>) => {
  useEffect(() => {
    linkObjects.map((linkObject) => {
      const link = linkObject?.ref?.current;
      handleLink(linkObject?.url, link);
    });
  }, []);
};

const handleLink = (url: string, link: any) => {
  const origin = getBaseUrl();
  const linkIsExternal = isExternalLink(url, origin);

  if (linkIsExternal && !link?.getAttribute("gaListener")) {
    link?.addEventListener("click", trackExternalLink);
    link?.setAttribute("gaListener", "true");
  }

  () => {
    link?.removeEventListener("click", trackExternalLink);
    link?.removeAttribute("gaListener");
  };
};

export { useExternalTracking, useExternalTrackingByParent, useMultiExternalTracking };
