import { RichTextBlockType_richTextBlock } from "@components/RichTextBlock/queries/contentfulTypes/RichTextBlockType";
import { Block, Inline, Node, helpers } from "@contentful/rich-text-types";

export const toLower = (str: string): string => (!str ? "" : str.toLowerCase());
export const removeAllWhiteSpace = (str: string): string => (!str ? "" : str.replace(/\s+/g, ""));
export const subString = (str: string, length: number, endChars = "..."): string => {
  if (!str) return "";
  if (!length) return str;
  return `${str.substring(0, length)}${endChars}`;
};
export const isNullOrEmpty = (str: string): boolean => {
  if (!str) return true;
  const trimmed = str.trim();
  return trimmed === "";
};

export const replaceSector = (json: any, valueToReplace: string) => {
  const text = JSON.stringify(json);
  const replaced = text ? text?.replace("{sektor}", valueToReplace) : "";
  const newJson = replaced && JSON.parse(replaced);
  return newJson;
};

export const richTextToPlainTextString = (
  rootNode: Block | Inline,
  blockDivisor: string = " "
): string => {
  if (!rootNode || !rootNode.content) {
    return "";
  }
  return (rootNode as Block).content.reduce((acc: string, node: Node, i: number): string => {
    let nodeTextValue: string;

    if (helpers.isText(node)) {
      nodeTextValue = node.value;
    } else if (helpers.isBlock(node) || helpers.isInline(node)) {
      nodeTextValue = richTextToPlainTextString(node, blockDivisor);
      if (!nodeTextValue.length) {
        return acc;
      }
    }

    const nextNode = rootNode.content[i + 1];
    const isNextNodeBlock = nextNode && helpers.isBlock(nextNode);
    const divisor = isNextNodeBlock ? blockDivisor : "";
    return acc + nodeTextValue + divisor;
  }, "");
};

export const isValidEmail = (email: string): boolean => {
  if (!email) return false;
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

//converts string from Contentful eg. "1.29 Mb" to bytes, then calling formatBytes-method to receive correct size
export const getFileSize = (fileSize: string): string => {
  const sizeNumber = parseFloat(fileSize?.split(" ")[0]);
  const sizeType = fileSize?.split(" ")[1];
  switch (sizeType?.toLowerCase()) {
    case "mb":
      return formatBytes(sizeNumber * 1048576);
    case "kb":
      return formatBytes(sizeNumber * 1024);
    case "gb":
      return formatBytes(sizeNumber * 1073741824);
    case "bytes":
      return formatBytes(sizeNumber);
    default:
      return "";
  }
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const CheckRichTextNotEmpty = (block: RichTextBlockType_richTextBlock) => {
  if (!block?.bodytext?.json) return false;
  const hasContent =
    block?.bodytext?.json?.content?.length > 1 ||
    (block?.bodytext?.json?.content?.length === 1 &&
      block?.bodytext?.json?.content[0]?.content[0]?.value?.trim()?.length > 0);
  return hasContent;
};
