import Accordion from "@afa-shared/afa-components/dist/Accordion";
import RichTextRenderer from "@components/RichTextRenderer";

import { Context } from "types/context/Context";

import CFContentArea from "../cFContentArea";
import accordionStyles from "./Accordion.module.css";
import { accordionTracker } from "./accordionTracker";
import { AccordionBlockType_accordionBlock } from "./queries/contentfulTypes/AccordionBlockType";

interface IAccordionBlockProps {
  blockData: AccordionBlockType_accordionBlock;
  context?: Context;
  uniqueKey?: string;
  maxWidth: number;
}

export const AccordionBlock = ({
  blockData: accordionBlock,
  context,
  uniqueKey,
  maxWidth,
}: IAccordionBlockProps) => {
  context = { ...context, accordion: true };
  const handleClick = (heading: string, index: number, isOpen: boolean) => {
    if (isOpen) accordionTracker(heading, context?.insuranceName || "");
  };

  return (
    <Accordion
      variant={"default"}
      onClick={(index, isOpen) => handleClick(accordionBlock?.heading, index, isOpen)}
      data-blockname="expanderbart innehåll"
      className={`${accordionStyles.accordion} ${context?.accordion ? "print-noborder" : ""}`}
      data-contentful-entry-id={accordionBlock.sys?.id}
      data-contentful-field-id="name"
    >
      <div
        data-expanded={accordionBlock?.expanded}
        data-header={accordionBlock?.heading}
        data-headervariant={context?.heading?.parentLevel}
        data-uniquekey={`heading-${uniqueKey}`}
        className={`${accordionStyles.accordionWrapper}`}
      >
        {accordionBlock?.text?.json && (
          <RichTextRenderer json={accordionBlock?.text?.json} links={accordionBlock?.text?.links} />
        )}
        {accordionBlock?.contentAreaCollection?.items?.length > 0 && (
          <CFContentArea
            desktopColumns={1}
            contentAreaItems={accordionBlock?.contentAreaCollection?.items}
            context={context}
            heading={context?.heading?.childLevel}
            maxWidth={maxWidth}
          />
        )}
      </div>
    </Accordion>
  );
};
export default AccordionBlock;
