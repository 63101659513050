import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import Tooltip from "@afa-shared/afa-components/dist/Inputs/Tooltip";

import { useEscape } from "hooks/useEscape";
import { useClickOutside } from "hooks/useOutsideClick";
import { useTab } from "hooks/useTab";

interface IAfaTooltip {
  labelSize: "normal" | "heading";
  label?: string;
  showLabel?: boolean;
  spaceBetween?: boolean;
  removeMargin?: boolean;
  tooltipLabel?: string;
  iconAriaLabel?: string;
  tooltipContent: any;
  tooltipMaxWidth?: string;
  className?: string;
  ariaLabelledBy: string;
  tooltipPositionX?: "left" | "right";
  tooltipPositionY?: "up" | "down";
  setParentOpenState?: Dispatch<SetStateAction<boolean>>;
}

const AfaTooltip = ({
  labelSize,
  label,
  showLabel,
  iconAriaLabel,
  ariaLabelledBy,
  tooltipMaxWidth,
  tooltipContent,
  className,
  tooltipPositionX,
  tooltipPositionY,
  setParentOpenState,
}: IAfaTooltip) => {
  const [open, setOpen] = useState<boolean>(false);

  const refs = {
    tooltipTopRef: useRef<HTMLSpanElement>(null),
    tooltipBottomRef: useRef<HTMLSpanElement>(null),
    tooltipContentRef: useRef<HTMLDivElement>(null),
    tooltipOpenBtnRef: useRef<HTMLButtonElement>(null),
    tooltipCloseBtnRef: useRef<HTMLButtonElement>(null),
  };

  const handleStateToggle = (state: boolean) => {
    setOpen(state);
    setParentOpenState && setParentOpenState(state);
  };

  useClickOutside(
    refs?.tooltipContentRef,
    () => {
      handleStateToggle(false);
    },
    open
  );

  useEffect(() => {
    if (open) {
      refs?.tooltipTopRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useTab(
    () => {
      refs?.tooltipTopRef?.current?.focus();
    },
    () => {
      refs?.tooltipBottomRef?.current?.focus();
    }
  );

  useEscape(
    refs?.tooltipCloseBtnRef,
    () => {
      handleStateToggle(false);
      refs?.tooltipOpenBtnRef?.current?.focus();
    },
    open
  );

  const handleToggle = (newOpenState: boolean) => {
    handleStateToggle(newOpenState);
    if (newOpenState === false) {
      refs?.tooltipOpenBtnRef?.current?.focus();
    }
  };

  return (
    <Tooltip
      label={label}
      showLabel={showLabel}
      children={tooltipContent}
      positionX={tooltipPositionX}
      positionY={tooltipPositionY}
      tipMaxWidth={tooltipMaxWidth}
      iconAriaLabel={iconAriaLabel}
      open={open}
      setOpen={(newState) => handleToggle(newState)}
      tooltipContentRef={refs.tooltipContentRef}
      tooltipOpenBtnRef={refs?.tooltipOpenBtnRef}
      tooltipCloseBtnRef={refs?.tooltipCloseBtnRef}
      useTabHelper={true}
      tooltipTopRef={refs?.tooltipTopRef}
      tooltipBottomRef={refs?.tooltipBottomRef}
      labelSize={labelSize}
      aria-labelledby={ariaLabelledBy}
      className={className}
    />
  );
};

export default AfaTooltip;
