import Grid from "@afa-shared/afa-components/dist/Grid";
import QuoteCard from "@afa-shared/afa-components/dist/QuoteCard";
import TrackedLink from "@components/TrackedLink";
import { BlockSize } from "@components/cFContentArea";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getPortraitImage } from "@utils/imageHelpers/portraitImageHelper";
import { getInternalOrExternalIcon } from "@utils/linkIconHelper";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

import { Context } from "types/context/Context";

import { QuoteBlockType_quoteBlock } from "./queries/contentfulTypes/QuoteBlockType";

interface IQuoteBlockProps {
  blockData?: QuoteBlockType_quoteBlock;
  uniqueKey?: string;
  context?: Context;
}
export const QuoteBlock = ({ blockData: quoteBlock, context }: IQuoteBlockProps) => {
  const imageData = quoteBlock?.ivImage?.[0];
  const image = getPortraitImage(imageData);

  const url = quoteBlock?.linkReference?.url;
  const externalReference = quoteBlock?.linkReference?.url;
  const internalReference = quoteBlock?.linkReference?.reference;
  const globalReference = quoteBlock?.linkReference?.globalLinkItem;
  const linkVariant =
    quoteBlock?.linkReference?.variant &&
    quoteBlock?.linkReference?.variant?.toLowerCase() === "knapp"
      ? "button"
      : "link";
  const linkType = getInternalOrExternalIcon(externalReference, internalReference, globalReference);

  return (
    <Grid
      mobile={12}
      data-blockname="citatblock"
      className={`${
        context?.sizes?.desktop === BlockSize.Full || context?.sizes?.notebook === BlockSize.Full
          ? "full-width"
          : ""
      }`}
      data-contentful-entry-id={quoteBlock?.sys?.id}
      data-contentful-field-id="name"
    >
      <QuoteCard
        imageUrl={replaceImageVaultBasePath(image?.url)}
        imageAltTag={imageData?.Metadata?.altText}
        quoteText={
          quoteBlock?.quoteText?.json && documentToReactComponents(quoteBlock?.quoteText?.json)
        }
        quoteSource={quoteBlock?.quoteSource}
        linkType={
          quoteBlock?.linkReference && (
            <TrackedLink
              linkType={linkType}
              linkText={quoteBlock?.linkReference?.linkText}
              url={url}
              linkVariant={linkVariant}
            />
          )
        }
      />
    </Grid>
  );
};

export default QuoteBlock;
