import { PaymentDay } from "contexts/PaymentDaysContext";

import FactCard from "@afa-shared/afa-components/dist/FactCard";
import Text from "@afa-shared/afa-components/dist/Text";

import { Context } from "types/context/Context";

import paymentDaysBlockStyles from "./PaymentDaysBlock.module.css";
import { getClosestPaymentDayAsObject } from "./paymentDaysHelper";
import { PaymentDaysBlockType_paymentDaysBlock } from "./queries/contentfulTypes/PaymentDaysBlockType";

interface PaymentDaysFactCardProps {
  paymentDays: PaymentDay[];
  context?: Context;
  blockData: PaymentDaysBlockType_paymentDaysBlock;
}

export const PaymentFactCard = ({ paymentDays, context, blockData }: PaymentDaysFactCardProps) => {
  const closestPaymentDay = getClosestPaymentDayAsObject(paymentDays);
  return (
    <div
      className={paymentDaysBlockStyles.paymentFactCard}
      data-contentful-entry-id={blockData.sys?.id}
      data-contentful-field-id="name"
    >
      <FactCard
        heading={"Nästa utbetalningsdag"}
        headingVariant={context?.heading?.parentLevel}
        type={"blue"}
        data-blockname="faktaruta"
        className={paymentDaysBlockStyles.factCard}
        tabIndex={0}
      >
        <div className={paymentDaysBlockStyles.singleTextContainer}>
          <Text
            variant="paragraph"
            className={`${paymentDaysBlockStyles.text} ${paymentDaysBlockStyles.capitalize}`}
          >
            {closestPaymentDay ? (
              <span>
                {closestPaymentDay.dayOfWeek} {closestPaymentDay.dayOfMonth}{" "}
                {closestPaymentDay.month}
              </span>
            ) : (
              <span>Något gick fel</span>
            )}
          </Text>
        </div>
      </FactCard>
    </div>
  );
};
export default PaymentFactCard;
