import clsx from "clsx";
import { Dispatch, RefObject, SetStateAction, useEffect, useId, useRef, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import TextInput from "@afa-shared/afa-components/dist/Inputs/TextInput";
import Text from "@afa-shared/afa-components/dist/Text";
import TrackedLink from "@components/TrackedLink";
import { LinkType } from "@utils/linkIconHelper";

import { OrderListType } from "../../OrderBlock";
import FormValidationFeedback from "../Feedback/FormValidationFeedback";
import {
  addressMaxInputLength,
  emailMaxInputLength,
  generalMaxInputLength,
} from "../formConstants";
import { scrollIntoView, validate } from "../formHelper";
import { formTracker } from "../formTracker";
import formStyles from "../forms.module.css";

interface IOrderForm {
  orderList: OrderListType[];
  setFormSucceded: Dispatch<SetStateAction<boolean>>;
  setErrorSendMail: Dispatch<SetStateAction<boolean>>;
  blockId: string;
  orderListRef: RefObject<any>;
  sendFeedbackRef: RefObject<any>;
}

export const OrderForm = ({
  orderList,
  setFormSucceded,
  blockId,
  setErrorSendMail,
  orderListRef,
  sendFeedbackRef,
}: IOrderForm) => {
  const [tooHighAmount, setTooHighAmount] = useState<boolean>(false);
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [hasSingleError, setHasSingleError] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    organisation: { value: "", required: false },
    name: { value: "", required: true },
    role: { value: "", required: false },
    address: { value: "", required: true },
    zipCode: { value: "", required: true },
    state: { value: "", required: true },
    email: { value: "", required: true },
    orders: { value: [], required: false },
    blockId: { value: blockId, required: false },
  });

  const [errors, setErrors] = useState({
    amount: null,
    name: null,
    role: null,
    address: null,
    zipCode: null,
    state: null,
    email: null,
  });

  const personuppgifterPath = "/personuppgifter";

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setFormState({
      ...formState,
      ["orders"]: { ...formState["orders"], value: orderList },
    });
    if (
      orderList?.some(
        (order: OrderListType) => order?.amount > order.orderItem?.metaData?.orderAmount
      )
    ) {
      setTooHighAmount(true);
    } else {
      setTooHighAmount(false);
      setErrors({ ...errors, ["amount"]: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderList]);

  const formRefs = {
    sendFeedback: sendFeedbackRef,
    errorInfobox: useRef(null),
    name: useRef(null),
    role: useRef(null),
    email: useRef(null),
    address: useRef(null),
    zipCode: useRef(null),
    state: useRef(null),
    amount: orderListRef,
  };

  const formIds = {
    organisation: useId(),
    name: useId(),
    role: useId(),
    address: useId(),
    zipCode: useId(),
    state: useId(),
    email: useId(),
    header: useId(),
  };

  const handleFormChange = (e: any, key: string = null) => {
    const { value } = e.target;
    setFormState({ ...formState, [key]: { ...formState[key], value: value } });
    setErrors({ ...errors, [key]: null });
  };

  const handleError = () => {
    setErrorSendMail(true);
    setFormSucceded(true);
    formTracker("felmeddelande", "beställning");
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const manipulatedFormState = { ...formState };
    manipulatedFormState["amount"] = { value: tooHighAmount, required: true };

    setHasSingleError(false);
    const formErrors = validate(manipulatedFormState, errors);
    setErrors(formErrors);
    const foundErrors = Object.values(formErrors).filter((e) => e !== null).length;

    if (foundErrors) {
      if (foundErrors === 1) {
        setHasSingleError(true);
        const key = Object.keys(formErrors).find((key) => formErrors[key] !== null);
        scrollIntoView(formRefs[key]);
      } else {
        scrollIntoView(formRefs?.errorInfobox);
      }
    } else {
      let cleanFormState = {};
      for (const field in formState) {
        cleanFormState = { ...cleanFormState, [field]: formState[field].value };
      }
      try {
        setIsSubmitting(true);

        // Need to slim down payload here to avoid large orders timing out.
        // Previously the whole order items objects were sent over the wire which
        // caused issues when all items were ordered. ¯\_(ツ)_/¯
        // See https://dev.azure.com/afaforsakring2/TeamPolaris/_workitems/edit/254075
        const payload = JSON.parse(JSON.stringify(cleanFormState));
        payload.orders = payload.orders.map((x: any) => ({
          fNumber: x.orderItem.metaData.fNumber,
          name: x.orderItem.metaData.headingWeb,
          amount: x.amount,
        }));

        const res = await fetch("/api/sendOrder", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (res?.ok) {
          setFormSucceded(true);
          formTracker("inskickat", "beställning");
          formRefs?.sendFeedback?.current?.focus();
        } else {
          handleError();
        }
      } catch (err) {
        handleError();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleReCaptchaVerify = (token) => {
    if (!token) {
      return;
    }
    token && setIsValidToken(true);
  };

  const renderErrorInfoBox = () => (
    <FormValidationFeedback
      formErrors={errors}
      formRefs={formRefs}
      hasSingleError={hasSingleError}
    />
  );

  return (
    <Grid direction={"column"} className={"form-container"}>
      <GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
      <div className={`${formStyles.formBackground} ${formStyles.formWrapper}`}>
        <div className={formStyles.formHeadingWrapper}>
          <Heading id={formIds?.header} variant="h2" removeMargin>
            Fyll i dina uppgifter
          </Heading>
          <Text variant="paragraph" removeMargin>
            * Obligatoriskt fält
          </Text>
        </div>
        {renderErrorInfoBox()}
        <form aria-labelledby={formIds?.header} noValidate className={formStyles.form}>
          <TextInput
            label={"Din organisation och arbetsplats"}
            id={formIds?.organisation}
            name="organisation"
            value={formState?.organisation?.value}
            type={"text"}
            maxLength={generalMaxInputLength}
            fullWidth
            onChange={(e) => handleFormChange(e, "organisation")}
          />
          <TextInput
            inputRef={formRefs?.name}
            label={"Ditt förnamn och efternamn *"}
            required
            aria-required="true"
            id={formIds?.name}
            autoComplete="name"
            value={formState?.name?.value}
            type={"text"}
            maxLength={generalMaxInputLength}
            fullWidth
            error={errors?.name?.input}
            onChange={(e) => handleFormChange(e, "name")}
          />
          <TextInput
            inputRef={formRefs?.role}
            label={"Din roll/titel"}
            id={formIds?.role}
            value={formState?.role?.value}
            type={"text"}
            maxLength={generalMaxInputLength}
            fullWidth
            onChange={(e) => handleFormChange(e, "role")}
          />
          <TextInput
            inputRef={formRefs?.address}
            label={"Leveransadress *"}
            required
            id={formIds?.address}
            autoComplete="street-address"
            fullWidth
            error={errors?.address?.input}
            value={formState?.address?.value}
            type={"text"}
            maxLength={addressMaxInputLength}
            onChange={(e) => handleFormChange(e, "address")}
          />
          <div className={formStyles.multiFieldWrapper}>
            <TextInput
              inputRef={formRefs?.zipCode}
              label={"Postnummer *"}
              required
              id={formIds?.zipCode}
              autoComplete="postal-code"
              value={formState?.zipCode?.value}
              type={"text"}
              maxLength={6}
              pattern="[0-9]*"
              width={"100%"}
              error={errors?.zipCode?.input}
              onChange={(e) => handleFormChange(e, "zipCode")}
            />
            <TextInput
              inputRef={formRefs?.state}
              label={"Postort *"}
              required
              id={formIds?.state}
              autoComplete="address-level1"
              value={formState?.state?.value}
              type={"text"}
              maxLength={generalMaxInputLength}
              width={"100%"}
              error={errors?.state?.input}
              onChange={(e) => handleFormChange(e, "state")}
            />
          </div>
          <TextInput
            inputRef={formRefs?.email}
            label={"Din e-postadress (kontrollera stavningen) *"}
            required
            id={formIds?.email}
            autoComplete="email"
            value={formState?.email?.value}
            type={"email"}
            maxLength={emailMaxInputLength}
            fullWidth
            error={errors?.email?.input}
            onChange={(e) => handleFormChange(e, "email")}
          />
          <TrackedLink
            url={personuppgifterPath}
            linkText={"Så behandlar vi personuppgifter"}
            linkType={LinkType.Internal}
            linkVariant={"link"}
            target={"_self"}
          />
          <Button
            type="submit"
            buttonType="primary"
            onClick={
              isValidToken
                ? onSubmit
                : (e) => {
                    e.preventDefault();
                  }
            }
            label={isSubmitting ? "" : "Skicka beställning"}
            className={clsx(formStyles.submitButton, { [formStyles.spinner]: isSubmitting })}
            disabled={isSubmitting}
          />
        </form>
      </div>
    </Grid>
  );
};
export default OrderForm;
