import Link from "next/link";
import { RefObject, useId, useRef, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import TextInput from "@afa-shared/afa-components/dist/Inputs/TextInput";
import Text from "@afa-shared/afa-components/dist/Text";
import { quizFormActionTracker } from "@components/QuizBlock/QuizTracker";

import FormErrorBox from "../FormErrorBox";
import {
  addressMaxInputLength,
  emailMaxInputLength,
  generalMaxInputLength,
  scrollIntoView,
  validate,
} from "../FormHelper";
import FormSendError from "../FormSendError";
import formStyles from "../Forms.module.css";
import { setQuizParticipationCookie } from "./CompetitionFormHelper";

interface ICompetitionForm {
  submitButtonRef: RefObject<any>;
  setFormIsSubmitted: () => void;
  closeModal: () => void;
  competitionExpirationDate: Date;
  quizId: string;
  quizType: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const CompetitionForm = ({
  submitButtonRef,
  setFormIsSubmitted,
  closeModal,
  competitionExpirationDate,
  quizId,
  quizType,
}: ICompetitionForm) => {
  const [formHasSendErrors, setFormHasSendErrors] = useState<boolean>(false);
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    name: { value: "", required: true },
    email: { value: "", required: true },
    address: { value: "", required: true },
    zipCode: { value: "", required: true },
    city: { value: "", required: true },
  });
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    address: null,
    zipCode: null,
    city: null,
  });
  const formIds = {
    header: useId(),
    name: useId(),
    email: useId(),
    address: useId(),
    zipCode: useId(),
    city: useId(),
    consent: useId(),
  };
  const formRefs = {
    name: useRef(null),
    email: useRef(null),
    address: useRef(null),
    zipCode: useRef(null),
    city: useRef(null),
    consent: useRef(null),
    errorInfoBox: useRef(null),
  };

  const numErrors = Object.keys(errors).filter((key) => errors[key]).length;

  const handleReCaptchaVerify = (token) => {
    if (!token) {
      return;
    }
    token && setIsValidToken(true);
  };

  const handleFormChange = (e: any, key: string = null) => {
    const { value } = e.target;
    setFormState({ ...formState, [key]: { ...formState[key], value: value } });
    setErrors({ ...errors, [key]: null });
  };

  const handleSubmitClick = (e: any) => {
    isValidToken
      ? handleSubmit(e)
      : (e) => {
          e.preventDefault();
        };
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formErrors = validate(formState, errors);
    setErrors(formErrors);
    const foundErrors = Object.values(formErrors).filter((e) => e !== null).length;

    if (foundErrors === 0) {
      let cleanFormState = {};
      for (const field in formState) {
        cleanFormState = { ...cleanFormState, [field]: formState[field].value };
      }

      try {
        setIsSubmitting(true);

        const res = await fetch("/api/sendQuizCompetitionEntry", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cleanFormState),
        });

        if (res?.ok) {
          setQuizParticipationCookie(quizId, competitionExpirationDate);
          setFormIsSubmitted();
          closeModal();
          sessionStorage.removeItem(`${quizId}-answers`);
          sessionStorage.removeItem(`${quizId}-modal-open`);
          quizFormActionTracker(quizType, "tävling_quiz", "inskickat");
        } else {
          setFormHasSendErrors(true);
          scrollIntoView(formRefs?.errorInfoBox);
        }
      } catch (err) {
        setFormHasSendErrors(true);
        scrollIntoView(formRefs?.errorInfoBox);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      if (foundErrors === 1) {
        const key = Object.keys(formErrors).find((key) => formErrors[key] !== null);
        scrollIntoView(formRefs[key]);
      } else {
        scrollIntoView(formRefs?.errorInfoBox);
      }
    }
  };

  return (
    <div>
      <GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
      <Grid
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={formStyles.formHeading}
      >
        <Heading id={formIds.header} variant="h2" removeMargin>
          Fyll i dina uppgifter
        </Heading>
        <Text variant="span">* Obligatoriskt fält</Text>
      </Grid>
      <div
        ref={formRefs.errorInfoBox}
        aria-live="polite"
        className={`${numErrors > 1 || formHasSendErrors ? formStyles.formFeedbackVisible : ""}`}
        tabIndex={-1}
        data-temp-keep-index
      >
        {numErrors > 1 ? (
          <FormErrorBox errors={errors} refs={formRefs} />
        ) : formHasSendErrors ? (
          <FormSendError />
        ) : (
          <></>
        )}
      </div>
      <div className={formStyles.termsText}>
        <Text variant="smallParagraph">
          {
            "Vi sparar ditt namn och dina kontaktuppgifter för att kunna meddela dig om du har vunnit och för att kunna posta t-shirten till dig. Direkt efter tävlingens slut raderas uppgifterna. Mer information om dina rättigheter och "
          }
          <Link
            href={
              "/kundtjanst/information-for-olika-kategorier/deltagare-vid-utbildning-eller-event"
            }
            prefetch={false}
            className={formStyles.termsLink}
          >
            {"hur vi behandlar dina personuppgifter"}
          </Link>
          {"."}
        </Text>
      </div>
      <form className={formStyles.form} aria-labelledby={formIds.header} noValidate>
        <TextInput
          inputRef={formRefs.name}
          label={"Ditt förnamn och efternamn *"}
          id={formIds.name}
          name="name"
          required
          autoComplete="name"
          value={formState.name.value}
          type={"text"}
          error={errors.name?.input}
          maxLength={generalMaxInputLength}
          onChange={(e) => handleFormChange(e, "name")}
        />
        <TextInput
          inputRef={formRefs.email}
          label={"Din e-postadress (kontrollera stavningen) *"}
          required
          id={formIds.email}
          autoComplete="email"
          value={formState.email.value}
          type={"email"}
          error={errors.email?.input}
          maxLength={emailMaxInputLength}
          onChange={(e) => handleFormChange(e, "email")}
        />
        <TextInput
          inputRef={formRefs.address}
          label={"Gatuadress *"}
          id={formIds.address}
          required
          autoComplete="street-address"
          value={formState.address.value}
          type={"text"}
          maxLength={addressMaxInputLength}
          error={errors.address?.input}
          onChange={(e) => handleFormChange(e, "address")}
        />
        <div className={formStyles.multiInputRow}>
          <TextInput
            inputRef={formRefs.zipCode}
            label={"Postnummer *"}
            required
            id={formIds.zipCode}
            autoComplete="postal-code"
            value={formState.zipCode.value}
            type={"text"}
            width={"100%"}
            maxLength={5}
            pattern="[0-9]*"
            error={errors.zipCode?.input}
            onChange={(e) => handleFormChange(e, "zipCode")}
          />
          <TextInput
            inputRef={formRefs.city}
            label={"Postort *"}
            id={formIds.city}
            required
            autoComplete="address-level1"
            width={"100%"}
            value={formState.city.value}
            type={"text"}
            maxLength={generalMaxInputLength}
            error={errors.city?.input}
            onChange={(e) => handleFormChange(e, "city")}
          />
        </div>
        <div className={formStyles.consentWrapper}>
          <Button
            type="submit"
            buttonType="primary"
            onClick={(e) => handleSubmitClick(e)}
            label={isSubmitting ? "Skickar..." : "Skicka"}
            buttonRef={submitButtonRef}
            disabled={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
};

export default CompetitionForm;
