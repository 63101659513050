import * as ga from "../../lib/ga";

export const shareImageTracker = (contentId: string, contentType = "kort") => {
  const trackingData = {
    event: "share",
    event_parameters: {
      page_location: window.location.href,
      content_type: contentType,
      content_id: contentId,
    },
  };
  ga.gaTracking(trackingData);
};

export const imageDownloadTracker = (fileName: string, extention: string) => {
  const trackingData = {
    event: "file_download",
    event_parameters: {
      page_location: window.location.href,
      file_name: fileName?.toLowerCase(),
      file_extention: extention?.toLowerCase(),
    },
  };

  ga.gaTracking(trackingData);
};
