import { ChangeEvent, RefObject, useEffect, useState } from "react";

// import { virtualPageviewTracker } from "@containers/Layout/layoutTracker";
import { getPersonalInfoByAudienceId } from "@utils/targetGroup/getPersonalInfo";
import { getRoleById, getSectorById } from "@utils/targetGroup/targetGroupHelper";

import useParamsToggler from "hooks/useParamToggler";
// import { IPageInfo, PageInfo, usePageInfo } from "hooks/zustand/usePageInfo";
import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { IRole, ISector } from "types/PersonalInfo";

import SectorPicker from "./SectorPicker/SectorPicker";
import { sectorTracker } from "./sectorTracker";

interface ISectorGuideProps {
  openClose: (closedBySubmit: boolean) => void;
  sectorToggleRef: RefObject<any>;
}

const SectorGuide = ({ openClose, sectorToggleRef }: ISectorGuideProps) => {
  const [sector, setSector] = useState<ISector>();
  const [role, setRole] = useState<IRole>();
  const [openGuide, setOpenGuide] = useState<boolean>();
  const setSelectedSector = useSectorToggler((state: ISectorToggler) => state.setSelectedSector);
  // const pageInfo: IPageInfo = usePageInfo((state: PageInfo) => state.pageInfo);
  const selectedSector = useSectorToggler((state: ISectorToggler) => state.selectedSector);

  const { closeModal } = useParamsToggler();

  useEffect(() => {
    const currentSector = getSectorById(selectedSector?.sector?.sectorId);
    const currentRole = getRoleById(selectedSector?.role?.roleId);
    setSector(currentSector);
    setRole(currentRole);

    const openSectorGuide = selectedSector?.sector?.sectorId ? true : false;
    setOpenGuide(openSectorGuide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSectorChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const sectorId = event?.target?.value;
    const selectedSector = getSectorById(sectorId);
    setSector(selectedSector);
    setOpenGuide(true);
    setTimeout(() => {
      const element = document.querySelector("#scrollForSubmit");
      element.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  const handleRoleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const roleId = event?.target?.value;
    const selectedRole = getRoleById(roleId);
    setRole(selectedRole);
  };

  const handleSubmit = async () => {
    const audienceId = role.roleId + "-" + sector.sectorId;
    await fetch("/api/personalization/personalization", {
      method: "POST",
      headers: { contentType: "application/json" },
      body: JSON.stringify({ audienceId }),
    });
    //Målgruppsväljaren har bytt målgrupp. Skicka ett event om detta.
    const targetGroupChangedEvent = new CustomEvent("targetGroupChanged", {
      detail: audienceId,
    } as CustomEventInit);
    document.dispatchEvent(targetGroupChangedEvent);

    setSelectedSector(getPersonalInfoByAudienceId(audienceId));
    openClose(true);
    sectorTracker(sector?.defaultName, role?.defaultName, "bekräfta", "målgruppsväljare_pop_up");
    // virtualPageviewTracker(
    //   role,
    //   sector,
    //   pageInfo?.firstLevelPageName,
    //   pageInfo?.secondLevelPageName,
    //   pageInfo?.pageName,
    //   pageInfo?.pageRefferer
    // );
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    sectorToggleRef?.current?.focus();
  };

  return (
    <SectorPicker
      submit={() => handleSubmit()}
      onSelectChange={handleSectorChange}
      onRoleChange={(event) => handleRoleChange(event)}
      selectedRole={role}
      formValid={openGuide}
      selectedSector={sector}
      openClose={(closedBySubmit) => {
        openClose(closedBySubmit);
        closeModal();
      }}
      sectorToggleRef={sectorToggleRef}
    />
  );
};

export default SectorGuide;
