import Accordion from "@afa-shared/afa-components/dist/Accordion";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import { faqTracker } from "@components/FAQBlock/FAQTracker";
import { FAQListBlockType_faqListBlock } from "@components/FAQListBlock/queries/contentfulTypes/FAQListBlockType";
import RichTextRenderer from "@components/RichTextRenderer";
import TrackedLink from "@components/TrackedLink";
import { LinkType } from "@utils/linkIconHelper";

import { Context } from "types/context/Context";

import faqListStyles from "./faqList.module.css";
import { FAQListResultType_faqCollection_items } from "./queries/contentfulTypes/FAQListResultType";

export interface FAQListResult extends FAQListBlockType_faqListBlock {
  faqListResult: Array<FAQListResultType_faqCollection_items>;
}
interface FAQListProps {
  faqListBlock: FAQListResult;
  uniqueKey: string;
  context: Context;
}

export const FAQList = ({ faqListBlock, uniqueKey, context }: FAQListProps | any) => {
  const linkOnFaqList = faqListBlock?.linkOnFaqList;
  const url = faqListBlock?.linkOnFaqList?.url || "";
  const listitems = faqListBlock?.faqListResult ?? faqListBlock?.manualPostsCollection.items;
  const faqVariant = faqListBlock?.heading
    ? context?.heading?.childLevel
    : context?.heading?.parentLevel;

  const handleClick = (heading: string, index: number, isOpen: boolean) => {
    if (isOpen) faqTracker(heading, context?.insuranceName || "");
  };

  const faqListText = () => (
    <>
      {faqListBlock?.heading && (
        <Heading variant={context?.heading?.parentLevel} removeMargin>
          {faqListBlock?.heading}
        </Heading>
      )}
      {faqListBlock?.text && (
        <RichTextRenderer json={faqListBlock?.text?.json} links={faqListBlock?.text?.links} />
      )}
    </>
  );

  return (
    listitems?.length > 0 && (
      <div
        key={uniqueKey}
        className={faqListStyles.faqListBlock}
        data-contentful-entry-id={faqListBlock?.sys?.id ?? faqListBlock?.id}
        data-contentful-field-id="name"
      >
        {faqListBlock?.heading && faqListBlock?.text ? (
          <div className={faqListStyles.textWrapper}>{faqListText()}</div>
        ) : (
          faqListText()
        )}
        <Grid data-blockname="faq" className={`${faqListStyles.faqList}`}>
          {listitems?.map((item, index) => {
            return (
              <Accordion
                variant="faq"
                key={index}
                onClick={(index, isOpen) => handleClick(item?.question, index, isOpen)}
              >
                <div data-headervariant={faqVariant} data-header={item?.question}>
                  <RichTextRenderer json={item?.answer?.json} links={item?.answer?.links} />
                </div>
              </Accordion>
            );
          })}
          {linkOnFaqList && listitems && (
            <Grid direction="row" justifyContent="center">
              <TrackedLink
                url={url}
                linkType={LinkType.Internal}
                linkText={linkOnFaqList?.linkText}
                linkVariant="button"
                buttonStyle="secondary"
              />
            </Grid>
          )}
        </Grid>
      </div>
    )
  );
};
export default FAQList;
