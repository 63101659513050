import { useRouter } from "next/router";

import Card from "@afa-shared/afa-components/dist/Card";
import IvTeaserImage from "@components/Medias/Image/IvTeaserImage";
import { relatedContentTracker } from "@components/RelatedContent/relatedContentTracker";
import { BlockSize } from "@components/cFContentArea";
import { columnSize } from "@utils/sectionHelper";
import {
  getDirection,
  getReverse,
  getTeaserImageSize,
  shouldRemoveBorder,
  shouldUseNotebookSmallImages,
  shouldUseSmallImages,
} from "@utils/teaserHelper/teaserHelper";

import useMediaQuery from "hooks/useMediaQuery";
import { Context } from "types/context/Context";

import pageStyles from "../../styles/page.module.css";
import { PageType_page } from "./queries/contentfulTypes/PageType";

interface PageData extends PageType_page {
  pageUrl: string;
}
interface PageProps {
  blockData: PageData;
  uniqueKey?: string;
  context: Context;
  maxWidth?: number;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Page = ({ blockData: page, context, maxWidth }: PageProps) => {
  const url = page?.pageUrl;

  const headingText =
    context?.sectionBlock && context?.sectionBlock?.noHeading
      ? null
      : page?.teaserTitle
      ? page?.teaserTitle
      : page?.content?.heading;
  const text =
    context?.sectionBlock && context?.sectionBlock?.noText
      ? null
      : page?.teaserText
      ? page?.teaserText
      : page?.content?.preamble;
  const linkText =
    context?.sectionBlock && context?.sectionBlock?.noLink
      ? null
      : page?.teaserLinkText
      ? page?.teaserLinkText
      : page?.content?.heading;

  const isMobile = useMediaQuery("mobile");
  const isTabletOnly = useMediaQuery("tabletOnly");
  const isNotebook = useMediaQuery("notebook");

  const router = useRouter();

  let size: number = 12;
  const contextSize = context?.columns || 1;

  if (context?.sectionBlock) {
    size = contextSize < 2 || isMobile ? 12 : contextSize === 2 ? 6 : 4;
  } else {
    size = columnSize(contextSize);
  }

  const handleClick = (event, url): void => {
    event.preventDefault();
    router.push(url);

    if (context?.relatedContent) {
      relatedContentTracker(headingText, context?.insuranceName);
    }
  };

  const useSmallImage = shouldUseSmallImages(size, maxWidth, context);
  const direction = getDirection(size, maxWidth, !isNotebook && isTabletOnly);
  const imgSize = getTeaserImageSize(size, maxWidth, useSmallImage, context);
  const reverse = getReverse(size, maxWidth);
  const useNotebookSmallImages = shouldUseNotebookSmallImages(size, maxWidth);
  const getImage = (imageData: any) => {
    return context?.sectionBlock && context?.sectionBlock?.noImage ? null : (
      <IvTeaserImage image={imageData} />
    );
  };

  return (
    <Card
      onCardClick={(event) => handleClick(event, url)}
      nextImage={
        context?.sectionBlock && context?.sectionBlock?.noImage
          ? null
          : getImage(page?.ivTeaserImage?.[0])
      }
      direction={direction}
      heading={headingText}
      headingVariant={context?.heading?.parentLevel}
      text={text}
      linkUrl={url}
      linkText={linkText}
      reverse={reverse} //TODO: should be able to choose reverse
      removeBorder={shouldRemoveBorder(context)}
      imgSize={imgSize}
      notebookSmallImage={useNotebookSmallImages}
      lang={page?.pageLanguage === "Arabiska" ? "ar" : undefined}
      dir={page?.pageLanguage === "Arabiska" ? "rtl" : undefined}
      className={`${pageStyles.pageCard} ${
        context?.sizes?.desktop === BlockSize.Full || context?.sizes?.notebook === BlockSize.Full
          ? "full-width"
          : ""
      }`}
      data-contentful-entry-id={page.sys?.id}
      data-contentful-field-id="name"
    />
  );
};
export default Page;
