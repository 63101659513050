import PageList, { PressreleaseListBlockFull } from "@components/PageList";

import { Context } from "types/context/Context";

interface PressreleaseListBlockProps {
  blockData: PressreleaseListBlockFull;
  uniqueKey: string;
  context: Context;
}

export const PressreleaseListBlock = ({
  blockData: pressreleaseListBlock,
  uniqueKey,
  context,
}: PressreleaseListBlockProps) => {
  return <PageList listBlock={pressreleaseListBlock} uniqueKey={uniqueKey} context={context} />;
};
export default PressreleaseListBlock;
