export const getBackgroundColor = (color: string): string => {
  switch (color) {
    case "Ljusgrön":
      return "#B9F5E1";

    case "Ljusblå":
      return "#C8EBFA";
    default:
      return "";
  }
};
