import { defaultImage } from "@utils/imageHelpers/imageHelper";
import { getImageMetaData } from "@utils/imageHelpers/metadataImageHelper";
import { getTeaserImage } from "@utils/imageHelpers/teaserImageHelper";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

import AfaImage from "./AfaImage";
import { IImageSrc } from "./ImageSources";

interface IImage {
  image: any;
  className?: string;
}

const IvSmallTeaserImage = ({ image, className }: IImage): JSX.Element => {
  const metadata = getImageMetaData(image);

  if (image?.mediaUrl.endsWith(".svg")) {
    return (
      <AfaImage
        fallbackUrl={replaceImageVaultBasePath(image?.mediaUrl) || defaultImage?.url}
        alt={metadata?.altText || defaultImage?.imageAltText}
        imageSrcSet={[{ url: replaceImageVaultBasePath(image?.mediaUrl) }]}
        className={className}
      />
    );
  }

  const srcSet: IImageSrc[] = [{ url: replaceImageVaultBasePath(getTeaserImage(image, 768)?.url) }];

  return (
    <AfaImage
      fallbackUrl={replaceImageVaultBasePath(getTeaserImage(image, 768)?.url) || defaultImage?.url}
      alt={metadata?.altText || defaultImage?.imageAltText}
      imageSrcSet={srcSet}
      className={className}
    />
  );
};

export default IvSmallTeaserImage;
