import { useRouter } from "next/router";
import { useEffect, useState } from "react";

function useParamsToggler(queryParamKey = "openSector") {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const openSector: string = router.query[queryParamKey] as string;

    if (openSector && openSector.toLowerCase() === "true") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [router.query, queryParamKey]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleModal = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const appendQueryParameter = (key, value) => {
    const url = new URL(router.asPath, window.location.origin);
    if (!url.searchParams.has(key)) {
      url.searchParams.set(key, value);
      router.replace(url);
    }
  };
  const removeQueryParameter = (key) => {
    const url = new URL(router.asPath, window.location.origin);
    if (url.searchParams.has(key)) {
      url.searchParams.delete(key);
      router.replace(url);
    }
  };

  return {
    isOpen,
    openModal,
    closeModal,
    toggleModal,
    appendQueryParameter,
    removeQueryParameter,
  };
}

export default useParamsToggler;
