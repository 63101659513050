import { defaultImage } from "@utils/imageHelpers/imageHelper";

import { FileBlockDataType } from "types/FileBlockDataType";

import AfaImage from "./AfaImage";
import { IImageSrc } from "./ImageSources";

interface IImage {
  fileData: FileBlockDataType;
  className?: string;
  isLargeImage: boolean;
}

const IvFileListImage = ({ fileData, className, isLargeImage }: IImage): JSX.Element => {
  const hasImages =
    fileData?.urls?.original ||
    fileData?.urls?.size768 ||
    fileData?.urls?.size480 ||
    fileData?.urls?.size375 ||
    fileData?.urls?.size240 ||
    fileData?.urls?.size180
      ? true
      : false;
  if (!hasImages) return null;
  const srcSet: IImageSrc[] = getSourceSet(fileData);
  const altText = fileData?.thumbNailAlt;

  return (
    <AfaImage
      fallbackUrl={
        isLargeImage
          ? fileData?.urls?.size768 ||
            fileData?.urls?.original ||
            fileData?.urls?.size480 ||
            fileData?.urls?.size375 ||
            defaultImage?.url
          : getFirstBestsmallImage(fileData) || defaultImage?.url
      }
      alt={altText || defaultImage?.imageAltText}
      imageSrcSet={srcSet}
      className={className}
    />
  );
};

export default IvFileListImage;

function getSourceSet(image: FileBlockDataType): IImageSrc[] {
  const srcSetArray = [] as IImageSrc[];
  const hasImages =
    image?.urls?.original ||
    image?.urls?.size768 ||
    image?.urls?.size480 ||
    image?.urls?.size375 ||
    image?.urls?.size240 ||
    image?.urls?.size180
      ? true
      : false;
  if (hasImages) {
    srcSetArray.push({
      url:
        image?.urls?.size375 ||
        image?.urls?.size480 ||
        image?.urls?.original ||
        image?.urls?.size768,
      media: "(max-width: 400px)",
    });
    srcSetArray.push({
      url:
        image?.urls?.size768 ||
        image?.urls?.original ||
        image?.urls?.size480 ||
        image?.urls?.size375,
      media: "(max-width: 767px)",
    });
    if (image?.hasExtraThumbnail) {
      srcSetArray.push({
        url:
          image?.urls?.size375 ||
          image?.urls?.original ||
          image?.urls?.size768 ||
          image?.urls?.size480 ||
          image?.urls?.size240,
        media: "(max-width: 1239px)",
      });
      srcSetArray.push({
        url:
          image?.urls?.size375 ||
          image?.urls?.original ||
          image?.urls?.size768 ||
          image?.urls?.size480 ||
          image?.urls?.size240,
        media: "(min-width: 1240px)",
      });
    } else {
      srcSetArray.push({
        url:
          image?.urls?.size180 ||
          image?.urls?.size240 ||
          image?.urls?.size375 ||
          image?.urls?.size480 ||
          image?.urls?.original ||
          image?.urls?.size768,
        media: "(max-width: 1239px)",
      });
      srcSetArray.push({
        url:
          image?.urls?.size240 ||
          image?.urls?.size375 ||
          image?.urls?.size480 ||
          image?.urls?.original ||
          image?.urls?.size768 ||
          image?.urls?.size180,
        media: "(min-width: 1240px)",
      });
    }
  }
  return srcSetArray;
}

function getFirstBestsmallImage(image: FileBlockDataType): string {
  return (
    image?.urls?.size180 ||
    image?.urls?.size240 ||
    image?.urls?.size375 ||
    image?.urls?.size480 ||
    image?.urls?.size768 ||
    image?.urls?.original ||
    defaultImage?.url
  );
}
