import { ImageSize } from "@afa-shared/afa-components/dist/Card";

import { pixelsToRem } from "../sizeHelper";

const imageRemSizes = {
  xs: pixelsToRem(129),
  s: pixelsToRem(213),
  m: pixelsToRem(261),
  l: pixelsToRem(278),
  xl: pixelsToRem(368),
};

const gapRemSizes = {
  xs: "1.5rem",
  s: pixelsToRem(74),
  m: "6rem",
  l: pixelsToRem(122),
};

export const getTabletImageSize = (size: ImageSize): string => {
  return size === "xs" ? imageRemSizes.xs : imageRemSizes.s;
};

export const getTabletGapSize = (): string => {
  return gapRemSizes.xs;
};

export const getNotebookImageSize = (size: ImageSize, smallImage: boolean): string => {
  if (smallImage) return imageRemSizes.xs;
  if (size === "xl" || size === "l" || size === "m") {
    return imageRemSizes.m;
  } else if (size === "s") {
    return imageRemSizes.s;
  } else {
    return imageRemSizes.xs;
  }
};

export const getNotebookGapSize = (size: ImageSize, smallImage: boolean): string => {
  return size === "xs" || smallImage ? gapRemSizes.xs : gapRemSizes.s;
};

export const getImageSize = (size: ImageSize): string => {
  switch (size) {
    case "xs":
      return imageRemSizes.xs;
    case "s":
      return imageRemSizes.s;
    case "m":
      return imageRemSizes.m;
    case "l":
      return imageRemSizes.l;
    case "xl":
      return imageRemSizes.xl;
    default:
      return imageRemSizes.m;
  }
};

export const getGapSize = (size: ImageSize): string => {
  switch (size) {
    case "xs":
      return gapRemSizes.xs;
    case "s":
      return gapRemSizes.s;
    case "m":
      return gapRemSizes.xs;
    case "l":
      return gapRemSizes.xs;
    case "xl":
      return gapRemSizes.l;
    default:
      return gapRemSizes.m;
  }
};
