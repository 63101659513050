import * as ga from "../../lib/ga";

export const sectorTracker = (sector: string, role: string, action: string, context: string) => {
  const trackingData = {
    event: "målgruppsväljare",
    user_properties: {
      roll: role ? role?.toLowerCase() : "",
      sektor: sector ? sector?.toLowerCase() : "",
    },
    event_parameters: {
      page_location: window.location.href.split("?")[0],
      feature_block_namn: context?.toLowerCase(),
      roll: role?.toLowerCase(),
      sektor: sector?.toLowerCase(),
      åtgärd: action?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};

export const sectorViewTracker = (context: string) => {
  const trackingData = {
    event: "block_impression",
    event_parameters: {
      page_location: window.location.href,
      feature_block_namn: context?.toLowerCase(),
      åtgärd: "visning",
    },
  };
  ga.gaTracking(trackingData);
};
