export const getInformationType = (type: string): string => {
  switch (type) {
    case "information":
      return "info";
    case "löst":
      return "success";
    case "pågående":
      return "error";
    case "varning":
      return "warning";
    case "fakta":
      return "blue";
    case "utan kontainer":
      return "";
    default:
      return "";
  }
};
