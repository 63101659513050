import { useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import { BlockSize } from "@components/cFContentArea";
import { getNowDate } from "@utils/dateHelpers/getNowDate";
import { setLocalTimezone } from "@utils/dateHelpers/setLocalTimezone";

import { Context } from "types/context/Context";

import quizBlockStyles from "./QuizBlock.module.css";
import QuizQuestions from "./QuizQuestions/QuizQuestions";
import QuizStep from "./QuizStep/QuizStep";
import { quizStartTracker } from "./QuizTracker";
import OrgaizationTestResult from "./Result/OrganizationTestResult/OrgaizationTestResult";
import QuizResult from "./Result/QuizResult/QuizResult";
import { QuizBlockType_quizBlock } from "./queries/contentfulTypes/QuizBlockType";

interface IQuizBlockProps {
  blockData?: QuizBlockType_quizBlock;
  context: Context;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const QuizBlock = ({ blockData: quizBlock, context }: IQuizBlockProps) => {
  const cookies = new Cookies();
  const [quizState, setQuizState] = useState(0);
  const [userQuizAnswers, setUserQuizAnswers] = useState(
    Array<string>(quizBlock?.questionAreaCollection?.total)
  );
  const [userCorrectAnswers, setUserCorrectAnswers] = useState(0);
  const [initWithOpenForm, setInitWithOpenForm] = useState(false);
  const containerRef = useRef(null);
  const questions = quizBlock?.questionAreaCollection?.items;

  const now = getNowDate();
  const localStartDate = quizBlock?.quizStartDate && setLocalTimezone(quizBlock?.quizStartDate);
  const localEndDate = quizBlock?.quizEndDate && setLocalTimezone(quizBlock?.quizEndDate);
  const availableQuizForm =
    quizBlock?.quizStartDate &&
    quizBlock?.quizEndDate &&
    localStartDate <= now &&
    localEndDate >= now
      ? true
      : false;
  const userHasParticipated = cookies.get(`Participation_${quizBlock?.sys?.id}`);

  const quizConclusions: Array<string> = (questions || []).map((question) => {
    switch (question.correctAnswer) {
      case "Svarsalternativ 1":
        return question.answerOne;
      case "Svarsalternativ 2":
        return question.answerTwo;
      case "Svarsalternativ 3":
        return question.answerThree;
      case "Svarsalternativ 4":
        return question.answerFour;
      default:
        throw new Error(`Unsupported value for correctAnswer: ${question.correctAnswer}`);
    }
  });

  const handleQuizStepChange = (increase: boolean, calculateAnswers?: boolean) => {
    let correctAnswers = 0;
    if (calculateAnswers) {
      for (let i = 0; i < userQuizAnswers.length; ++i) {
        if (userQuizAnswers[i] === quizConclusions[i]) {
          correctAnswers++;
        }
      }
      setUserCorrectAnswers(correctAnswers);
    }
    setQuizState(increase ? quizState + 1 : quizState - 1);
    if (increase && quizState + 1 > questions?.length) {
      sessionStorage.setItem(`${quizBlock?.sys?.id}-answers`, JSON.stringify(userQuizAnswers));
    }
    containerRef?.current?.focus();
  };

  useEffect(() => {
    const userStoredAnswers = JSON.parse(sessionStorage.getItem(`${quizBlock?.sys?.id}-answers`));
    const userStoredModalOpen = JSON.parse(
      sessionStorage.getItem(`${quizBlock?.sys?.id}-modal-open`)
    );

    if (userStoredAnswers) {
      let correctAnswers = 0;
      for (let i = 0; i < userStoredAnswers.length; ++i) {
        if (userStoredAnswers[i] === quizConclusions[i]) {
          correctAnswers++;
        }
      }
      setUserQuizAnswers(userStoredAnswers);
      setUserCorrectAnswers(correctAnswers);
      setQuizState(userStoredAnswers?.length + 1);
      if (userStoredModalOpen) {
        setInitWithOpenForm(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuizAnswer = (index: number, answer: string) => {
    const newAnswers = [...userQuizAnswers];
    newAnswers[index] = answer;
    setUserQuizAnswers(newAnswers);
  };

  const handleQuizReset = () => {
    setQuizState(0);
    setUserCorrectAnswers(0);
    setUserQuizAnswers(new Array<string>(quizBlock?.questionAreaCollection?.total));
    sessionStorage.removeItem(`${quizBlock?.sys?.id}-answers`);
    sessionStorage.removeItem(`${quizBlock?.sys?.id}-modal-open`);
  };

  const buttons = (isNotebookDown: boolean) => (
    <div
      className={`${quizBlockStyles.buttonWrapper} ${
        isNotebookDown ? "" : quizBlockStyles.desktopWrapper
      }`}
    >
      <Button
        buttonType="primary"
        margin="0rem"
        onClick={() => {
          handleQuizStepChange(true);
          quizStartTracker(quizBlock?.quizType);
        }}
        label={quizBlock?.startViewButtonText}
      />
    </div>
  );

  return (
    <div
      className={`${quizBlockStyles.quizBlock} ${
        context?.sectionBlock?.coloredSection ? "" : quizBlockStyles.border
      } ${
        context?.sizes?.desktop === BlockSize.Full || context?.sizes?.notebook === BlockSize.Full
          ? "full-width"
          : ""
      }`}
      ref={containerRef}
      tabIndex={-1}
      data-temp-keep-index
      data-contentful-entry-id={quizBlock.sys?.id}
      data-contentful-field-id="name"
    >
      <>
        {quizState === 0 ? (
          <QuizStep
            ivImage={quizBlock?.startViewIvImage?.[0]}
            children={
              <>
                <Heading variant="h3" children={quizBlock?.startViewTitle} removeMargin />
                <Text variant="span" children={quizBlock?.startViewText} />
                {buttons(false)}
              </>
            }
            notebookDownButtonWrapper={buttons(true)}
          />
        ) : quizState > 0 && quizState <= questions?.length ? (
          <QuizQuestions
            questions={questions}
            currentQuestion={quizState}
            handleQuestionChange={handleQuizStepChange}
            userQuizAnswers={userQuizAnswers}
            handleQuizAnswer={handleQuizAnswer}
            formLabel={quizBlock?.startViewTitle}
            quizType={quizBlock?.quizType}
          />
        ) : quizState > questions?.length ? (
          <>
            {quizBlock?.quizType === "Quiz" ? (
              <QuizResult
                questions={questions}
                userQuizAnswers={userQuizAnswers}
                quizConclusions={quizConclusions}
                userCorrectAnswers={userCorrectAnswers}
                resultInsufficientMessage={quizBlock?.resultInsufficientMessage}
                resultBronzeMessage={quizBlock?.resultBronzeMessage}
                resultSilverMessage={quizBlock?.resultSilverMessage}
                resultGoldMessage={quizBlock?.resultGoldMessage}
                bronzeImage={quizBlock?.resultBronzeIvImage && quizBlock?.resultBronzeIvImage?.[0]}
                silverImage={quizBlock?.resultSilverIvImage && quizBlock?.resultSilverIvImage?.[0]}
                goldImage={quizBlock?.resultGoldIvImage && quizBlock?.resultGoldIvImage?.[0]}
                resultPageContactButtonText={quizBlock?.resultPageContactButtonText}
                resultPageConclusionButtonText={quizBlock?.resultPageConclusionButtonText}
                availableQuizForm={availableQuizForm && !userHasParticipated}
                competitionExpirationDate={localEndDate}
                quizId={quizBlock?.sys?.id}
                showForm={initWithOpenForm}
                containerRef={containerRef}
                quizType={quizBlock?.quizType}
              />
            ) : (
              <OrgaizationTestResult
                questions={questions}
                userCorrectAnswers={userCorrectAnswers}
                resetQuiz={() => handleQuizReset()}
                resultInsufficientMessage={quizBlock?.resultInsufficientMessage}
                resultBronzeMessage={quizBlock?.resultBronzeMessage}
                resultSilverMessage={quizBlock?.resultSilverMessage}
                resultGoldMessage={quizBlock?.resultGoldMessage}
                bronzeImage={quizBlock?.resultBronzeIvImage && quizBlock?.resultBronzeIvImage?.[0]}
                silverImage={quizBlock?.resultSilverIvImage && quizBlock?.resultSilverIvImage?.[0]}
                goldImage={quizBlock?.resultGoldIvImage && quizBlock?.resultGoldIvImage?.[0]}
                availableQuizForm={availableQuizForm}
                quizId={quizBlock?.sys?.id}
                showForm={initWithOpenForm}
                containerRef={containerRef}
                quizType={quizBlock?.quizType}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default QuizBlock;
