import { LandingPageType_landingpage_heroContentAreaCollection_items } from "@containers/Landingpage/queries/contentfulTypes/LandingPageType";

import { HeroBlockType } from "types/HeroBlock";

const getHeroData = (
  heroItems: LandingPageType_landingpage_heroContentAreaCollection_items[],
  heading: string,
  ingress: string,
  contentfulPreviewEntryId: string
): HeroBlockType => {
  const shortCutsBlock = heroItems.find((item) => Object.keys(item)[0] === "shortCutsBlock");
  const imageBlock = heroItems.find((item) => Object.keys(item)[0] === "imageBlock");
  if (!imageBlock) return null;

  const heroBlock: HeroBlockType = {
    heading: heading,
    ingress: ingress,
    shortcutLinks: shortCutsBlock || null,
    image: imageBlock,
    contentfulPreviewEntryId: contentfulPreviewEntryId,
  };

  return heroBlock;
};

const getHeroShortcutLinkColor = (index: number) => {
  if (index === 2 || index === 3) return "#37E164";
  if (index === 4 || index === 5) return "#003CD2";
  return "#C8EBFA";
};

export { getHeroData, getHeroShortcutLinkColor };
