import * as ga from "../../lib/ga";

export const accordionTracker = (accordionTitle: string, insurancePage: string = "") => {
  const trackingData = {
    event: "select_content",
    event_parameters: {
      page_location: window.location.href,
      feature_block_namn: "expanderbart block",
      content_type: accordionTitle?.toLowerCase(),
      försäkring: insurancePage && insurancePage?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};
