import { ImageMetaData } from "types/ImageMetaData";

export const getImageMetaData = (imageData: any): ImageMetaData => {
  if (imageData?.Metadata?.[0]) {
    const photographerName = imageData?.Metadata?.find(
      (item: any) => item?.Name === "Fotograf"
    )?.Value;

    const metadata = {
      altText: imageData?.Metadata?.find((item: any) => item?.Name === "_alt-text")?.Value || "",
      imageText:
        imageData?.Metadata?.find((item: any) => item?.Name === "_Bildtext som visas")?.Value || "",
      //Bildens rubrik på webb
      imageHeading:
        imageData?.Metadata?.find((item: any) => item?.MetadataDefinitionId === 81)?.Value || "",
      photographer: photographerName || "",
    } as ImageMetaData;
    return metadata;
  }
  return;
};
