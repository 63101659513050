import Link from "next/link";
import { ChangeEvent, RefObject, useEffect, useId, useRef, useState } from "react";
import { setTimeout } from "timers";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Icon from "@afa-shared/afa-components/dist/Icon";
import RadioButton from "@afa-shared/afa-components/dist/Inputs/RadioButton";
import Text from "@afa-shared/afa-components/dist/Text";
import AfaTooltip from "@components/AfaTooltip/Index";
import { getRoleById, getSectorById } from "@utils/targetGroup/targetGroupHelper";
import targetGroupOptions from "@utils/targetGroup/targetGroupOptions.json";

import { useEscape } from "hooks/useEscape";
import { useTab } from "hooks/useTab";
import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { IRole, ISector } from "types/PersonalInfo";

import CloseSection from "../CloseSection";
import Select from "../Select";
import { sectorTracker } from "../sectorTracker";
import { hasSetAudience } from "../services/hasSetAudience";
import sectorPickerStyles from "./SectorPicker.module.css";

interface ISelectorPickerProps {
  onSelectChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onRoleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  submit?: () => void;
  formValid?: boolean;
  selectedSector?: ISector;
  selectedRole?: IRole;
  openClose?: (closedBySubmit: boolean) => void;
  sectorToggleRef: RefObject<any>;
}

const SectorPicker = ({
  submit = () => {},
  onRoleChange,
  onSelectChange,
  formValid,
  selectedRole,
  selectedSector,
  openClose = () => {},
  sectorToggleRef,
}: ISelectorPickerProps): JSX.Element => {
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  const hasSetAudienceBefore = hasSetAudience(selectedRole?.roleId, selectedSector?.sectorId);
  const personalInfo = useSectorToggler((state: ISectorToggler) => state.selectedSector);

  const selectId = useId();
  const radioGroupId = useId();

  const refs = {
    closeBtn: useRef(null),
    helpLink: useRef(null),
    rolePrivate: useRef(null),
    roleEmployer: useRef(null),
    sector: useRef(null),
    cancelBtn: useRef(null),
    confirmBtn: useRef(null),
    topHidden: useRef(null),
    bottomHidden: useRef(null),
  };

  const sectorOptions = [];
  targetGroupOptions?.sectors?.map((sector) => {
    sectorOptions.push({
      name: sector?.sectorGuideModal,
      value: sector?.sectorId,
    });
  });

  useEffect(() => {
    refs?.topHidden?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEscape(
    sectorToggleRef,
    () => {
      openClose(false);
      sectorToggleRef?.current?.focus();
    },
    !tooltipActive
  );

  useTab(
    () => {
      if (
        (!selectedRole?.roleId &&
          !formValid &&
          (document?.activeElement === refs?.rolePrivate?.current ||
            document?.activeElement === refs?.roleEmployer?.current)) ||
        (selectedRole?.roleId && !formValid && document?.activeElement === refs?.sector?.current) ||
        (!hasSetAudienceBefore &&
          selectedRole?.roleId &&
          formValid &&
          document?.activeElement === refs?.confirmBtn?.current) ||
        (hasSetAudienceBefore &&
          selectedRole?.roleId &&
          formValid &&
          (selectedRole?.roleId !== personalInfo?.role?.roleId ||
            selectedSector?.sectorId !== personalInfo?.sector?.sectorId) &&
          document?.activeElement === refs?.confirmBtn?.current) ||
        (hasSetAudienceBefore &&
          selectedRole?.roleId &&
          formValid &&
          selectedRole?.roleId === personalInfo?.role?.roleId &&
          selectedSector?.sectorId === personalInfo?.sector?.sectorId &&
          document?.activeElement === refs?.cancelBtn?.current)
      ) {
        refs?.topHidden?.current?.focus();
      }
    },
    () => {
      if (document?.activeElement === refs?.closeBtn?.current) {
        refs?.bottomHidden?.current?.focus();
      }
    }
  );

  const handleRoleRadioClick = () => {
    setTimeout(() => {
      const element = document.querySelector("#scollForSectorSection");
      element.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  const trackLinkClick = () => {
    const sector = getSectorById(personalInfo?.sector?.sectorId);
    const role = getRoleById(personalInfo?.role?.roleId);
    sectorTracker(
      sector?.defaultName ? sector?.defaultName : "",
      role?.defaultName ? role?.defaultName : "",
      "förklara mer",
      "målgruppsväljare_pop_up"
    );
  };

  return (
    <>
      <div className={sectorPickerStyles.sectorPicker} id="sectorGuideContainer">
        <div className={sectorPickerStyles.darken} />
        <div ref={refs?.topHidden} tabIndex={-1} data-temp-keep-index />
        <div className={sectorPickerStyles.modalWrapper} role="dialog" aria-modal="true">
          <div className={sectorPickerStyles.sectorGuide}>
            <div className={sectorPickerStyles.overlayHelper}>
              <div className={sectorPickerStyles.closeWrapper}>
                <CloseSection
                  close={() => openClose(false)}
                  noMargin
                  closeButtonRef={refs?.closeBtn}
                />
              </div>

              <div className={`${sectorPickerStyles.infoCard}`}>
                <div className={sectorPickerStyles.infoWrapper}>
                  <Heading className={sectorPickerStyles.heading} variant="h2" removeMargin>
                    Ställ in information om din anställning
                  </Heading>
                  <Text variant="paragraph" className={sectorPickerStyles.paragraph}>
                    Vilka kollektivavtalade försäkringar du har beror på var du jobbar. Välj var du
                    är anställd för att se korrekt information.
                  </Text>
                  <div className={sectorPickerStyles.linkSection}>
                    <Icon name="arrow_forward" size="sm" referenceSize="link" />
                    <Link
                      href={"/kundtjanst/guider-och-instruktioner/anpassa-forsakringsinformation"}
                      prefetch={false}
                      onClick={() => trackLinkClick()}
                      ref={refs?.helpLink}
                    >
                      Vad betyder de olika valen?
                    </Link>
                  </div>
                </div>

                <div
                  className={`${sectorPickerStyles.roleChoiceWrapper} ${
                    selectedRole?.roleId ? sectorPickerStyles.isValid : ""
                  }`}
                >
                  <fieldset className={sectorPickerStyles.fieldSet} id={radioGroupId}>
                    <div className={sectorPickerStyles.fieldHeadingWrapper}>
                      <legend
                        id="sector-popup-tooltip-heading-role"
                        className={sectorPickerStyles.fieldLabel}
                      >
                        I vilken roll besöker du oss?
                      </legend>
                      <AfaTooltip
                        label="Hjälp"
                        showLabel={true}
                        iconAriaLabel="Hjälp"
                        tooltipContent={
                          <div className={sectorPickerStyles.tooltipText}>
                            <Text variant="paragraph">
                              Välj <span className={sectorPickerStyles.bold}>privatperson</span> om
                              du har eller har haft en anställning eller är egenföretagare. Även du
                              som är anhörig väljer detta alternativ.
                            </Text>
                            <Text variant="paragraph">
                              Välj{" "}
                              <span className={sectorPickerStyles.bold}>
                                arbetsgivare eller facklig representant
                              </span>{" "}
                              när du i ditt jobb, i ditt fackliga uppdrag eller som
                              försäkringsinformatör vill uträtta ett ärende eller söka information
                              åt dig själv eller någon annan.
                            </Text>
                          </div>
                        }
                        labelSize={"normal"}
                        tooltipPositionX="right"
                        tooltipPositionY="up"
                        ariaLabelledBy="sector-popup-tooltip-heading-role"
                        setParentOpenState={setTooltipActive}
                        className={sectorPickerStyles.tooltipSector}
                      />
                    </div>

                    <ul className={sectorPickerStyles.roleList}>
                      {targetGroupOptions?.roles?.map((radioButton) => (
                        <li key={radioButton.roleId}>
                          <RadioButton
                            name={"roleOption"}
                            label={radioButton?.sectorGuideModal}
                            onChange={onRoleChange}
                            onClick={handleRoleRadioClick}
                            value={radioButton?.roleId}
                            checked={radioButton?.roleId === selectedRole?.roleId}
                            className={sectorPickerStyles.radioButton}
                            inputRef={
                              radioButton?.roleId === "privatperson"
                                ? refs?.rolePrivate
                                : refs?.roleEmployer
                            }
                            dataTestId={"sector-popup-role-radiobutton-" + radioButton?.roleId}
                          />
                        </li>
                      ))}
                    </ul>
                  </fieldset>
                </div>

                {selectedRole?.roleId && (
                  <div
                    className={`${sectorPickerStyles.sectorChoiceWrapper} ${
                      selectedRole?.roleId && selectedSector?.sectorId
                        ? sectorPickerStyles.isValid
                        : ""
                    }`}
                    id="scollForSectorSection"
                  >
                    <div className={sectorPickerStyles.fieldHeadingWrapper}>
                      <label
                        id="sector-popup-tooltip-heading-sector"
                        htmlFor={selectId}
                        className={sectorPickerStyles.fieldLabel}
                      >
                        Var är du anställd?
                      </label>
                      <AfaTooltip
                        label="Hjälp"
                        showLabel={true}
                        iconAriaLabel="Hjälp"
                        tooltipContent={
                          <div className={sectorPickerStyles.tooltipText}>
                            <Text variant="paragraph">
                              Som <span className={sectorPickerStyles.bold}>arbetare</span> jobbar
                              du exempelvis på lager, som städare eller personlig assistent, i
                              butik, på bygge eller kör lastbil. Andra exempel är inom restaurang
                              eller hotell.
                            </Text>
                            <Text variant="paragraph">
                              Som <span className={sectorPickerStyles.bold}>tjänsteman</span> jobbar
                              du ofta på kontor, kanske med ekonomi eller IT. Ibland som chef eller
                              arbetsledare.
                            </Text>
                          </div>
                        }
                        labelSize={"normal"}
                        tooltipPositionX="right"
                        tooltipPositionY="up"
                        ariaLabelledBy="sector-popup-tooltip-heading-sector"
                        setParentOpenState={setTooltipActive}
                      />
                    </div>
                    <Select
                      options={sectorOptions}
                      id={selectId}
                      placeholder={"Välj"}
                      onChange={onSelectChange}
                      selected={selectedSector?.sectorId}
                      selectFieldRef={refs?.sector}
                    />
                  </div>
                )}
                {formValid && (
                  <div className={sectorPickerStyles.submitSection} id="scrollForSubmit">
                    <Button
                      id="cancel"
                      label="Avbryt"
                      onClick={() => openClose(false)}
                      buttonType="secondary"
                      buttonRef={refs?.cancelBtn}
                      className={sectorPickerStyles.roleButton}
                    />
                    {(selectedRole?.roleId !== personalInfo?.role?.roleId ||
                      selectedSector?.sectorId !== personalInfo?.sector?.sectorId) && (
                      <Button
                        label="Bekräfta"
                        onClick={submit}
                        buttonType="primary"
                        buttonRef={refs?.confirmBtn}
                        dataTestId={"sector-popup-confirm-button"}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={refs?.bottomHidden} tabIndex={-1} data-temp-keep-index />
    </>
  );
};

export default SectorPicker;
