import { getBaseUrl, replaceImageVaultBasePath } from "@utils/urlResolver";

export interface IVImage {
  url: string;
  width: number;
  height: number;
  imageAltText?: string;
}

enum ImageType {
  mainImage = 0,
  metaImage = 1,
  complementImageOne = 2,
  complementImageTwo = 3,
}

const defaultImage = {
  url: "/images/teaser-default-image.svg",
  width: 1240,
  height: 720,
  imageAltText: "Afa Försäkring",
} as IVImage;

const socialMediaDefaultImage = {
  url: "/images/social-media-default-image.jpg",
  width: 1200,
  height: 627,
  imageAltText: "Afa Försäkring",
} as IVImage;

const checkWebP = () => {
  return document.body.classList.contains("webp");
};

const getExtensionFromImage = (url: string) => {
  return url?.split(".").pop();
};

const getMimeTypeFromImage = (url: string) => {
  const ext = getExtensionFromImage(url);
  switch (ext) {
    case "svg":
      return "image/svg+xml";
    case "jpg":
      return "image/jpeg";
    default:
      return `image/${ext}`;
  }
};

export function metaImageUrl(pageSeo: any): string {
  const imageUrl = replaceImageVaultBasePath(
    pageSeo?.ivSocialMediaImage?.[0]?.socialMediaImageVersion,
    false
  );
  return `${getBaseUrl()}${imageUrl || socialMediaDefaultImage?.url}`;
}

export {
  ImageType,
  defaultImage,
  socialMediaDefaultImage,
  checkWebP,
  getMimeTypeFromImage,
  getExtensionFromImage,
};
