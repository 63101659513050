import { IVImage, checkWebP } from "./imageHelper";

export const getPortraitImage = (imageData: any): IVImage => {
  if (checkSVG(imageData)) {
    return {
      url: imageData?.thumbUrl,
      width: 375,
      height: 375,
      imageAltText: imageData?.altText,
    } as IVImage;
  }

  if (checkWebP) {
    const image = getPortraitImageWebP(imageData);
    if (image) {
      return image;
    }
  }
  return getPortraitImageFallback(imageData);
};

const getPortraitImageWebP = (imageData: any): IVImage => {
  return imageData?.Size375webP
    ? ({
        url: imageData?.Size375webP,
        width: 375,
        height: 375,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
};

const getPortraitImageFallback = (imageData: any): IVImage => {
  const desktopImage = imageData?.mediaUrl
    ? ({
        url: imageData?.mediaUrl,
        width: 768,
        height: 768,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const image768 = imageData?.Size768
    ? ({
        url: imageData?.Size768,
        width: 768,
        height: 768,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const image375 = imageData?.Size375
    ? ({
        url: imageData?.Size375,
        width: 375,
        height: 375,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;
  const thumbnailImage = imageData?.backup16x9Format
    ? ({
        url: imageData?.backup16x9Format,
        width: 375,
        height: 375,
        imageAltText: imageData?.altText,
      } as IVImage)
    : null;

  return image375 || thumbnailImage || image768 || desktopImage;
};

const checkSVG = (image: any): Boolean => {
  return image?.thumbUrl?.endsWith(".svg");
};
