import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useId, useRef, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import FactCard from "@afa-shared/afa-components/dist/FactCard";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Checkbox from "@afa-shared/afa-components/dist/Inputs/Checkbox";
import Select from "@afa-shared/afa-components/dist/Inputs/Select";
import TextArea from "@afa-shared/afa-components/dist/Inputs/TextArea";
import TextInput from "@afa-shared/afa-components/dist/Inputs/TextInput";
import Text from "@afa-shared/afa-components/dist/Text";
import { FormProps } from "@components/FormBlock";
import TrackedLink from "@components/TrackedLink";
import { LinkType } from "@utils/linkIconHelper";

import FormErrorFeedback from "../Feedback/FormErrorFeedback";
import FormSendFeedback from "../Feedback/FormSendFeedback";
import FormSuccessFeedback from "../Feedback/FormSuccessFeedback";
import FormValidationFeedback from "../Feedback/FormValidationFeedback";
import { emailMaxInputLength, messageMaxInputLength } from "../formConstants";
import { scrollIntoView, validate } from "../formHelper";
import { contactFormTracker, formTracker } from "../formTracker";
import formStyles from "../forms.module.css";

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ContactForm = ({ contentfulPreviewEntryId }: FormProps) => {
  const router = useRouter();
  const routerAsPath = router.asPath;
  const [formState, setFormState] = useState({
    email: { value: "", required: true },
    contactFormCaseType: { value: "", required: true },
    contactFormMessage: { value: "", required: true },
    sendCopy: { value: false },
  });

  const [previewing, setPreviewing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    email: null,
    contactFormCaseType: null,
    contactFormMessage: null,
    approved: null,
  });

  const [displayForm, setDisplayForm] = useState<boolean>(true);
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [errorSendMail, setErrorSendMail] = useState<boolean>(false);
  const [hasSingleError, setHasSingleError] = useState<boolean>(false);

  const formRefs = {
    sendFeedback: useRef(null),
    errorInfobox: useRef(null),
    email: useRef(null),
    contactFormCaseType: useRef(null),
    contactFormMessage: useRef(null),
    sendCopy: useRef(null),
    previewRef: useRef(null),
  };

  const formIds = {
    email: useId(),
    contactFormCaseType: useId(),
    contactFormMessage: useId(),
    header: useId(),
    sendCopy: useId(),
  };

  const caseTypes = [
    { id: 1, name: "Sjukdom", value: "Sjukdom" },
    { id: 2, name: "Arbetsskada", value: "Arbetsskada" },
    { id: 5, name: "Arbetsbrist", value: "Arbetsbrist" },
    { id: 3, name: "Dödsfall", value: "Dödsfall" },
    { id: 4, name: "Föräldraledighet", value: "Föräldraledighet" },
    { id: 6, name: "IA-systemet", value: "IA-systemet" },
    { id: 7, name: "Annat", value: "Annat" },
  ];

  const personuppgifterPath = "/personuppgifter";

  useEffect(() => {
    if (router.asPath.includes("#preview")) {
      const formErrors = validate(formState, errors);
      const foundErrors = Object.values(formErrors).filter((e) => e !== null).length;
      if (foundErrors) {
        setPreviewing(false);
        router.replace({ pathname: routerAsPath.replace("#preview", "") });
      } else {
        setPreviewing(true);
      }
    } else {
      setPreviewing(false);
    }
  }, [router.asPath, errors, formState, router, routerAsPath]);

  const goToPreview = (evt: any) => {
    evt.preventDefault();
    setHasSingleError(false);
    const formErrors = validate(formState, errors);
    setErrors(formErrors);
    const foundErrors = Object.values(formErrors).filter((e) => e !== null).length;

    if (foundErrors) {
      if (foundErrors === 1) {
        setHasSingleError(true);
        const key = Object.keys(formErrors).find((key) => formErrors[key] !== null);
        scrollIntoView(formRefs[key]);
      } else {
        scrollIntoView(formRefs?.errorInfobox);
      }
    } else {
      router.replace({ pathname: routerAsPath, hash: "preview" });
      scrollIntoView(formRefs?.previewRef);
    }
  };

  const editForm = () => {
    router.replace({ pathname: routerAsPath.replace("#preview", "") });
  };

  const handleFormChange = (e: any, key: string = null, sendCopyChange: boolean) => {
    if (sendCopyChange) {
      setFormState({
        ...formState,
        [key]: { ...formState[key], value: !formState[key].value },
      });
    } else {
      const { value } = e.target;
      setFormState({ ...formState, [key]: { ...formState[key], value: value } });
      setErrors({ ...errors, [key]: null });
    }
  };

  const handleErrorResponse = (): void => {
    setErrorSendMail(true);
    setDisplayForm(false);
    formTracker("felmeddelande", "kontakt");
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    let cleanFormState = {};
    for (const field in formState) {
      cleanFormState = { ...cleanFormState, [field]: formState[field].value };
    }
    setIsLoading(true);
    try {
      const res = await fetch("/api/sendmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cleanFormState),
      });

      if (res?.ok) {
        setDisplayForm(false);
        contactFormTracker("inskickat", formState["contactFormCaseType"].value);
        formRefs?.sendFeedback?.current?.focus();
      } else {
        handleErrorResponse();
      }

      setIsLoading(false);
      router.replace({ pathname: routerAsPath.replace("#preview", "") });
    } catch (err) {
      handleErrorResponse();
      setIsLoading(false);
    }
  };

  const handleReCaptchaVerify = (token) => {
    if (!token) {
      return;
    }
    token && setIsValidToken(true);
  };

  const renderErrorInfoBox = () => (
    <FormValidationFeedback
      formErrors={errors}
      formRefs={formRefs}
      hasSingleError={hasSingleError}
    />
  );
  const renderFormFeedback = () => (
    <FormSuccessFeedback
      messageHeading="Tack för ditt meddelande!"
      messageChildren={
        <>
          <Text
            variant="paragraph"
            children={
              "Du får svar inom två vardagar. Om du har begärt en kopia av meddelandet till din e-postadress kontrollera att den inte hamnat i din skräppost."
            }
          />
          <Text
            variant="paragraph"
            children={
              "För att skydda dina personuppgifter kan vi inte gå in i detalj på ditt ärende när vi svarar dig via e-post. När vi har avslutat ärendet raderar vi alla e-postmeddelanden."
            }
          />
        </>
      }
      messageLink={
        <TrackedLink
          url={personuppgifterPath}
          linkText={"Så behandlar vi personuppgifter"}
          linkType={LinkType.Internal}
          linkVariant={"link"}
          target={"_self"}
        />
      }
    />
  );
  const renderErrorMessage = () => (
    <FormErrorFeedback
      messageChildren={
        <>
          <Text
            variant="paragraph"
            children={
              "Vi kunde inte skicka iväg ditt meddelande på grund av att ett tekniskt fel har inträffat."
            }
          />
          <Text
            variant="paragraph"
            children={
              "Kontakta kundcenter istället på telefonnummer 0771-88 00 99. Öppettider: vardagar klockan 8 - 17."
            }
          />
        </>
      }
    />
  );

  const showFormFeedback: boolean = !displayForm && !errorSendMail;

  return (
    <Grid
      direction={"column"}
      className={`form-container`}
      data-contentful-entry-id={contentfulPreviewEntryId}
      data-contentful-field-id="name"
    >
      <GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
      {
        <FormSendFeedback
          formSendRef={formRefs?.sendFeedback}
          formErrorFeedback={renderErrorMessage()}
          formSuccessFeedback={renderFormFeedback()}
          showSuccessFeedback={showFormFeedback}
          showErrorFeedback={errorSendMail}
        />
      }
      <div id="preview"></div>
      {displayForm && previewing && (
        <div className={formStyles.formPreviewWrapper}>
          <FactCard
            heading="Kontrollera dina uppgifter"
            type={"blue"}
            headingVariant={"h2"}
            className={formStyles.formPreviewCard}
            data-blockname="faktaruta"
          >
            {/* Enables data suppression for hotjar */}
            <div className={"data-hj-suppress"}>
              <Text variant="span" weight="bold" className={`${formStyles.formPreviewQuestion}`}>
                Din e-postadress
              </Text>
              <Text variant="paragraph" className={formStyles.previewHelpText}>
                Kontrollera att du har stavat e-postadressen rätt - annars kan vi inte nå dig.
              </Text>
              <Text variant="paragraph" className={formStyles.formPreviewAnswer}>
                {formState?.email?.value || "-"}
              </Text>
              <Text variant="span" weight="bold" className={formStyles.formPreviewQuestion}>
                Frågan handlar om
              </Text>
              <Text variant="paragraph" className={formStyles.formPreviewAnswer}>
                {formState?.contactFormCaseType?.value || "-"}
              </Text>
              <Text variant="span" weight="bold" className={formStyles.formPreviewQuestion}>
                Beskriv hur vi kan hjälpa dig
              </Text>
              <Text variant="paragraph" className={formStyles.previewHelpText}>
                Om frågan gäller ett försäkringsärende ring kundtjänst 
                <Link href={"tel:+46771880099"} className={formStyles.previewHelpTextLink}>
                  0771-88 00 99
                </Link>
              </Text>
              <Text variant="paragraph" className={formStyles.formPreviewAnswer}>
                {formState?.contactFormMessage?.value || "-"}
              </Text>
            </div>
            <Button
              buttonType="secondary"
              onClick={() => editForm()}
              label={"Ändra"}
              className={formStyles.editButton}
              iconName="arrow_back"
            />
          </FactCard>
          <Checkbox
            inputRef={formRefs?.sendCopy}
            checked={formState?.sendCopy?.value}
            label="Ja tack! Skicka en kopia av meddelandet till min e&#8209;postadress."
            onChange={() => handleFormChange(null, "sendCopy", true)}
            id={formIds?.sendCopy}
            isWrapped
            onClick={() => handleFormChange(null, "sendCopy", true)}
            className={formStyles.sendCopyCheck}
          />
          <Button
            type="submit"
            buttonType="primary"
            onClick={
              isValidToken
                ? onSubmit
                : (e) => {
                    e.preventDefault();
                  }
            }
            label={isLoading ? "" : "Skicka"}
            className={clsx(formStyles.submitPreviewButton, { [formStyles.spinner]: isLoading })}
          />
        </div>
      )}
      {displayForm && !previewing && (
        <div className={`${formStyles.formBackground} ${formStyles.formWrapper}`}>
          <div className={formStyles.formHeadingWrapper}>
            <Heading id={formIds?.header} variant="h2" removeMargin>
              Ditt meddelande
            </Heading>
            <Text variant="paragraph" removeMargin>
              * Obligatoriskt fält
            </Text>
          </div>
          {renderErrorInfoBox()}
          <form aria-labelledby={formIds?.header} noValidate className={formStyles.form}>
            {/* STEP ONE */}
            <div className={formStyles.formStep}>
              <div>
                <TextInput
                  label={"Din e-postadress *"}
                  description="Kontrollera att du har stavat e-postadressen rätt - annars kan vi inte nå dig."
                  inputRef={formRefs?.email}
                  required
                  id={formIds?.email}
                  autoComplete="email"
                  value={formState?.email?.value}
                  type={"email"}
                  maxLength={emailMaxInputLength}
                  width={"100%"}
                  fullWidth
                  error={errors?.email?.input}
                  onChange={(e) => handleFormChange(e, "email", false)}
                />
              </div>
            </div>
            <div className={formStyles.formStep}>
              {/* STEP TWO */}
              <Select
                inputRef={formRefs?.contactFormCaseType}
                labelText={"Frågan handlar om *"}
                required
                placeholder="Välj ämne"
                options={caseTypes}
                error={errors?.contactFormCaseType?.input}
                id={formIds?.contactFormCaseType}
                selected={formState?.contactFormCaseType?.value || ""}
                onChange={(e) => {
                  handleFormChange(e, "contactFormCaseType", false);
                  setErrors({ ...errors, ["contactFormCaseType"]: null });
                }}
                width={"100%"}
              />
              <div>
                <Text variant="paragraph" removeMargin className={formStyles.textAreaHeadingMargin}>
                  Beskriv hur vi kan hjälpa dig *
                </Text>
                <TextArea
                  textAreaRef={formRefs?.contactFormMessage}
                  required
                  description={
                    <p>
                      Om du har frågor om ett försäkringsärende måste du ringa kundtjänst 
                      <Link href={"tel:+46771880099"} style={{ color: "#020678" }}>
                        0771-88 00 99
                      </Link>
                      . Sådana frågor får inte skickas eller besvaras via e-post.
                    </p>
                  }
                  id={formIds?.contactFormMessage}
                  value={formState?.contactFormMessage?.value}
                  name="contactFormMessage"
                  width={"100%"}
                  maxLength={messageMaxInputLength}
                  rows={8}
                  error={errors?.contactFormMessage?.input}
                  onChange={(e) => handleFormChange(e, "contactFormMessage", false)}
                />
              </div>
            </div>
            <TrackedLink
              url={personuppgifterPath}
              linkText={"Så behandlar vi personuppgifter"}
              linkType={LinkType.Internal}
              linkVariant={"link"}
              target={"_self"}
            />
            <Button
              buttonType="primary"
              //router.pushstate
              onClick={(e) => goToPreview(e)}
              label={"Nästa"}
              className={formStyles.submitButton}
            />
          </form>
        </div>
      )}
    </Grid>
  );
};
export default ContactForm;
