import { PaymentDay } from "contexts/PaymentDaysContext";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";

import paymentDaysBlockStyles from "./PaymentDaysBlock.module.css";
import { PaymentDate as PaymentDateType, getClosestPaymentDayAsObject } from "./paymentDaysHelper";
import { PaymentDaysBlockType_paymentDaysBlock } from "./queries/contentfulTypes/PaymentDaysBlockType";

interface PaymentDateProps {
  nextPayment: PaymentDateType;
}

interface PaymentDaysCalendarViewProps {
  paymentDaysDaily: PaymentDay[];
  paymentDaysMonthly: PaymentDay[];
  paymentDaysParental: PaymentDay[];
  blockData: PaymentDaysBlockType_paymentDaysBlock;
}

const PaymentDate = ({ nextPayment }: PaymentDateProps) => {
  if (!nextPayment) {
    return null;
  }
  return (
    <Grid
      direction="column"
      alignItems="center"
      className={paymentDaysBlockStyles.paymentDateContainer}
      aria-label={`${nextPayment.dayOfWeek} ${nextPayment.dayOfMonth} ${nextPayment.month}`}
    >
      <Text
        variant="paragraph"
        className={`${paymentDaysBlockStyles.paymentDateText} ${paymentDaysBlockStyles.capitalize}`}
        aria-hidden="true"
      >
        {nextPayment.dayOfWeek}
      </Text>
      <Text variant="paragraph" className={paymentDaysBlockStyles.paymentDate} aria-hidden="true">
        {nextPayment.dayOfMonth}
      </Text>
      <Text
        variant="paragraph"
        className={`${paymentDaysBlockStyles.paymentDateText} ${paymentDaysBlockStyles.capitalize}`}
        aria-hidden="true"
      >
        {nextPayment.month}
      </Text>
    </Grid>
  );
};

export const PaymentDaysCalendarView = ({
  paymentDaysMonthly,
  paymentDaysDaily,
  paymentDaysParental,
  blockData,
}: PaymentDaysCalendarViewProps) => {
  const nextMonthlyPaymentDay = getClosestPaymentDayAsObject(paymentDaysMonthly);
  const nextDailyPaymentDay = getClosestPaymentDayAsObject(paymentDaysDaily);
  const nextParentalBenefitPaymentDay = getClosestPaymentDayAsObject(paymentDaysParental);

  return (
    <Grid
      mobile={12}
      tablet={12}
      desktop={12}
      className={paymentDaysBlockStyles.paymentDaysGrid}
      data-contentful-entry-id={blockData.sys?.id}
      data-contentful-field-id="name"
    >
      <Heading variant="h2">Nästa utbetalningsdag</Heading>
      <div className={paymentDaysBlockStyles.paymentDaysCalendarView}>
        <Grid className={paymentDaysBlockStyles.container} tabIndex={0}>
          <Heading
            variant={"h3"}
            removeMargin
            className={`${paymentDaysBlockStyles.heading} ${paymentDaysBlockStyles.firstHeading}`}
          >
            Sjukförsäkring
          </Heading>
          <Grid direction="row">
            <Grid direction="column" className={paymentDaysBlockStyles.paymentWrapper}>
              <Text
                variant="paragraph"
                align="center"
                removeMargin
                className={paymentDaysBlockStyles.compensationTypeText}
              >
                Månadsersättning
              </Text>
              <PaymentDate nextPayment={nextMonthlyPaymentDay} />
            </Grid>
            <div className={paymentDaysBlockStyles.paymentWrapper}>
              <Text
                variant="paragraph"
                align="center"
                removeMargin
                className={paymentDaysBlockStyles.compensationTypeText}
              >
                Dagsersättning
              </Text>
              <PaymentDate nextPayment={nextDailyPaymentDay} />
            </div>
          </Grid>
        </Grid>
        <Grid className={paymentDaysBlockStyles.parentalPaymentContainer} tabIndex={0}>
          <Heading
            variant="h3"
            removeMargin
            align="center"
            className={`${paymentDaysBlockStyles.heading} ${paymentDaysBlockStyles.secondHeading}`}
          >
            Föräldrapenningtillägg
          </Heading>
          <PaymentDate nextPayment={nextParentalBenefitPaymentDay} />
        </Grid>
      </div>
    </Grid>
  );
};
export default PaymentDaysCalendarView;
