import Grid from "@afa-shared/afa-components/dist/Grid";

import RichTextRenderer from "../RichTextRenderer";
import { RichTextBlockType_richTextBlock } from "./queries/contentfulTypes/RichTextBlockType";

interface IRichTextBlockProps {
  blockData: RichTextBlockType_richTextBlock;
  uniqueKey?: string;
}

export const RichTextBlock = ({ blockData: richTextBlock }: IRichTextBlockProps) => {
  return (
    <Grid
      container
      data-blockname="textyta"
      data-contentful-entry-id={richTextBlock.sys.id}
      data-contentful-field-id="bodytext"
    >
      <RichTextRenderer
        json={richTextBlock?.bodytext?.json}
        links={richTextBlock?.bodytext?.links}
      />
    </Grid>
  );
};
export default RichTextBlock;
