import * as ga from "../../lib/ga";

export const navigationTracker = (item: string, section: string) => {
  const trackingData = {
    event: "navigation",
    event_parameters: {
      page_location: window.location.href,
      navigationstyp: "submeny",
      sektion: section?.toLowerCase(),
      select_navigation: item?.toLowerCase(),
    },
  };
  ga.gaTracking(trackingData);
};
