import create from "zustand";
import { devtools } from "zustand/middleware";

export interface IPageInfo {
  pageName: string;
  firstLevelPageName: string;
  secondLevelPageName: string;
  pageRefferer: string;
  isCoveredByInsurance: string;
  insuranceName: string;
}

export type PageInfo = {
  pageInfo: any;
  pageInfoLoading: boolean;
  setPageInfo: (info: IPageInfo) => void;
};
export const usePageInfo = create<PageInfo>(
  devtools(
    (set) => ({
      pageInfo: {
        pageName: "",
        firstLevelPageName: "",
        secondLevelPageName: "",
        pageRefferer: "",
        isCoveredByInsurance: "",
        insuranceName: "",
      },
      pageInfoLoading: true,
      setPageInfo: (pageInfo: any) =>
        set((state: PageInfo) => ({ pageInfo, pageInfoLoading: false })),
    }),
    { name: "PAGE_INFO" }
  )
);
