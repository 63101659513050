import { getImageBlockImage } from "@utils/imageHelpers/imageBlockImageHelper";
import { defaultImage } from "@utils/imageHelpers/imageHelper";
import { getImageMetaData } from "@utils/imageHelpers/metadataImageHelper";
import { replaceImageVaultBasePath } from "@utils/urlResolver";

import AfaImage from "./AfaImage";
import { IImageSrc } from "./ImageSources";

interface IImage {
  image: any;
  className?: string;
}

const IvImageBlockImage = ({ image, className }: IImage): JSX.Element => {
  const srcSet: IImageSrc[] = [
    {
      url: replaceImageVaultBasePath(getImageBlockImage(image, 374)?.url),
      media: "(max-width: 375px)",
    },
    { url: replaceImageVaultBasePath(getImageBlockImage(image, 768)?.url) },
  ];

  const metadata = getImageMetaData(image);

  return (
    <AfaImage
      fallbackUrl={
        replaceImageVaultBasePath(getImageBlockImage(image, 374)?.url) || defaultImage?.url
      }
      alt={metadata?.altText || defaultImage?.imageAltText}
      imageSrcSet={srcSet}
      className={className}
    />
  );
};

export default IvImageBlockImage;
