import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { RefObject, useEffect, useId, useRef, useState } from "react";

import LoginButton from "@afa-shared/afa-components/dist/Buttons/Login/LoginButton";
import HeaderLogo from "@afa-shared/afa-components/dist/HeaderLogo";
import HiddenLinkToContent from "@afa-shared/afa-components/dist/HiddenLinkToContent";
import TopBar from "@afa-shared/afa-components/dist/TopBar";
import TopBarNavLinks from "@afa-shared/afa-components/dist/TopBarNavLinks";
import AfaBreadCrumbs from "@components/AfaBreadcrumbs";
import OperationalInfo from "@components/OperationalInfo/OperationalInfo";
import { Preview } from "@components/Preview/preview";
import { pixelsToRem } from "@utils/sizeHelper";

import { useEscape } from "hooks/useEscape";
import useParamsToggler from "hooks/useParamToggler";

import Navigation from "../../../components/navigation";
import { TopbarNavlinksTracker, breadcumbsLinksTracker } from "./HeaderTracker";
import pageHeaderStyles from "./PageHeader.module.css";
import { BreadCrumbItem } from "./headerFactory";

interface IPageHeader {
  is404: boolean;
  siteHeaderData: any;
  preview: boolean;
  tabStartRef: RefObject<any>;
}

const PageHeader = ({ is404, siteHeaderData, preview, tabStartRef }: IPageHeader): JSX.Element => {
  const router = useRouter();
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [mobileLoginMenuOpen, setMobileLoginMenuOpen] = useState<boolean>(false);
  const mobileButtonRef = useRef(null);
  const mobileMenuId = useId();
  const [documentHeight, setDocumentHeight] = useState<number>(0);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const { isOpen, removeQueryParameter } = useParamsToggler();

  const getIsActive = (asPath: string, slug: string, item) => {
    let path = asPath.replace(/\?.+/, "");
    if (slug !== "" && `/${slug}` === path) {
      return "active";
    }
    if (path.startsWith("/forsakringshandboken") && slug === "forsakring") {
      return "in-active-path";
    }
    if (path.startsWith("/anslutna-utbildningsanordnare") && slug === "ohalsa-och-arbetsskador") {
      return "in-active-path";
    }
    if (path.startsWith("/filer") && slug === "kundtjanst") {
      return "in-active-path";
    }
    if (slug !== "" && path.startsWith("/" + slug)) {
      return "in-active-path";
    }

    return "";
  };

  useEffect(() => {
    const isLoadedInFrame = window.self !== window.top;
    if (preview && !isLoadedInFrame) {
      setShowPreview(true);
    }
  }, [preview]);

  useEffect(() => {
    mobileMenuOpen
      ? document.body.classList.add("mobile-menu-open")
      : document.body.classList.remove("mobile-menu-open");
  }, []);

  const topMenuLinks = siteHeaderData.topmenuLinks?.map((item: any, index: number) => {
    const slug = item?.url?.substring(1, item?.url?.length).split("/")[0];
    return (
      <Link
        key={index}
        href={item?.url}
        prefetch={false}
        className={getIsActive(router?.asPath, slug, item)}
        onClick={() => TopbarNavlinksTracker(item?.name, item?.name)}
        data-testid={`page-header-link-${slug}`}
      >
        {item?.name}
      </Link>
    );
  });

  const breadCrumbs = siteHeaderData?.breadCrumbLinks?.map(
    (breadcrumb: BreadCrumbItem, index: number) => {
      const slug = breadcrumb?.url.substring(breadcrumb?.url.lastIndexOf("/") + 1);
      return (
        <Link key={index} href={breadcrumb?.url} prefetch={false} legacyBehavior={true}>
          {index === siteHeaderData?.breadCrumbLinks?.length - 1 ? (
            <a
              href={breadcrumb?.url}
              onClick={() => breadcumbsLinksTracker(breadcrumb.name, breadcrumb.section)}
              aria-current="page"
              data-testid={`breadcrumb-link-${slug}`}
            >
              {breadcrumb?.name}
            </a>
          ) : (
            <a
              href={breadcrumb?.url}
              onClick={() => breadcumbsLinksTracker(breadcrumb.name, breadcrumb.section)}
              data-testid={`breadcrumb-link-${slug}`}
            >
              {breadcrumb?.name}
            </a>
          )}
        </Link>
      );
    }
  );

  const handleMenuToggle = () => {
    mobileMenuOpen
      ? document.body.classList.remove("mobile-menu-open")
      : document.body.classList.add("mobile-menu-open");
    setMobileMenuOpen(!mobileMenuOpen);
    removeQueryParameter("openSector");
    if (mobileLoginMenuOpen) {
      setMobileLoginMenuOpen(false);
      document.body.classList.remove("mobile-login-menu-open");
    }
    if (isOpen) {
      removeQueryParameter("openSector");
    }
  };

  const handleLoginMenuToggle = () => {
    removeQueryParameter("openSector");
    mobileLoginMenuOpen
      ? document.body.classList.remove("mobile-login-menu-open")
      : document.body.classList.add("mobile-login-menu-open");
    setMobileLoginMenuOpen(!mobileLoginMenuOpen);
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
      document.body.classList.remove("mobile-menu-open");
    }
  };

  const handleAfaLogoOnClick = () => {
    TopbarNavlinksTracker("afa logga", "afa logga");
    if (mobileMenuOpen) handleMenuToggle();
    if (mobileLoginMenuOpen) handleLoginMenuToggle();
  };

  const handleLoginClickOutside = () => {
    setMobileLoginMenuOpen(false);
    document.body.classList.remove("mobile-login-menu-open");
    // }
  };

  useEscape(
    mobileButtonRef,
    () => {
      mobileButtonRef?.current?.focus();
      handleMenuToggle();
    },
    mobileMenuOpen
  );

  useEffect(function observeDocumentHeight() {
    const resizeObserver = new ResizeObserver((entries) => {
      const doc = entries[0].target as HTMLElement;
      setDocumentHeight(doc.offsetHeight);
    });
    resizeObserver.observe(document.documentElement);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <header data-blockname="header" className={pageHeaderStyles.pageHeader}>
      <div ref={tabStartRef} tabIndex={-1} data-temp-keep-index></div>
      {siteHeaderData?.linkToContent && (
        <HiddenLinkToContent linkToContent={siteHeaderData?.linkToContent} />
      )}
      {showPreview && <Preview />}

      <TopBar
        menuId={mobileMenuId}
        showMenu={mobileMenuOpen}
        toggleShowMenu={handleMenuToggle}
        mobileButtonRef={mobileButtonRef}
        dataTestIdMenu="menu-button"
        topLogo={
          <div className={pageHeaderStyles.logoWrapper}>
            <Link
              href={"/"}
              prefetch={false}
              onClick={handleAfaLogoOnClick}
              data-testid={"top-logo"}
            >
              <div className={pageHeaderStyles.screenReaderWrapper}>
                Afa Försäkring - Gå till startsidan
              </div>
              <HeaderLogo
                className={pageHeaderStyles.logo}
                label={siteHeaderData?.logotypeText}
                logoUrl={
                  process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT === "test"
                    ? "/images/icons/afa-test-logotype.svg"
                    : "/images/icons/logotype.svg"
                }
              />
            </Link>
          </div>
        }
        logoText={siteHeaderData?.logotypeText}
        columnTwo={<TopBarNavLinks navLinks={topMenuLinks} />}
        columnThree={
          <LoginButton
            dropDownItems={siteHeaderData?.loginLinks}
            buttonLabel={siteHeaderData?.loginButtonText}
            dropdownItemOnClick={(item) => TopbarNavlinksTracker("logga in", item?.title)}
            toggleDropdownOnClick={() => handleLoginMenuToggle()}
            clickOutsideCallback={() => handleLoginClickOutside()}
            overlayHeight={pixelsToRem(documentHeight - 86)}
            className={pageHeaderStyles.loginButton}
            dataTestId={"login-button"}
          />
        }
      />

      <Navigation
        toggleMenu={mobileMenuOpen}
        setToggleMenu={setMobileMenuOpen}
        mobileMenuShortcuts={siteHeaderData?.mobileMenuShortcuts}
        menuItems={siteHeaderData?.menuItems}
        mobileMenuId={mobileMenuId}
        is404={is404}
      />

      <OperationalInfo />

      {!router?.asPath?.startsWith("/forsakringshandboken/") && !is404 && (
        <AfaBreadCrumbs breadCrumbLinks={breadCrumbs} ariaLabel={"Brödsmulor"} />
      )}
    </header>
  );
};

PageHeader.propTypes = {};

export default PageHeader;
