import { useEffect, useState } from "react";

export enum CookieConsentCategory {
  functional = "functional",
  marketing = "marketing",
  necessary = "necessary",
  statistical = "statistical",
  undefined = "undefined",
}

const EVENT_NAME_GIVEN = "CookieConsentGiven";
const EVENT_NAME_REVOKED = "CookieConsentRevoked";

const consentGivenFor = (category: CookieConsentCategory): boolean =>
  global.cookieTractor?.consentGivenFor(category.valueOf()) ?? false;

export const useCookieConsent = (category: CookieConsentCategory): boolean => {
  const [consentGiven, setConsentGiven] = useState<boolean>(false);

  useEffect(() => {
    // Need to do set initial state here within a useEffect to prevent
    // hydration errors due to global.cookieTractor sometimes being
    // available already at time of hydration :/
    setConsentGiven(consentGivenFor(category));

    const onConsentGiven = (e: CustomEvent) => {
      if (e.detail.consents.indexOf(category.valueOf()) > -1) {
        setConsentGiven(true);
      }
    };

    const onConsentRevoked = (e: CustomEvent) => {
      if (e.detail.consents.indexOf(category.valueOf()) > -1) {
        setConsentGiven(false);
      }
    };

    global.window.addEventListener(EVENT_NAME_GIVEN, onConsentGiven, false);
    global.window.addEventListener(EVENT_NAME_REVOKED, onConsentRevoked, false);

    return () => {
      global.window.removeEventListener(EVENT_NAME_GIVEN, onConsentGiven, false);
      global.window.removeEventListener(EVENT_NAME_REVOKED, onConsentRevoked, false);
    };
  }, []);

  return consentGiven;
};
