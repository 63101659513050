import Icon from "@afa-shared/afa-components/dist/Icon";

import breadcrumbStyles from "./breadcrumbs.module.css";

export interface IBreadCrumbsProps {
  breadCrumbLinks: any[];
  ariaLabel?: string;
  className?: string;
}

const AfaBreadCrumbs = ({
  breadCrumbLinks,
  ariaLabel,
  className,
}: IBreadCrumbsProps): JSX.Element | null => {
  const classes = className ? className : "";
  return breadCrumbLinks?.length > 1 ? (
    <nav className={`${breadcrumbStyles.breadcrumbs} ${classes}`} aria-label={ariaLabel}>
      <div className={breadcrumbStyles.breadcrumbHelper}></div>
      <div className={breadcrumbStyles.breadcrumbsListWrapper}>
        <ol className={breadcrumbStyles.breadcrumbsList}>
          {breadCrumbLinks?.map((breadCrumbItem, index) =>
            index < breadCrumbLinks?.length - 1 ? (
              <li className={breadcrumbStyles.breadcrumbsListItem} key={index}>
                {breadCrumbItem}
                <Icon name="chevron_right_black" size="sm" className={breadcrumbStyles.icon} />
              </li>
            ) : (
              <li className={breadcrumbStyles.breadcrumbsListItem} key={index}>
                {breadCrumbItem}
              </li>
            )
          )}
        </ol>
      </div>
    </nav>
  ) : null;
};

export default AfaBreadCrumbs;
