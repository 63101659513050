import { isNullOrEmpty } from "./string";

// Some file formats we can load directly from the ImageVault CDN
// and not proxy via our app servers, like we do for .pdfs etc
// due to SEO reasons.
const FILE_FORMATS_TO_LOAD_FROM_IMAGE_VAULT_CDN = Object.freeze([".webp", ".svg", ".jpg"]);

const getBaseUrl = () => {
  return (
    process.env.NEXT_PUBLIC_BASE_URL ||
    (typeof window !== "undefined" ? window?.location?.origin : "https://www.afaforsakring.se")
  );
};

const replaceImageVaultBasePath = (
  url: string | undefined,
  allowLoadingFromCdn: boolean = true
): string => {
  if (isNullOrEmpty(url)) {
    return "";
  }

  if (!url.includes("https://afa.imagevault.app/publishedmedia")) {
    return url;
  }

  if (allowLoadingFromCdn) {
    for (const format of FILE_FORMATS_TO_LOAD_FROM_IMAGE_VAULT_CDN) {
      if (url.endsWith(format)) {
        return url.replace("afa.imagevault.app", "afa.imagevault.media");
      }
    }
  }

  return url.replace("https://afa.imagevault.app/publishedmedia", "/dokument");
};

// Returns full url. like window.location.href. But can be used within getServerSideProps
const getCurrentUrl = (context: any): string => {
  return `${getProtocol(context)}://${context?.req.headers.host}${context?.resolvedUrl}`;
};
//Returns protocol. Can be used in getServerSideProps.
const getProtocol = (context: any): string => {
  return context?.req.headers["x-forwarded-proto"] || context?.req.connection.encrypted
    ? "https"
    : "http";
};

const existInURL = (path: string, pathNames: Array<string>): boolean => {
  if (!pathNames.length || isNullOrEmpty(path)) return false;
  return pathNames.some((p) => path.startsWith(p));
};

export { getBaseUrl, replaceImageVaultBasePath, getCurrentUrl, getProtocol, existInURL };
