import Cookies from "universal-cookie";

export const hasSetAudience = (roleId: string, sectorId: string): boolean => {
  const cookies = new Cookies();
  const cookieValue: string = cookies.get("Personalize");

  const hasRoleAndSector = roleId && sectorId;

  return cookieValue && hasRoleAndSector ? true : false;
};
