import { CSSProperties } from "react";

import { RichTextBlockType } from "@components/RichTextBlock/queries/contentfulTypes/RichTextBlockType";
import { getHeadingVariants } from "@utils/headingHelper";
import { CheckRichTextNotEmpty } from "@utils/string";

import { ContentAreaItem } from "types/ContentAreaItem";
import { Context } from "types/context/Context";

import Render from "./Render";
import contentAreaStyles from "./contentArea.module.css";

interface IContentfulContentArea {
  content?: any;
  [htmlAttributes: string]: any;
  context: Context;
  maxWidth?: number;
  mobileColumns?: number;
  tabletColumns?: number;
  desktopColumns: number;
  notebookColumns?: number;
  heading?: string;
}
export interface Sizes {
  desktop: string;
  notebook: string;
  tablet: string;
  mobile: string;
}
export const BlockSize = {
  Full: "full",
  Half: "half",
};
const CFContentArea = ({
  contentAreaItems = [],
  context,
  mobileColumns,
  tabletColumns,
  notebookColumns,
  desktopColumns,
  maxWidth,
  heading = "",
}: IContentfulContentArea): JSX.Element => {
  const { accordion, sectionBlock, relatedContent, faqPageContent } = context;

  let shouldRenderContent = true;

  if (
    contentAreaItems?.length === 1 &&
    Object?.keys(contentAreaItems[0])[0]?.toLowerCase() === "richtextblock"
  ) {
    const richTextBlock = contentAreaItems[0] as RichTextBlockType;
    shouldRenderContent = CheckRichTextNotEmpty(richTextBlock.richTextBlock);
  }
  const getContextSize = (
    itemIndex: number,
    columnCount: number,
    numberOfItems: number,
    mobileColumns: number,
    tabletColumns: number,
    notebookColumns: number,
    desktopColumns: number
  ) => {
    let mobileSize = "";
    let tabletSize = "";
    let notebookSize = "";
    let desktopSize = "";
    if (columnCount % mobileColumns === 1 && numberOfItems <= itemIndex) {
      mobileSize = BlockSize.Full;
    }
    if (columnCount % tabletColumns === 1 && numberOfItems <= itemIndex) {
      tabletSize = BlockSize.Full;
    }
    if (columnCount % notebookColumns === 1 && numberOfItems <= itemIndex) {
      notebookSize = BlockSize.Full;
    }
    if (columnCount % desktopColumns === 1 && numberOfItems <= itemIndex) {
      desktopSize = BlockSize.Full;
    }
    return {
      desktop: desktopSize,
      mobile: mobileSize,
      tablet: tabletSize,
      notebook: notebookSize,
    } as Sizes;
  };

  const headingContext = getHeadingVariants(heading);
  context = { ...context, heading: headingContext };

  const mobileMultiCol = mobileColumns > 1 ? contentAreaStyles.mobileMultiCol : "";
  const tabletMultiCol = tabletColumns > 1 ? contentAreaStyles.tabletMultiCol : "";
  const notebookMultiCol = notebookColumns > 1 ? contentAreaStyles.notebookMultiCol : "";
  const desktopMultiCol = desktopColumns > 1 ? contentAreaStyles.desktopMultiCol : "";
  const accordionClass = accordion ? contentAreaStyles.accordion : "";
  const sectionBlockClass = sectionBlock ? contentAreaStyles.sectionBlock : "";
  const relatedContentClass = relatedContent ? contentAreaStyles.relatedContent : "";
  const faqPageContentClass = faqPageContent ? contentAreaStyles.faqPageContent : "";
  const classes = `${contentAreaStyles.contentArea} ${mobileMultiCol} ${tabletMultiCol} ${notebookMultiCol} ${desktopMultiCol} ${accordionClass} ${sectionBlockClass} ${relatedContentClass} ${faqPageContentClass}`;

  const elementStyle: CSSProperties = {
    "--mobile-cols": mobileColumns,
    "--tablet-cols": tabletColumns,
    "--notebook-cols": notebookColumns,
    "--desktop-cols": desktopColumns,
  } as CSSProperties;
  let columnCount = 0;
  return (
    <>
      {shouldRenderContent && (
        <div className={classes} style={elementStyle}>
          {contentAreaItems?.map((contentAreaItem: ContentAreaItem, i) => {
            if (i === 0 || i + (1 % desktopColumns) === 1) {
              columnCount = 1;
            } else {
              columnCount += 1;
            }
            const blockName = Object.keys(contentAreaItem)[0];
            const key = contentAreaItem[blockName].id;
            const contextSize = getContextSize(
              i + 1,
              columnCount,
              contentAreaItems?.length,
              mobileColumns,
              tabletColumns,
              notebookColumns,
              desktopColumns
            );
            context = { ...context, sizes: contextSize };

            return (
              <Render
                contentAreaItem={contentAreaItem}
                key={`${key}-${i}`}
                uniqueKey={`${key}-${i}`}
                context={context}
                maxWidth={maxWidth}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default CFContentArea;
