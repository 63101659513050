import { ReactNode } from "react";

import IvTeaserImage from "@components/Medias/Image/IvTeaserImage";

import quizStepStyles from "./QuizStep.module.css";

interface IQuizStep {
  ivImage?: any;
  children: ReactNode;
  notebookDownButtonWrapper: ReactNode;
  className?: string;
}

const QuizStep = ({
  ivImage,
  children,
  notebookDownButtonWrapper,
  className,
}: IQuizStep): JSX.Element => {
  return (
    <div className={`${quizStepStyles.quizStep} ${className}`}>
      <div className={quizStepStyles.quizStepWrapper}>
        {ivImage ? <IvTeaserImage image={ivImage} /> : <></>}
        <div className={quizStepStyles.quizStepContent}>{children}</div>
      </div>
      {notebookDownButtonWrapper}
    </div>
  );
};

export default QuizStep;
