import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Icon from "@afa-shared/afa-components/dist/Icon";
import RichTextRenderer from "@components/RichTextRenderer";
import { hasVisibleChildrenForAudience } from "@utils/menuHelper";

import { useEscape } from "hooks/useEscape";
import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { MenuItem } from "types/MenuItem";

import { useClickOutside } from "../../../hooks/useOutsideClick";
import { navigationTracker } from "../NavigationTracker";
import desktopStyles from "./Desktop.module.css";

const MainNavigation = (props: any): JSX.Element | any => {
  const selectedSector = useSectorToggler((state: ISectorToggler) => state.selectedSector);

  const [menuItems, setMenuItems] = useState<Array<MenuItem>>(props.menuItems);
  const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
  const ref = useRef(null);
  const buttonRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    setMenuItems(props.menuItems);
    setOverlayVisible(false);
    () => {
      setMenuItems([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query?.slug, selectedSector]);

  const toggleFirstLevel = (array: Array<MenuItem>): Array<MenuItem> => {
    for (const menuItem of array) {
      menuItem.isOpened = false;
    }
    return array;
  };
  useClickOutside(ref, () => {
    const array = toggleFirstLevel(menuItems);
    setMenuItems([...array]);
    setOverlayVisible(false);
  });

  useEscape(ref, () => {
    buttonRef?.current?.focus();
    setOverlayVisible(false);
    const closeStuff = toggleFirstLevel(menuItems);
    setMenuItems([...closeStuff]);
  });

  const renderSubMenu = (item: MenuItem, levelTwoParentName: string) => {
    return (
      <ul
        className={`${desktopStyles.list} ${item?.isOpened ? desktopStyles.isOpen : ""}`}
        data-level={item?.level + 1}
        id={item.id}
      >
        {item.children.map((child: MenuItem) =>
          getMenuItemsRecursively(
            child,
            levelTwoParentName,
            child?.hideForAudiences?.includes(selectedSector?.audienceId)
          )
        )}
      </ul>
    );
  };
  const SideBar = ({ item }: { item: MenuItem }) => {
    if (!item?.sideBar) return null;
    const { heading, iconName, text, link, linkText } = item?.sideBar;
    const url = link;
    if (!url) {
      //We return null (and dont show sidebar) in test/stage/prod to prevent the site from crashing, but console the error for troubleshooting purposes
      console.error(`Url is missing in menu sidebar for submenu under ${item.name}`);
      return null;
    }
    return (
      <div className={desktopStyles.sideBar}>
        <Grid>
          {iconName && <Icon name={iconName} size="md" className={desktopStyles.sideBarIcon} />}
          <Heading variant="h2" className={desktopStyles.sideBarHeading}>
            {heading}
          </Heading>
          <div className={desktopStyles.sideBarTextWrapper}>
            <RichTextRenderer json={text} />
          </div>
          <Link href={url} prefetch={false} className={desktopStyles.sideBarLink}>
            {linkText}
          </Link>
        </Grid>
      </div>
    );
  };

  const toggleItemRecursively = (item: MenuItem, array: Array<MenuItem>): Array<MenuItem> => {
    for (const menuItem of array) {
      if (menuItem?.id === item?.id) {
        menuItem.isOpened = !menuItem.isOpened;
      } else {
        toggleItemRecursively(item, menuItem?.children);
      }
    }
    return array;
  };

  const openSubmenu = (item: MenuItem) => {
    const array = toggleItemRecursively(item, menuItems);
    setMenuItems([...array]);
  };

  const ToggleDropDown = (item: MenuItem) => {
    if (!item?.children?.length) return;
    if (!item?.isOpened) {
      const closeStuff = toggleFirstLevel(menuItems);
      setMenuItems([...closeStuff]);
    }
    if (item?.children?.length) setOverlayVisible(!item.isOpened);
    const array = toggleItemRecursively(item, menuItems);
    setMenuItems([...array]);
  };

  const renderLevelTwoItem = (item: MenuItem, levelTwoParentName: string): JSX.Element => {
    return (
      <>
        {!item?.children?.length ? (
          <Link
            href={item?.redirectsTo ? item?.redirectsTo : item?.url}
            prefetch={false}
            className={`${desktopStyles.navLink} ${item?.isActive ? desktopStyles.active : ""}`}
            onClick={() => navigationTracker(item?.name, levelTwoParentName)}
            data-level={item?.level}
            data-testid={`page-subheader-link-${item?.slug}`}
          >
            {item?.name}
          </Link>
        ) : (
          <button
            className={`${desktopStyles.invisibleButton} ${
              item?.isActive ? desktopStyles.active : ""
            }`}
            onClick={() => ToggleDropDown(item)}
            aria-expanded={item?.isOpened}
            aria-controls={item.id}
            ref={item?.level === 2 && item?.isOpened ? buttonRef : null}
            aria-label={"Öppna undermeny till " + item?.name}
            data-level={item?.level}
            data-testid={`page-subheader-container-${item?.slug}`}
          >
            {item?.name}
          </button>
        )}
      </>
    );
  };

  const renderItemWrapper = (item: MenuItem, children: any): JSX.Element => {
    const hasActiveHiddenChildren =
      !item?.isActive &&
      item?.hasActiveChildren &&
      !hasVisibleChildrenForAudience(item, selectedSector?.audienceId);
    return (
      <>
        {item?.level === 3 && item?.children?.length === 0 ? (
          <>{children}</>
        ) : (
          <div
            className={`${desktopStyles.itemWrapper} ${
              item?.isActive || hasActiveHiddenChildren ? desktopStyles.active : ""
            }`}
          >
            {children}
          </div>
        )}
      </>
    );
  };

  const renderItem = (
    item: MenuItem,
    levelTwoParentName: string
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): JSX.Element => {
    const hasActiveHiddenChildren =
      !item?.isActive &&
      item?.hasActiveChildren &&
      !hasVisibleChildrenForAudience(item, selectedSector?.audienceId);
    return (
      <>
        {item?.level !== 3 && (
          <span
            className={`${desktopStyles.decorator} ${
              item?.isActive || hasActiveHiddenChildren ? desktopStyles.active : ""
            }`}
            data-level={item?.level}
          />
        )}
        {item?.isContainer && hasVisibleChildrenForAudience(item, selectedSector?.audienceId) ? (
          <button
            className={`${desktopStyles.invisibleButton} ${
              item?.hasActiveChildren ? desktopStyles.inActivePath : ""
            }`}
            aria-label={"Öppna undermeny till " + item?.name}
            aria-expanded={item?.isOpened}
            aria-controls={item.id}
            onClick={() => openSubmenu(item)}
          >
            {item?.name}
            {item?.children?.length && (
              <Icon name={item?.isOpened ? "remove" : "add"} className={desktopStyles.toggleIcon} />
            )}
          </button>
        ) : (
          <>
            <Link
              href={item?.redirectsTo ? item?.redirectsTo : item?.url ? item.url : ""}
              prefetch={false}
              className={`${desktopStyles.navLink} ${
                item?.isActive || hasActiveHiddenChildren ? desktopStyles.active : ""
              } ${item?.hasActiveChildren ? desktopStyles.inActivePath : ""} ${
                item?.children?.length === 0 ? desktopStyles.noChildren : ""
              }`}
              onClick={() => navigationTracker(item?.name, levelTwoParentName)}
              data-level={item?.level}
              data-testid={`page-subheader-link-${item?.slug}`}
            >
              {item?.name}
            </Link>
            {item?.children?.length &&
            hasVisibleChildrenForAudience(item, selectedSector?.audienceId) ? (
              <button
                className={desktopStyles.toggleButton}
                aria-label={"Öppna undermeny"}
                aria-expanded={item?.isOpened}
                aria-controls={item.id}
                onClick={() => openSubmenu(item)}
                data-testid={`page-subheader-button-${item?.slug}`}
              >
                <Icon name={item?.isOpened ? "remove" : "add"} />
              </button>
            ) : null}
          </>
        )}
      </>
    );
  };

  const getMenuItemsRecursively = (item: MenuItem, levelTwoParentName: string, hidden: boolean) => {
    const hasActiveHiddenChildren =
      !item?.isActive &&
      item?.hasActiveChildren &&
      !hasVisibleChildrenForAudience(item, selectedSector?.audienceId) &&
      item?.slug !== "varumarke" &&
      item?.slug !== "intressant-vetande" &&
      item?.slug !== "ingen-missad-ersattning" &&
      item?.slug !== "tank-pa-oss";

    return (
      <li
        className={`${desktopStyles.listItem} ${
          item?.isActive || hasActiveHiddenChildren ? desktopStyles.active : ""
        }
        ${hidden ? desktopStyles.hidden : ""}`}
        ref={item?.level === 2 && item?.isOpened ? ref : null}
        key={item?.id}
        data-level={item?.level}
      >
        <>
          {item?.level === 2
            ? renderLevelTwoItem(item, levelTwoParentName)
            : renderItemWrapper(item, renderItem(item, levelTwoParentName))}
        </>

        {item?.children?.length ? (
          item?.level === 2 ? (
            <div
              className={`${desktopStyles.childWrapper} ${
                item?.isOpened ? desktopStyles.dropdownOpen : ""
              }`}
            >
              {renderSubMenu(item, levelTwoParentName)}
              <SideBar item={item} />
            </div>
          ) : (
            renderSubMenu(item, levelTwoParentName)
          )
        ) : null}
      </li>
    );
  };

  return (
    <div className={desktopStyles.desktopNavigation} data-testid={"desktop-menu"}>
      {overlayVisible && (
        <div
          className={desktopStyles.overlay}
          style={{
            height: document?.documentElement?.offsetHeight - 150,
          }}
        />
      )}
      <ul className={`${desktopStyles.list}`} data-level={2}>
        {menuItems?.map((item: MenuItem) => {
          const hidden = item?.hideForAudiences?.includes(selectedSector?.audienceId);

          return getMenuItemsRecursively(item, item?.name, hidden);
        })}
      </ul>
    </div>
  );
};

export default MainNavigation;
