import { getInternalOrExternalIcon } from "@utils/linkIconHelper";
import { getUrlByLink } from "@utils/pageHelpers/getUrlByLink";
import { getUrlFromUrlStructureBySlug } from "@utils/pageHelpers/getUrlFromUrlStructureBySlug";
import { getUrlStructureItemBySlug } from "@utils/pageHelpers/getUrlStructureItemBySlug";

import { GlobalSettingsType_globalSettings_headerLoginLinksCollection_items } from "globalQueries/contentfulTypes/GlobalSettingsType";
import { SimpleLinkData } from "types/SimpleLinkData";
import { SiteStructureItem } from "types/SiteStructureItem";
import { UrlStructure } from "types/UrlStructure";

export const getTopMenuLinks = (siteTreeStructure: SiteStructureItem) => {
  let topMenuLinks = new Array<any>();
  const topmenuItems: Array<SiteStructureItem> = siteTreeStructure?.children.filter(
    (item: SiteStructureItem) => !item?.hideInMenu
  );
  topmenuItems?.map((item) => {
    topMenuLinks.push({ name: item?.name, url: item?.url });
  });
  const startPage = siteTreeStructure;
  topMenuLinks.splice(0, 0, { name: startPage?.name, url: startPage?.url });
  return topMenuLinks;
};
export const getIsCoveredByInsurance = (audienceId, insuranceCoveredTargetGroups) => {
  if (audienceId === "" || audienceId === undefined) return "ej vald målgrupp";
  if (!insuranceCoveredTargetGroups?.length) return "other";
  return insuranceCoveredTargetGroups?.some((a: any) => a === audienceId)
    ? "omfattas"
    : "omfattas inte";
};
export const getInsuranceName = (pageData: any) => {
  return pageData?.insuranceName ? pageData?.insuranceName : "";
};
export const getInsuranceCoveredTargetGroups = (pageData: any) => {
  const audiences = pageData?.availableForAudiencesCollection?.items?.map((a) => a?.audianceId);
  return audiences || [];
};

export const getLoginLinks = (loginLinksCollection: Array<any>, urlStructure): any => {
  let loginLinks = [];
  loginLinksCollection?.map(
    (item: GlobalSettingsType_globalSettings_headerLoginLinksCollection_items) => {
      const url = getUrlByLink(item, urlStructure);
      const name = item?.linkText || "";
      const externalReference = item?.url;
      const globalLink = item?.globalLinkItem;
      const internalReference = item?.reference;
      const linkType = getInternalOrExternalIcon(externalReference, internalReference, globalLink);

      loginLinks.push({
        description: item?.name,
        iconName: linkType,
        linkUrl: url,
        title: name,
      });
    }
  );
  return loginLinks;
};

const getUrlByLinkItem = (item: any, urlStructure: UrlStructure): SimpleLinkData => {
  const url = getUrlByLink(item, urlStructure);
  const name = item?.linkText || "";
  return { url: url, text: name };
};

const getLinkItem = (item: any, urlStructure): SimpleLinkData => {
  return item?.__typename === "Page"
    ? {
        url: getUrlFromUrlStructureBySlug(item?.slug, urlStructure),
        text: item?.title,
      }
    : getUrlByLinkItem(item, urlStructure);
};
export const getFooterLinkItems = (items: any, urlStructure) => {
  let linkItems = new Array<SimpleLinkData>();

  items?.map((item) => {
    const linkItem = getLinkItem(item, urlStructure);
    if (linkItem && linkItem?.text && linkItem?.url) linkItems.push(linkItem);
  });
  return linkItems;
};

export const getFirstParentPageLink = (
  path,
  slugArray,
  urlStructure: UrlStructure
): SimpleLinkData => {
  if (path && slugArray && urlStructure) {
    let slugs = slugArray?.reverse();

    for (const slug of slugs) {
      const item = getUrlStructureItemBySlug(slug, urlStructure);
      if (item) {
        return { url: item?.url, text: item?.name };
      }
    }
  }

  return null;
};
