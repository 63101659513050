import FAQList, { FAQListResult } from "@components/FAQList";

import { Context } from "types/context/Context";

interface ListBlockProps {
  blockData: FAQListResult;
  uniqueKey: string;
  context: Context;
}

export const FAQListBlock = ({ blockData: faqListBlock, uniqueKey, context }: ListBlockProps) => {
  return <FAQList faqListBlock={faqListBlock} uniqueKey={uniqueKey} context={context} />;
};
export default FAQListBlock;
