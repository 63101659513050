import React, { CSSProperties, useEffect, useRef, useState } from "react";

import { ImageSize } from "@afa-shared/afa-components/dist/Card";
import DocumentType from "@afa-shared/afa-components/dist/DocumentType";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Icon from "@afa-shared/afa-components/dist/Icon";
import Text from "@afa-shared/afa-components/dist/Text";
import IvImageBlockImage from "@components/Medias/Image/IvImageBlockImage";
import { setShareImageModalClosed, setShareImageModalOpen } from "@utils/globalClassHelper";
import { getExtensionFromImage, getMimeTypeFromImage } from "@utils/imageHelpers/imageHelper";
import {
  getGapSize,
  getImageSize,
  getNotebookGapSize,
  getNotebookImageSize,
  getTabletGapSize,
  getTabletImageSize,
} from "@utils/imageHelpers/sharedImageHelper";
import { columnSize } from "@utils/sectionHelper";
import { formatBytes } from "@utils/string";
import {
  getDirection,
  getTeaserImageSize,
  shouldUseNotebookSmallImages,
} from "@utils/teaserHelper/teaserHelper";
import { getBaseUrl, replaceImageVaultBasePath } from "@utils/urlResolver";

import useMediaQuery from "hooks/useMediaQuery";
import { Context } from "types/context/Context";

import shareImageStyles from "./shareImage.module.css";
import ShareImageModal from "./shareImageModal";
import { imageDownloadTracker, shareImageTracker } from "./shareImageTracker";

interface IShareImageProps {
  imageData: any;
  shareHeading: string;
  shareRichtext: string;
  shareLinkText: string;
  uniqueKey?: string;
  context: Context;
  maxWidth?: number;
  contentfulPreviewEntryId?: string;
}

type modalTextType = {
  heading: string;
  errorMessage: string;
};

const ShareImage = ({
  imageData,
  shareHeading,
  shareRichtext,
  shareLinkText,
  context,
  maxWidth,
  contentfulPreviewEntryId,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
IShareImageProps) => {
  const [openShareImageModal, setOpenShareImageModal] = useState(false);
  const [modalTexts, setModalTexts] = useState<modalTextType>({
    heading: "",
    errorMessage: "",
  });
  const containerRef = useRef(null);

  const isMobile = useMediaQuery("mobile");
  const isTabletOnly = useMediaQuery("tabletOnly");
  const isTablet = useMediaQuery("tablet");
  const isNotebook = useMediaQuery("notebook");

  const baseUrl = getBaseUrl();
  const path = replaceImageVaultBasePath(imageData?.socialMediaImageVersion, false);
  const url = `${baseUrl}${path}`;
  const imageExtension = getExtensionFromImage(imageData?.socialMediaImageVersion);

  const handleModalToggle = (setOpen: boolean) => {
    setOpen ? setShareImageModalOpen() : setShareImageModalClosed();
    setOpenShareImageModal(setOpen);
    if (!setOpen) {
      containerRef?.current?.focus();
    }
  };

  const [imageSize, setImageSize] = useState("");

  useEffect(() => {
    fetch(url, { method: "HEAD" }).then((response) => {
      if (response.headers.has("Content-Length")) {
        setImageSize(formatBytes(+response.headers.get("Content-Length")));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const filesArray = [
        new File([blob], imageData?.Name, {
          type: getMimeTypeFromImage(url),
        }),
      ];

      if (window.navigator.canShare && window.navigator.canShare({ files: filesArray })) {
        const shareData = {
          files: filesArray,
        };
        await navigator.share(shareData);
        shareImageTracker(shareHeading, "kort");
      } else {
        handleModalToggle(true);
        setModalTexts({
          heading: "Det går inte att dela bilder via den här webbläsaren",
          errorMessage:
            "Det går inte att dela bilder med den här versionen av din webbläsare. Uppdatera gärna till en senare version, eller prova med en annan webbläsare.",
        });
      }
    } catch (err) {
      //is triggered when user cancels sharing action
    }
  };

  let size: number = 12;
  const contextSize = context?.columns || 1;
  if (context?.sectionBlock) {
    size = contextSize < 2 || isMobile ? 12 : contextSize === 2 ? 6 : 4;
  } else {
    size = columnSize(contextSize);
  }
  const imgSize: ImageSize = getTeaserImageSize(size, maxWidth, false, context);
  const useNotebookSmallImages = shouldUseNotebookSmallImages(size, maxWidth);
  const direction = getDirection(size, maxWidth, !isNotebook && isTabletOnly);
  const noBorderClass =
    context?.sectionBlock && context?.sectionBlock?.coloredSection ? shareImageStyles.noBorder : "";
  const directionClass = direction === "row" ? shareImageStyles.row : shareImageStyles.column;

  const elementStyles: CSSProperties = {
    "--tablet-gap-size": getTabletGapSize(),
    "--tablet-image-size": getTabletImageSize(imgSize),
    "--notebook-gap-size": getNotebookGapSize(imgSize, useNotebookSmallImages),
    "--notebook-image-size": getNotebookImageSize(imgSize, useNotebookSmallImages),
    "--desktop-gap-size": getGapSize(imgSize),
    "--desktop-image-size": getImageSize(imgSize),
  } as CSSProperties;

  return (
    <div
      className={shareImageStyles.shareImage}
      ref={containerRef}
      tabIndex={-1}
      data-temp-keep-index
      data-contentful-entry-id={contentfulPreviewEntryId}
      data-contentful-field-id={"name"}
    >
      <article
        className={`${shareImageStyles.article} ${directionClass} ${noBorderClass}`}
        style={elementStyles}
      >
        <div className={`${shareImageStyles.imageWrapper}`}>
          <IvImageBlockImage image={imageData} />
        </div>
        <div className={`${shareImageStyles.content}`}>
          <div className={`${shareImageStyles.textWrapper}`}>
            {shareHeading && shareHeading !== "" && (
              <>
                <DocumentType
                  type={imageExtension}
                  referenceSize={context.heading?.parentLevel}
                  className={shareImageStyles.documentType}
                />
                <Heading variant={context.heading?.parentLevel} children={shareHeading} />
              </>
            )}
            {shareRichtext && shareRichtext !== "" && (
              <Text variant="paragraph" children={shareRichtext} />
            )}
          </div>
          <div className={`${shareImageStyles.linkWrapper}`}>
            {isTablet ? (
              shareLinkText &&
              shareLinkText !== "" && (
                <button
                  type="button"
                  onClick={handleOnClick}
                  aria-label={`Dela bild, ${shareLinkText}`}
                >
                  <Icon name={"share"} size="sm" referenceSize="span" />
                  <span>{shareLinkText}</span>
                </button>
              )
            ) : (
              <a
                className={`${shareImageStyles.download}`}
                aria-label={`Ladda ner bild, (${imageExtension}, ${imageSize})`}
                href={url}
                download={imageData?.Name}
                onClick={() => imageDownloadTracker(imageData?.Name, imageExtension)}
              >
                <Icon name={"file_download"} size="sm" referenceSize="span" />
                <span>{`Ladda ner bild ${imageSize && "(" + imageSize + ")"}`}</span>
              </a>
            )}
          </div>
        </div>
      </article>
      {openShareImageModal && (
        <ShareImageModal
          handleModalClose={() => handleModalToggle(false)}
          containerRef={containerRef}
          heading={modalTexts.heading}
          errorMessage={modalTexts.errorMessage}
        />
      )}
    </div>
  );
};
export default ShareImage;
