import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export const setLocalTimezone = (date: string): Date => {
  if (!date) return null;
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return dayjs.tz(date, "Europe/Stockholm").toDate();
};
