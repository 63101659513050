/* eslint-disable sonarjs/no-duplicate-string */
import { MenuItem } from "types/MenuItem";
import { PersonalInfo } from "types/PersonalInfo";
import { SideBarItem } from "types/SideBarItem";
import { SiteStructureItem } from "types/SiteStructureItem";
import { UrlStructure } from "types/UrlStructure";

export const getMenuItemsData = (
  currentMenuRoot: SiteStructureItem,
  currentUrl,
  urlStructure,
  firstSlug: string,
  sideBarItems: SideBarItem[]
): Array<MenuItem> => {
  const menuItems = getMenuItemsDataRecursivly(
    currentMenuRoot,
    currentUrl,
    urlStructure,
    firstSlug,
    sideBarItems
  );

  menuItems?.splice(0, 0, {
    hideInMenu: false,
    children: [],
    id: currentMenuRoot?.id,
    level: 2,
    name: "Start",
    sideBar: null,
    slug: currentMenuRoot?.slug,
    url: currentMenuRoot?.url,
    redirectsTo: currentMenuRoot?.redirectsTo,
    isContainer: false,
    hideForAudiences: [],
    isActive: currentUrl === currentMenuRoot?.url,
    isOpened: false,
    hasActiveChildren: false,
  });

  return menuItems;
};

const getMenuItemsDataRecursivly = (
  parentItem: SiteStructureItem,
  currentUrl: string,
  urlStructure: UrlStructure,
  firstSlug: string,
  sideBarItems: SideBarItem[]
): Array<MenuItem> => {
  const recursiveMenuItems = new Array<MenuItem>();
  if (!parentItem && !parentItem?.children?.length) return recursiveMenuItems;
  for (const item of parentItem?.children) {
    let sideBar = null;
    if (item?.level === 2) {
      const sideBarItem = sideBarItems?.find((i) => i?.slug === item?.slug);
      if (sideBarItem) {
        sideBar = sideBarItem?.sideBar;
      }
    }

    if (!item?.hideInMenu || item?.slug === firstSlug) {
      recursiveMenuItems.push({
        hideInMenu: item?.hideInMenu,
        children: getMenuItemsDataRecursivly(
          item,
          currentUrl,
          urlStructure,
          firstSlug,
          sideBarItems
        ),
        id: item?.id,
        level: item?.level,
        name: item?.name,
        sideBar: sideBar,
        slug: item?.slug,
        url: item?.url,
        isContainer: item?.isContainer,
        redirectsTo: item?.redirectsTo,
        hideForAudiences: item?.hideForAudiences,
        hasActiveChildren: hasActiveChildren(item, currentUrl),
        isActive: false,
        isOpened: false,
      });
    }
  }
  return recursiveMenuItems;
};
export const alterMenuItem = (
  menuItem: MenuItem,
  path,
  personalInfo,
  startLevel: number,
  isTablet: boolean
) => {
  if (!menuItem) return null;
  menuItem.level = Number(startLevel);
  menuItem.isActive = isTablet
    ? (path === menuItem?.url && !menuItem?.isContainer) ||
      (shouldMarkMenuItem(path, menuItem) && menuItem?.level !== 1)
    : (path === menuItem?.url && !menuItem?.isContainer) ||
      (menuItem?.level === 2 &&
        menuItem?.hasActiveChildren &&
        menuItem?.slug !== "varumarke" &&
        menuItem?.slug !== "intressant-vetande" &&
        menuItem?.slug !== "ingen-missad-ersattning" &&
        menuItem?.slug !== "tank-pa-oss");

  menuItem.isOpened =
    (hasVisibleChildren(menuItem, personalInfo) &&
      menuItem?.level > (isTablet ? 0 : 2) &&
      shouldOpenMenuItem(menuItem, path)) ||
    (menuItem?.level > (isTablet ? 0 : 2) && menuItem?.isActive);

  if (menuItem?.children?.length) {
    menuItem?.children?.map((childItem: MenuItem) => {
      alterMenuItem(childItem, path, personalInfo, startLevel + 1, isTablet);
    });
  }
  return menuItem;
};

const getMenuItemName = (slug: string) => {
  switch (slug) {
    case "varumarke":
      return "Varumärkesportal";

    case "intressant-vetande":
      return "Intressant vetande";

    case "ingen-missad-ersattning":
      return "Ingen missad ersättning";

    case "tank-pa-oss":
      return "Tänk på oss";
    default:
      return "Start";
  }
};

export const getDesktopMenu = (menuItemCollection, slug, path, personalInfo, isTablet: boolean) => {
  const currentMenuRoot = menuItemCollection?.filter((c: MenuItem) => c.slug === slug);

  const startMenuItem = currentMenuRoot[0];
  const desktopMenuItems = startMenuItem?.children;

  if (startMenuItem) {
    startMenuItem.name = getMenuItemName(slug);
    startMenuItem.children = [];
  }

  const concatDesktopMenu = {
    items:
      startMenuItem?.name === "Varumärkesportal" ||
      startMenuItem?.name === "Intressant vetande" ||
      startMenuItem?.name === "Ingen missad ersättning" ||
      startMenuItem?.name === "Tänk på oss"
        ? [].concat(startMenuItem, desktopMenuItems)
        : desktopMenuItems,
  };

  return concatDesktopMenu?.items?.map((menuItem: MenuItem) => {
    return alterMenuItem(menuItem, path, personalInfo, 2, isTablet);
  });
};

export const getMobileMenu = (mobileMenuItems, path, personalInfo, isTablet: boolean) => {
  return mobileMenuItems
    ?.filter((item: MenuItem) => !item?.hideInMenu)
    ?.map((a: MenuItem) => {
      return alterMenuItem(a, path, personalInfo, 1, isTablet);
    }) as Array<MenuItem>;
};
export const getBrandMenu = (mobileMenuItems, path, personalInfo, isTablet: boolean) => {
  const menuItems = mobileMenuItems?.filter((item: MenuItem) => item?.slug === "varumarke");

  const startMenuItem = menuItems[0];
  const brandMenuItems = startMenuItem?.children;
  if (startMenuItem) {
    startMenuItem.name = "Varumärkesportal";
    startMenuItem.children = [];
    startMenuItem.hideInMenu = false;
  }
  const concatDesktopMenu = {
    items: [].concat(startMenuItem, brandMenuItems),
  };

  return concatDesktopMenu?.items?.map((menuItem: MenuItem) => {
    return alterMenuItem(menuItem, path, personalInfo, 1, isTablet);
  }) as Array<MenuItem>;
};

export const getCampaignMenu = (mobileMenuItems, slug, path, personalInfo, isTablet: boolean) => {
  const menuItems = mobileMenuItems?.filter((item: MenuItem) => item?.slug === slug);

  const startMenuItem = menuItems[0];
  const campaignMenuItems = startMenuItem?.children;
  if (startMenuItem) {
    startMenuItem.name = getMenuItemName(slug);
    startMenuItem.children = [];
    startMenuItem.hideInMenu = false;
  }
  const concatDesktopMenu = {
    items: [].concat(startMenuItem, campaignMenuItems),
  };

  return concatDesktopMenu?.items?.map((menuItem: MenuItem) => {
    return alterMenuItem(menuItem, path, personalInfo, 1, isTablet);
  }) as Array<MenuItem>;
};

/**
 * Determines if a menu item should be visible based on its properties and the user's personal information.
 *
 * @param item - The menu item to check visibility for.
 * @param personalInfo - The personal information of the user.
 * @returns `true` if the menu item is visible, `false` otherwise.
 */
const isVisible = (item: MenuItem, personalInfo: PersonalInfo) => {
  if (item?.hideInMenu) return false;

  return !(
    personalInfo !== null &&
    item?.hideForAudiences?.some((audience) => audience === personalInfo?.audienceId)
  );
};

const hasVisibleChildren = (item: MenuItem, personalInfo: PersonalInfo): boolean =>
  item?.children?.some((child: MenuItem) => isVisible(child, personalInfo));

const hasActiveChildren = (item: SiteStructureItem, currentUrl: string): boolean => {
  if (currentUrl?.startsWith("/forsakringshandboken")) {
    return item?.children?.some(
      (child: SiteStructureItem) => child?.slug === "forsakringshandboken-redirect"
    );
  }
  if (currentUrl?.startsWith("/filer")) {
    return item?.children?.some(
      (child: SiteStructureItem) => child?.slug === "sok-filer-och-blanketter"
    );
  }

  return item?.children?.some((child: SiteStructureItem) =>
    currentUrl?.startsWith(child?.url) ? true : hasActiveChildren(child, currentUrl)
  );
};
const shouldOpenMenuItem = (item: MenuItem, path: string): boolean => {
  const slugs = path?.split("/");
  const hasActiveChildren =
    hasActiveHiddenChildren(path, item) && slugs?.some((slug: string) => slug === item?.slug);
  return (
    hasActiveChildren ||
    item?.children?.some((child: MenuItem) =>
      child?.url === path ? true : shouldOpenMenuItem(child, path)
    )
  );
};

const shouldMarkMenuItem = (path: string, menuItem: MenuItem) => {
  const slugs = path?.split("/");
  const currentParent = slugs?.[slugs?.length - 2];
  return hasActiveHiddenChildren(path, menuItem) && menuItem?.slug === currentParent;
};

const hasActiveHiddenChildren = (path: string, menuItem: MenuItem) => {
  const slugs = path?.split("/");
  const currentParent = slugs?.[slugs?.length - 2];
  const currentSlug = slugs?.[slugs?.length - 1];

  return currentParent !== "undefined" &&
    !menuItem?.children?.some((child: MenuItem) => child?.slug === currentSlug) &&
    menuItem?.hasActiveChildren
    ? true
    : false;
};

export const hasVisibleChildrenForAudience = (item: MenuItem, audienceId: string): boolean => {
  return audienceId === null || audienceId === "undefined"
    ? true
    : item?.children?.some((child: MenuItem) => !child?.hideForAudiences?.includes(audienceId));
};
